
import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Link, NavLink, withRouter } from "react-router-dom";
import { useState } from "react";
import { useEffect } from 'react';


import { connect } from 'react-redux';


/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import '../../css/partener.css';
import { useLocation } from 'react-router-dom'  


//import ContentVendorReportTable from './ContentVendorReportTable';
import {getContentVendorViewItemtData,exportContentVendorReport} from './ContentVendorReportActions';
import ContentVendorViewItem from './ContentVendorViewItem';

const contentTypeOptions = [];        
contentTypeOptions.push({key: 0,text: 'ALL',value: 'ALL',});
contentTypeOptions.push({key: 1,text: 'EPISODE_WITHOUT_MUSIC',value: "EPISODE_WITHOUT_MUSIC"});	  	
contentTypeOptions.push({key: 2,text: 'EPISODE_WITH_MUSIC',value: "EPISODE_WITH_MUSIC"});
contentTypeOptions.push({key: 3,text: 'SONG_WITHOUT_MUSIC',value: "SONG_WITHOUT_MUSIC"});
contentTypeOptions.push({key: 4,text: 'SONG_WITH_MUSIC',value: "SONG_WITH_MUSIC"});
contentTypeOptions.push({key: 5,text: 'RADIO_CHANNEL',value: "RADIO_CHANNEL"});
contentTypeOptions.push({key: 6,text: 'GAME',value: "GAME"}); 



 const ContentVendorViewList = ({loading,exporting,exportContentVendorReport }) => {
	
	exporting=false;				
	  
	let location = useLocation();
	  
	//console.log('The vendorId is : '+location.state.vendorId);
	//console.log('data is: '+JSON.stringify(contentVendorReportData,null,2));
	
		const [vendorId, setVendorId] = useState(location.state.vendorId)
		const [contentType,setContentType] = useState('ALL')
		const [currentPage, setCurrentPage] = useState(0);		
		const [pageSize, setPageSize] = useState(0); 
		
// --------------------------
	const today = new Date();

	const eMonth = today.getMonth() + 1;
	const eDay = today.getDate();
	const eYear = today.getFullYear();

	const oneWeekAgo = new Date(today);
	oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
	const sMonth = oneWeekAgo.getMonth() + 1;
	const sDay = oneWeekAgo.getDate();
	let sYear = oneWeekAgo.getFullYear();
	
	
	sYear=sYear-2000;
	 
	const [startDate, setStartDate] = useState(`${sMonth}/${sDay}/${sYear}`)
    const [endDate, setEndDate] = useState(`${eMonth}/${eDay}/${eYear}`)

// =======================================================================================================================================================
// =======================================================================================================================================================
/*const getViewOfAllItem =() => {
	contentTypeOptions.filter(ct=>ct.key!=0).forEach(ct =>{
		getContentVendorViewItemtData(vendorId,startDate,endDate,ct,0,pageSize);	
	});	
}
const getViewOfOneItem =(type) => {
	getContentVendorViewItemtData(vendorId,startDate,endDate,type,0,pageSize);
}*/

const handleDateChange = (e, { name, value }) => {
	
	if (name === "startDate") {			
		setStartDate(value);

		if (endDate) {
			//getContentVendorViewItemtData(vendorId,startDate,endDate,contentType,0,pageSize);
		}
	}
	if (name === "endDate") {
		setEndDate(value);

		if (startDate) {
			//getContentVendorViewItemtData(vendorId,startDate,endDate,contentType,0,pageSize);
		}
	}
}       
// =======================================================================================================================================================               
const handleContentTypeChange = (e, { value }) => {
    setContentType(value);
}													    
// =====================================================================
useEffect(() => {
	//console.log('call data from useEffect: '+contentType);														    
}, [contentType,startDate,endDate]); 
// ===================================================================== 
// =====================================================================
/*
	//Pagination logic :
	  														 
	const handlePagination = (pageNumber) => {	
		setCurrentPage(pageNumber);
	    getContentVendorViewReportData(vendorId,startDate,endDate,contentType,pageNumber,pageSize);	
	};
// =====================================================================  
*/
   const handleExport = () => {
        exportContentVendorReport(startDate, endDate,vendorId);
    };														 
/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                {/* <InnerHeader /> */}
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                           
                                            <div className="box-container">                                                
                                                
												<h4 className="main-title mt-2 font-weight-bold"> Vendor Views Report  </h4>

												<div>
													<div id="tab03" className="tab-contents details-table">
														<form onSubmit={(e) => e.preventDefault()}>
															<div className="form-row mt-2">
																<div className="col-md-4 col-12 mb-3">
																	<label className="small-label" for="">Start Date</label>
																	<DateInput
																		name="startDate"
																		closeOnMouseLeave

																		value={startDate}
																		placeholder="start date"
																		onChange={handleDateChange}
																		iconPosition="right"
																		dateFormat="MM/DD/YYYY"
																		required
																	/>
																</div>
																<div className="col-md-4 col-12 mb-3">
																	<label className="small-label" for="">End Date</label>
																	<DateInput
																		name="endDate"
																		closeOnMouseLeave

																		value={endDate}
																		placeholder="end date"
																		onChange={handleDateChange}
																		iconPosition="right"
																		dateFormat="MM/DD/YYYY"
																		required
																	/>
																</div>
															</div>
														</form>
													</div>
												</div>                                                
                                                
                                                
                                                <div className="row">	                                                
	                                                <div className="col-4">
	                                                	<div className="text-left  mb-2">Content Type </div>	                                                
														<Dropdown                                
							                                fluid
							                                selection
							                                placeholder="Select"
							                                id="packageType_id"
							                                onChange={handleContentTypeChange}
							                                options={contentTypeOptions}
							                                value={contentType}
							                              />         
	                                                                              
													</div>
													<div className="col-4"> </div>
													<div className="col-4">	
													
<div className="m-2" style={{ textAlign: 'right', marginBottom: '10px' }}>                  {/* disabled={exporting}  cursor: !exporting ? 'not-allowed' : 'pointer',*/}
    <button
        onClick={handleExport}
        
        style={{
            backgroundColor: exporting ? '#9d8cb3' : '#5c4b81',
            color: 'white',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            fontSize: '14px',
            
        }}
    >
        {!exporting ? 'Exporting...' : 'Export to Excel'}
    </button>
</div>
													
																									    	                                                                              
													</div>		                                           	                                                

                                                </div>
                                                
                                                <div className=" mt-3 p-3 panel">                                                												    
												
												{
													

														<>
															{(contentType=='ALL' || contentType=='EPISODE_WITHOUT_MUSIC')?
																<ContentVendorViewItem vendorId={vendorId} loading={loading} type="EPISODE_WITHOUT_MUSIC" startDate={startDate} endDate={endDate} key="1"/>
																:''
															}
															{contentType=='ALL' || contentType=='EPISODE_WITH_MUSIC'?
																<ContentVendorViewItem vendorId={vendorId} loading={loading} type="EPISODE_WITH_MUSIC" startDate={startDate} endDate={endDate} key="2"/>
																:''
															}
															{contentType=='ALL' || contentType=='SONG_WITHOUT_MUSIC'?
																<ContentVendorViewItem vendorId={vendorId} loading={loading} type="SONG_WITHOUT_MUSIC" startDate={startDate} endDate={endDate} key="3"/>
																:''
															}
															{contentType=='ALL' || contentType=='SONG_WITH_MUSIC'?
																<ContentVendorViewItem vendorId={vendorId} loading={loading} type="SONG_WITH_MUSIC" startDate={startDate} endDate={endDate} key="4"/>
																:''
															}
															{contentType=='ALL' || contentType=='RADIO_CHANNEL'?
																<ContentVendorViewItem vendorId={vendorId} loading={loading} type="RADIO_CHANNEL" startDate={startDate} endDate={endDate} key="5"/>
																:''
															}
															{contentType=='ALL' || contentType=='GAME'?
																<ContentVendorViewItem vendorId={vendorId} loading={loading} type="GAME" startDate={startDate} endDate={endDate} key="6"/>
																:''
															}
													    </>																										
												} 
                                                                                                                                                         
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )        
}

const mapStateToProps = state => ({
    loading: state.contentVendorReportReducer.State_Content_Vednor_Report_loading,
    exporting: state.contentVendorReportReducer.exporting,
    exportError: state.contentVendorReportReducer.exportError,
                                              
});

	
export default connect( mapStateToProps, { getContentVendorViewItemtData,exportContentVendorReport } )(withRouter(ContentVendorViewList));
    
