import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Link } from 'react-router-dom'
import {  Button, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/*Actions */
import { emptyContentVendor } from '../../Actions/ContentVendorActions';
import {saveContentVendor} from '../../Actions/AuthActions';


/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import country_list from '../ConstData/PaymentAndVendorCountriesList'

/* Routes */
import { ADMIN_LAND_PAGE, CONTENT_VENDORS } from '../../routes';
import { Editor } from '@tinymce/tinymce-react';
import UploadImage from "../S3Uploading/UploadImage";



const INITIAL_STATE = {

    name: '',
    email: '',
    password: '',
    phone: '',
    country: '',
    note: '',
    description:'',
    city: '',    
    addess: '',
    blogHTML: "",
    vendor_imageUrl: "",

    ////////////////// validation form
    error: true,
    errorMessage: "",
};

export class AddContentVendor extends Component { 

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }


    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });

        //console.log("email")
        //console.log(checkSpaces)

        setTimeout(() => {
            if ((this.state.name === '' || this.state.name === null || this.state.name === undefined)
                || (this.state.password === '' || this.state.password === null || this.state.password === undefined)
                || (this.state.email === '' || this.state.email === null || this.state.email === undefined)
                || (this.state.phone === '' || this.state.phone === null || this.state.phone === undefined)
                || (this.state.country === '' || this.state.country === null || this.state.country === undefined)
            ) {
                this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
            }
            else if ((this.state.name !== '' && this.state.name !== null && this.state.name !== undefined)
                && (this.state.password !== '' && this.state.password !== null && this.state.password !== undefined)
                && (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined)
                && (this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined)
                && (this.state.country !== '' && this.state.country !== null && this.state.country !== undefined)

            ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }, 0);
    }

    handleCountryChange = (e, { value }) => {
        this.setState({ country: value });
    };

getImageUrl(imageUrl) {
    if (imageUrl) {
      this.setState({ vendor_imageUrl: imageUrl });

    }
  }


    handleSubmit = (e) => {

        const { email, password, name, phone, country, note, city, addess,blogHTML,vendor_imageUrl } = this.state;
        
        var res = blogHTML;
        console.log('bloghtml: '+res);

	    if (blogHTML.includes('<img') === true) {
	      res = res.split('<img').join('<img style="display: block; margin-left: auto; margin-right: auto;       text-align: center;  line-height: 96px; height: 22%; color: gray; border: 1px solid rgba(34, 36, 38, .15);border-radius: .28571429rem; transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease; object-fit: scale-down"');
	    }
        let html = res;
        console.log('html: '+html);

		if (blogHTML === '' || blogHTML === undefined || blogHTML === null) {
	
	      alert("Description can't be empty");
	      this.setState({
	        validateInput: false
	      })
	    }
    
        
        this.props.saveContentVendor(email, password, name, phone, country, note,html, city, addess,vendor_imageUrl);
        // this.closeModal();
        e.preventDefault();

    }

    render() {
        const { success, error, loading } = this.props;

//console.log('=========================================================================== : Test');

        let buttonSubmitLoader = (
            <Button onClick={this.handleSubmit} id="nextButton" disabled={this.state.error}>
                Add
            </Button>);

        if (loading === true) 
        {
            buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">
                    Loading
                </button>
            )
        }
        if (success === true) {
            this.props.emptyContentVendor();
            alert("Adding Vendor Done Successfully");
            return (<Redirect to={CONTENT_VENDORS}></Redirect>)

        }
        else if (success === false) {
            alert(error);
            this.props.emptyContentVendor();
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="container">

                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                        <Link to={CONTENT_VENDORS}>Content Vendor</Link><span> {'>'} </span>

                                                    </span>
                                                    <span>Add New Content Vendor</span>

                                                </div>
                                                {/* <!--  end status bar --> */}

                                                <h3>Add New Content Vendor </h3>
                                                <hr />

                                                <div className="">
                                                    <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                                        <p style={{ color: "red" }}>{this.state.errorMessage} </p>

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                                    Name</label>
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="name" id="name" onChange={this.handleChange}
                                                            value={this.state.name} />
                                                        <br />


                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Password</label>
                                                        <input
                                                            type="password"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="password" id="password"
                                                            onChange={this.handleChange}
                                                            value={this.state.password}
                                                            required />
                                                        <br />

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Email</label>
                                                        <input
                                                            type="email"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="email" id="email"
                                                            onChange={this.handleChange}
                                                            value={this.state.email}
                                                            required />
                                                        <br />

                                                        <label htmlFor="phone" className="label">
                                                            <span className="required_input">*</span>
                                                            Phone</label>
                                                        <input
                                                            type="number"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="phone" id="email"
                                                            onChange={this.handleChange}
                                                            value={this.state.phone}
                                                            required />
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                                Select Country </label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Country'
                                                            id="episode_series_id"
                                                            onChange={this.handleCountryChange}
                                                            options={country_list}
                                                            value={this.state.country}
                                                        />
                                                        <br />

                                                        <label htmlFor="city" className="label">
                                                            City</label>
                                                        <input
                                                            type="text"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="city" id="city"
                                                            onChange={this.handleChange}
                                                            value={this.state.city}
                                                        />
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="addess" className="label">
                                                                Addess
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="addess"
                                                                id="addess"
                                                                onChange={this.handleChange}
                                                                value={this.state.addess} />
                                                        </div>
                                                        <br />




                                                        <div className="form-group">
                                                            <label htmlFor="note" className="label">
                                                                Note
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="note"
                                                                id="note"
                                                                onChange={this.handleChange}
                                                                value={this.state.note} />
                                                        </div>
                                                        
                                                        <div className="form-group">
                                                        	<label htmlFor="note" className="label">
                                                               Img URL
                                                            </label>
                                                            
							                              {/* <span className="required_input">*</span> */}
							                              <UploadImage
							                                getImageURLCallBack={this.getImageUrl.bind(
							                                  this
							                                )}
							                                imageUrl={this.state.vendor_imageUrl}
							                                imagepreview={this.state.vendor_imageUrl}
							                                s3DirectryrName="contentVendor"
							                              />
							                            </div>
                                                        
                                                        
                                                        
                                                      {/*  
                                                        <div className="form-group">
                                                            <label htmlFor="description" className="label">
                                                                Description
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="description"
                                                                id="description"
                                                                onChange={this.handleChange}
                                                                value={this.state.description} />
                                                        </div>
                                                        */}
                                                        <div className="form-group">
                            <Editor
                              apiKey='cxop87zxs4fbrhoromln8hz0gn4m9crk0obq2do43cee4fnw'
                              onInit={(evt, editor) => {

                                const body = editor.dom.select('body')[0];
                                editor.dom.add(
                                  body,
                                  'script',
                                  {
                                    src: "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js",
                                    type: 'text/javascript'
                                  }
                                );

                                editor.dom.add(
                                  body,
                                  'script',
                                  {
                                    src: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js",
                                    type: 'text/javascript'
                                  }
                                );
                              }}
                              initialValue={this.state.blogHTML}
                              onBlur={newValue => {
                                this.setState({ blogHTML: newValue.target.getContent() })
                              }}
                              // onEditorChange={newValue => {
                              //   this.setState({ blogHTML: newValue.target.innerHTML })
                              // }}
                              init={{

                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste"
                                ],
                                toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                                content_css: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css, https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css",
                                valid_children: '+body[style]',
                                valid_elements: '*[*]',
                                inline_styles: true,
                                extended_valid_elements: 'i[class]',
                                width: '100%'

                              }}
                            />
                          </div>

                                                    </form>

                                                </div>


                                                <div className="input-field">

                                                    {buttonSubmitLoader}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}


AddContentVendor.propTypes = {
    saveContentVendor: propTypes.func.isRequired,
    //     fetchAllSeries: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
   loading: state.contentVendor.State_loading,
    error: state.contentVendor.State_Content_Vendor_Load_Error,
    success: state.contentVendor.State_Content_Vendor_Add_sucessfully,
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                saveContentVendor,
                emptyContentVendor
            }
        )
        (AddContentVendor)
    );
