import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Actions*/
// import {
//     setChannelStatus,
//     emptyChannel
// } from '../../Actions/RadioChannelAction'
// import { getRadioData } from '../../Actions/RadioActions';

import {
    setBookStatus,
    emptyBook,
    getBookData,
    fetchBooks
} from '../../Actions/ReadWithJeelAction'

class SetBookState extends Component {

    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setBookStatus(id, staus) {
        this.props.setBookStatus(id, staus)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setBookStatus(id, "DEFAULT");
        } else {
            this.setBookStatus(id, "SUSPENDED");
        }
        this.close();
    }
    render() {
	
        const { success,
            error } = this.props
        const { status, id } = this.props

//console.log('status here in render :'+status);

        let contentPreview = null;

        if (success === false) {
            alert("Error: " + error);
            this.props.emptyBook();
            // this.props.getBookData(id);
            this.props.fetchBooks()
            if(this.props.getParentData) {
                this.props.getParentData(this.props.BookParentId)
            }
        }
        else if (success === true) {
            alert("Book status updated successfully");
            this.props.emptyBook();
            // this.props.getBookData(id);
            this.props.fetchBooks()
            if(this.props.getParentData) {
                this.props.getParentData(this.props.BookParentId)
            }
        }

        if (status === "SUSPENDED") {
            console.log('SUSPENDED')
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>
                        <i className="" >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            console.log('PLAY')
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }

        return (<span >
            {contentPreview}
            <Confirm id="modal"
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </span>)
    }
}


const mapStateToProps = state =>
({
    success: state.readWithJeel.book_status_updated_successfully,
    error: state.readWithJeel.error_setting_status
})
export default connect
    (mapStateToProps,
        {
            setBookStatus,
            emptyBook,
            getBookData,
            fetchBooks
        })
    (SetBookState)
