import {
GAME_FETCH_ERROR,
RADIO_FETCH_ERROR,
SONG_WITHOUT_FETCH_ERROR,
SONG_WITH_FETCH_ERROR,
EPISODE_WITHOUT_FETCH_ERROR,
EPISODE_WITH_FETCH_ERROR,

EPISODE_WITH_FETCH_SUCCESSFULLY,
EPISODE_WITHOUT_FETCH_SUCCESSFULLY,
SONG_WITH_FETCH_SUCCESSFULLY,
SONG_WITHOUT_FETCH_SUCCESSFULLY,
RADIO_FETCH_SUCCESSFULLY,
GAME_FETCH_SUCCESSFULLY,

EPISODE_WITH_LOAD,
EPISODE_WITHOUT_LOAD,
SONG_WITH_LOAD,
SONG_WITHOUT_LOAD,
RADIO_LOAD,
GAME_LOAD,

EXPORT_CONTENT_VENDOR_REQUEST,
EXPORT_CONTENT_VENDOR_SUCCESS,
EXPORT_CONTENT_VENDOR_FAIL

    
} from '../../Actions/Types'
import axios from 'axios'
import { post,get } from 'axios';

   
    
export const getContentVendorViewItemtData = (vendorId,startDate,endDate,contentType,pageNum,pageSize) => {
		
	return (dispatch, getState, { getFirebase }) => {
	
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {            
            
			dispatch({ type: getDispatchActionTypeForLoad(contentType) });
	
	        const url = "/ContentVendor/contentVendorViewsReport";
	        const formData = new FormData();
	        formData.append("vendorId",vendorId);
	        formData.append("Start_Date",startDate);
	        formData.append("End_Date",endDate);
	        formData.append("contentType",contentType);
	        formData.append("page",pageNum);
	        formData.append("size",pageSize);
	        
	        const config = {
	            headers: {
	                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
	                'X-Authorization-Firebase': token
	            }
	        }
	       
	       post(url,formData, config).then((res) => {
				//console.log('res in vendorAction : '+JSON.stringify(res.data,null,2));	
				//console.log('In Action, will dispatch : '+getDispatchActionTypeForFetchSuccessfully(contentType));
				dispatch({ type: getDispatchActionTypeForFetchSuccessfully(contentType), payload: res.data.content,totalElements: res.data.totalElements })             	
	        }).catch((Error) => {
				 dispatch({ type: getDispatchActionTypeForFetchError(contentType), payload: Error })
	        })            
        })
    }
}






const getDispatchActionTypeForLoad =(contentType)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return EPISODE_WITHOUT_LOAD;
	else if(contentType=='EPISODE_WITH_MUSIC') return EPISODE_WITH_LOAD;
	else if(contentType=='SONG_WITHOUT_MUSIC') return SONG_WITHOUT_LOAD;
	else if(contentType=='SONG_WITH_MUSIC') return SONG_WITH_LOAD;
	else if(contentType=='RADIO_CHANNEL') return RADIO_LOAD;
	else if(contentType=='GAME') return GAME_LOAD;
}
const getDispatchActionTypeForFetchSuccessfully =(contentType)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return EPISODE_WITHOUT_FETCH_SUCCESSFULLY;
	else if(contentType=='EPISODE_WITH_MUSIC') return EPISODE_WITH_FETCH_SUCCESSFULLY;
	else if(contentType=='SONG_WITHOUT_MUSIC') return SONG_WITHOUT_FETCH_SUCCESSFULLY;
	else if(contentType=='SONG_WITH_MUSIC') return SONG_WITH_FETCH_SUCCESSFULLY;
	else if(contentType=='RADIO_CHANNEL') return RADIO_FETCH_SUCCESSFULLY;
	else if(contentType=='GAME') return GAME_FETCH_SUCCESSFULLY;
}
const getDispatchActionTypeForFetchError =(contentType)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return EPISODE_WITH_FETCH_ERROR;
	else if(contentType=='EPISODE_WITH_MUSIC') return EPISODE_WITH_LOAD;
	else if(contentType=='SONG_WITHOUT_MUSIC') return SONG_WITHOUT_FETCH_ERROR;
	else if(contentType=='SONG_WITH_MUSIC') return SONG_WITH_FETCH_ERROR;
	else if(contentType=='RADIO_CHANNEL') return RADIO_FETCH_ERROR;
	else if(contentType=='GAME') return GAME_FETCH_ERROR;
}

// =======================================================================================================

export const exportContentVendorReport = (startDate, endDate,vendorId) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({ type: EXPORT_CONTENT_VENDOR_REQUEST });

            const url = "/ContentVendor/export-report";
            const formData = new FormData();
            formData.append("Start_Date", startDate);
            formData.append("End_Date", endDate);
            formData.append("vendorId",vendorId);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token,
                },
                responseType: 'blob', 
            };

            post(url, formData, config)
                .then((response) => {
                    const blob = new Blob([response.data]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `ContentVendor_Report_${startDate}_to_${endDate}.xlsx`
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    dispatch({ type: EXPORT_CONTENT_VENDOR_SUCCESS });
                })
                .catch((error) => {
                    dispatch({
                        type: EXPORT_CONTENT_VENDOR_FAIL,
                        payload: error.message || 'Failed to export report',
                    });
                });
        });
    };
};
