import store from '../Store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { post } from 'axios'

function savePages(ID, BookPages) {
    const url = "/Book/addBookPages";
    const config = {
            headers: {
                'content-type': 'application/json',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
    let pagesFormData = {
        id: ID,
        pages: BookPages
    }

    post(url, pagesFormData, config).then((res) => {
        return (res);
    }).catch((Error) => {
        alert("There is an error in pages upload")
        return Error.message
    })
}

export function saveBook(activityId) {
    return new Promise(function (resolve, reject) {
        let readWithJeel = store.getState().readWithJeel.item;
        
        let isFree = '', subValueId = '';
        if (readWithJeel.isFree === 'paid') {
            isFree = 0
        }
        else if (readWithJeel.isFree === 'free') {
            isFree = 1
        }

        if (readWithJeel.subValueId === '' ||
            readWithJeel.subValueId === undefined ||
            readWithJeel.subValueId === null) {
            subValueId = 0
        } else {
            subValueId = readWithJeel.subValueId
        }

        let bookPages = readWithJeel.pages;
        if (readWithJeel.pages == null ||
            readWithJeel.pages == undefined ||
            readWithJeel.pages == '') {
            bookPages = []
        }


        let description = readWithJeel.description;
        if (readWithJeel.description == null ||
            readWithJeel.description == undefined ||
            readWithJeel.description == '') {
            description = ''
        }

        let titleAudio = readWithJeel.titleAudio;
        if (titleAudio == null ||
            titleAudio == undefined ||
            titleAudio == '') {

            titleAudio = ''
        }

        let bookAudio = readWithJeel.bookAudio;
        if (bookAudio == null ||
            bookAudio == undefined ||
            bookAudio == '') {

            bookAudio = ''
        }

        let audioMarker = readWithJeel.audioMarker;
        if (audioMarker == null ||
            audioMarker == undefined ||
            audioMarker == '') {

            audioMarker = ''
        }


        const url = "/Book/addBook";
        const formData = new FormData();
        formData.append('Name', readWithJeel.name);
        formData.append('Description', description);
        formData.append('Name_In_English', readWithJeel.nameInEnglish);
        formData.append('Description_In_English', readWithJeel.descriptionInEnglish);

        formData.append('Image', readWithJeel.image);
        formData.append('Status', 'SUSPENDED');
        formData.append('Publish_Date', readWithJeel.PublishDate);
        formData.append('Activity_ID', activityId);
        formData.append('Is_free', isFree);
        // formData.append('Voice', readWithJeel.voice);
        formData.append('Voice', titleAudio);
        formData.append('book_voice', bookAudio);
        formData.append('book_marker', audioMarker);
        // formData.append('Pages', bookPages);
        formData.append('Content_Sub_Value_Id', subValueId);
        // formData.append('Radio_Id', book.selected_radio_id);
        formData.append('Book_Parent_Id', readWithJeel.selected_bookParent_id);
        formData.append('featured', readWithJeel.featured);
        formData.append('contentVendorSelectedId', readWithJeel.contentVendorSelectedId);
        
        formData.append('ageFrom', readWithJeel.ageFrom);
        formData.append('ageTo', readWithJeel.ageTo);


        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        let bookPagesArray = bookPages ? bookPages : []
        post(url, formData, config).then((res) => {
            resolve(res);
            // add pages
            savePages(res.data.id ,bookPagesArray)
            // end Add Pages
            return (res);
        }).catch((Error) => {
            reject(Error)
            return Error.message
        })
    })
}