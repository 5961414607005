import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Actions*/
// import {
//     setChannelStatus,
//     emptyChannel
// } from '../../Actions/RadioChannelAction'
// import { getRadioData } from '../../Actions/RadioActions';

import {
    setPdfBookStatus,
    emptyPdfBook,
    getPdfBookData,
    fetchPdfBooks
} from '../../Actions/PdfBookAction'

import { VIEW_BOOK_PARENT} from '../../routes';


class SetPdfBookState extends Component {

    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setPdfBookStatus(id, staus) {
        this.props.setPdfBookStatus(id, staus)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setPdfBookStatus(id, "DEFAULT");
        } else {
            this.setPdfBookStatus(id, "SUSPENDED");
        }
        this.close();
    }
    render() {
        const { success,
            error } = this.props
        const { status, id } = this.props

        let contentPreview = null;

        if (success === false) {
            alert("Error: " + error);
            this.props.emptyPdfBook();
            // this.props.getBookData(id);
            this.props.fetchPdfBooks()
        }
        else if (success === true) {
            alert("Book status updated successfully");
            this.props.emptyPdfBook();
            // this.props.getBookData(id);
            //////this.props.fetchPdfBooks()
            return (<Redirect to={VIEW_BOOK_PARENT} ></Redirect>)
        }

        if (status === "SUSPENDED") {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>
                        <i className="" >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }

        return (<span >
            {contentPreview}
            <Confirm id="modal"
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </span>)
    }
}


const mapStateToProps = state =>
({
    success: state.pdfBook.book_status_updated_successfully,
    error: state.pdfBook.error_setting_status
})
export default connect
    (mapStateToProps,
        {
            setPdfBookStatus,
            emptyPdfBook,
            getPdfBookData,
            fetchPdfBooks
        })
    (SetPdfBookState)
