import React, { Component } from "react";
import { connect } from "react-redux";
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { publishStory, emptyStory } from "../../Actions/StoryActions";
import { emptyEvaluationAll } from "../../Actions/EvaluationActions";

import Player from "video-react/lib/components/Player";
import { Button, Icon } from "semantic-ui-react";
import { Redirect, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/addStory.css";
import { clearQuestions } from "../../Actions/QuestionActions";
import QuestionPreview from "../Layout/QuestionPreview";
import AudioPlayer from "react-h5-audio-player";
import { addSeriesIDtoRedirectionPage } from "../../Actions/RedirectionPagesActions.js";
/* Routes */
import { ALL_STORIES, STORY_EVALUATION,VIEW_BOOK_PARENT } from "../../routes";
import RenderVideoWithMusic from "../VideoPreview/RenderVideoWithMusic.js";
import RenderVideoWithoutMusic from "../VideoPreview/RenderVideoWithoutMusic.js";
import { STORY_FOLDER_NAME } from "../../portal/src/constants.js";
import PreviewVideoWithMusic from "../VideoPreview/PreviewVideoWithMusic.js";
import PreviewVideoWithoutMusic from "../VideoPreview/PreviewVideoWithoutMusic.js";

class PublishStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoWithMusicReady: false,
      videoWithoutMusicReady: false,
      isButtonDisabled: false,
    };
  }
  publishStory = (e) => {
    const { story } = this.props;
    const { redirectionPage } = this.props;
    console.log(redirectionPage.pageName);
    this.props.addSeriesIDtoRedirectionPage(
      redirectionPage.pageName,
      story.selected_storyParent_id
    );

    const { activity } = this.props;
    this.props.publishStory(activity.name);
  };
  handleChangeVideoWithMusic = (uploadInfo,videoKey) => {
    // console.log(episodes);
    // console.log("Received uploadInfo in parent:", uploadInfo);
    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {
      
    // } 
  };
  handleChangeVideoWithoutMusic = (uploadInfo,videoKey) => {
    // console.log(episodes);
    // console.log("Received uploadInfo in parent:", uploadInfo);
    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {
      
    // } 
  };
  isButtonDisabled = () => {
    const { story } = this.props;
    const { videoWithMusicReady, videoWithoutMusicReady } = this.state;
    if (story.videoKeyVideoWithMusic && story.videoKeyVideoWithoutMusic){
      if (!videoWithMusicReady || !videoWithoutMusicReady){
        return true;
      }
    }

    else if (story.videoKeyVideoWithMusic && !story.videoKeyVideoWithoutMusic){
      if (!videoWithMusicReady){
        return true;
      }
    }

    else if (!story.videoKeyVideoWithMusic && story.videoKeyVideoWithoutMusic){
      if (!videoWithoutMusicReady){
        return true;
      }
    }

    else {
      return false;
    }
  };
  render() {
    const { storyerror } = this.props;
    const { storysucess } = this.props;
    const { activityerror } = this.props;
    const { activitysucess } = this.props;
    const { loading } = this.props;

    if (activitysucess === false) {
      alert(activityerror);
      this.props.emptyStory();
      this.props.clearQuestions();
      this.props.emptyEvaluationAll();
    } else if (activitysucess === true) {
      if (storysucess === false) {
        alert("error missing data or network " + storyerror);
        this.props.emptyStory();
        this.props.clearQuestions();
        this.props.emptyEvaluationAll();
      } else if (storysucess === true) {
        alert("story added Successfully");
        this.props.emptyStory();
        this.props.clearQuestions();
        this.props.emptyEvaluationAll();

        const { redirectionPage } = this.props;
        var pageName = redirectionPage.pageName;

        //return <Redirect to={pageName}></Redirect>;
        
        return (<Redirect to={{pathname:VIEW_BOOK_PARENT,state: {toTab:'STORY'}}}></Redirect>)  
      }
    }
    const { story } = this.props;
    const { questions } = this.props;

    let buttonName = story.radiobutton;
    console.log(buttonName);
    if (story.radiobutton === "now") {
      buttonName = "publish";
    } else if (story.radiobutton === "schedule") {
      buttonName = "save";
    }

    let questionsList = null;
    if (questions !== undefined) {
      questionsList = <QuestionPreview state="publish" questions={questions} />;
    }

    // loading button
    let $buttonSubmitLoader = (
      <Button id="episode_publish" onClick={this.publishStory}
       disabled={this.isButtonDisabled()}
      >
        {buttonName}
      </Button>
    );

    if (loading === true) {
      $buttonSubmitLoader = (
        <button id="episode_publish" class="ui loading button">
          Loading
        </button>
      );
      console.log("loading");
    }

    let audio = "";
    if (story.voice) {
      audio = (
        <AudioPlayer src={story.voice} onPlay={(e) => console.log("onPlay")} />
      );
    } else {
      audio = (
        <td fluid className="episode_label">
          {" "}
          No Audio
        </td>
      );
    }

    let story_VideoPreviewWithMusic = null;
    if (story.Video_With_Music) {
      story_VideoPreviewWithMusic = (
        <Player playsInline fluid src={story.Video_With_Music} />
      );
    } else {
      story_VideoPreviewWithMusic = (
        <div className="previewText">No Video for Preview</div>
      );
    }

    let story_VideoPreviewWithoutMusic = null;
    if (story.Video_Without_Music) {
      story_VideoPreviewWithoutMusic = (
        <Player playsInline fluid src={story.Video_Without_Music} />
      );
    } else {
      story_VideoPreviewWithoutMusic = (
        <div className="previewText">No Video for Preview</div>
      );
    }
    const handleVideoWithMusicReady = () => {
      this.setState({ videoWithMusicReady: true });
    };
    const handleVideoWithoutMusicReady = () => {
      this.setState({ videoWithoutMusicReady: true });
    };

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="main-progress-bar">
                        <ol className="progress-tracker">
                          <li className="step active s2-active">
                            <span className="step-name small-font">
                              ِAdd Story
                            </span>
                          </li>
                          <li className="step active s2-active">
                            <span className="step-name small-font">
                              Add activity
                            </span>
                          </li>
                          <li className="step active s2-active">
                            <span className="step-name small-font">
                              Add Evaluation
                            </span>
                          </li>
                          <li className="step active">
                            <span className="step-name small-font">
                              Puplish Story
                            </span>
                          </li>
                        </ol>
                      </div>
                      <br />
                      <div className="container">
                        <div className="add-items-s3 mt-4">
                        <div>
                              <label className="label">
                                Video with music
                              </label>
                              <section className="video_section">
                                  <PreviewVideoWithMusic
                                  videoKey={story.videoKeyVideoWithMusic}
                                  id="VideoWithMusic" 
                                  onVideoReady={handleVideoWithMusicReady}>
                                  </PreviewVideoWithMusic>
                              </section>
                              <br />
                            </div>
                          <div>
                              <label className="label">
                                Video without music
                              </label>
                              <section className="video_section">
                                  <PreviewVideoWithoutMusic
                                  videoKey={story.videoKeyVideoWithoutMusic}
                                  id="VideoWithoutMusic" 
                                  onVideoReady={handleVideoWithoutMusicReady}>
                                  </PreviewVideoWithoutMusic>
                              </section>

                              <br />
                            </div>
                          {/* {story.videoKeyVideoWithoutMusic && (
                            <div>
                              {console.log(story.videoKeyVideoWithMusic)}
                              <label className="label">Video with music</label>
                              <section className="video_section">
                                <RenderVideoWithMusic
                                  videoKey={story.videoKeyVideoWithMusic}
                                  id="VideoWithMusic"
                                  foldername={STORY_FOLDER_NAME}
                                  enableEdit={false}
                                  changedid={this.handleChangeVideoWithMusic}
                                  ></RenderVideoWithMusic>
                              </section>

                              <br />
                            </div>
                          )}
                          {story.videoKeyVideoWithoutMusic && (
                            <div>
                              {console.log(story.videoKeyVideoWithoutMusic)}
                              <label className="label">Video with music</label>
                              <section className="video_section">
                                <RenderVideoWithoutMusic
                                  videoKey={story.videoKeyVideoWithoutMusic}
                                  id="VideoWithoutMusic"
                                  foldername={STORY_FOLDER_NAME}
                                  enableEdit={false}
                                  changedid={this.handleChangeVideoWithoutMusic}
                                ></RenderVideoWithoutMusic>
                              </section>

                              <br />
                            </div>
                          )} */}
                          {/* {story.Video_With_Music && (
                            <div>
                              <label className="label">Video with music</label>

                              <section className="video_section">
                                {story_VideoPreviewWithMusic}
                              </section>
                              <br />
                            </div>
                          )}
                          {story.Video_Without_Music && (
                            <div>
                              <label className="label">
                                Video without music
                              </label>

                              <section className="video_section">
                                {story_VideoPreviewWithoutMusic}
                              </section>
                            </div>
                          )} */}
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-4 col-sm-12">
                            <img
                              className="image_Preview rounded"
                              src={story.Icon_Image}
                              alt=""
                            />
                          </div>
                          {/*
                                                <div className="col-md-8 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="font-weight-bold m-1 p-0">Story Name</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <p className="m-0 p-0">Story Name</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="font-weight-bold m-1 p-0">question Type</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <p className="m-0 p-0">Multiple Choice (Text)</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="font-weight-bold m-1 p-0">Schedule</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <p className="m-0 p-0">11/2/2019, 02:00 am</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="font-weight-bold m-1 p-0">Description</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <p className="m-0 p-0">
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer tooka a galley of type and scrambled it to make a type specimen book.
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>  */}

                          <div className="col">
                            <table>
                              <tr>
                                <th className="episode_label"> Story Name </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {story.Name}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  Story Name In English{" "}
                                </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {story.name_in_english}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  Selected Story Parent{" "}
                                </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {story.selected_storyParent_name}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label"> Description </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {story.Description}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  Description In English
                                </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {story.description_in_english}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  payment availability{" "}
                                </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {story.isFree}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label"> Schedule </th>
                                <td fluid className="episode_label">
                                  {story.Publish_Date}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label"> Audio </th>
                                {audio}
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="mt-5 mb-4">
                          <div className="hr">
                            <span className="hr-title font-weight-bold">
                              The Questions
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">{questionsList}</div>
                        </div>
                      </div>
                      {$buttonSubmitLoader}
                      <Link to={STORY_EVALUATION}>
                        <Button
                          icon
                          labelPosition="left"
                          id="episode_backButton"
                        >
                          back
                          <Icon name="left arrow" />
                        </Button>
                      </Link>
                      <br /> <br /> <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.stories.loading,
  videos: state.storyVideos.items,
  story: state.stories.item,
  activity: state.activities.item,
  questions: state.questions.items,
  storyerror: state.stories.error_adding_story,
  activityerror: state.activities.error_adding_activity,
  storysucess: state.stories.story_added_successfully,
  activitysucess: state.activities.activity_added_successfully,
  redirectionPage: state.redirectionPage.item,
});
export default connect(mapStateToProps, {
  publishStory,
  emptyStory,
  clearQuestions,
  emptyEvaluationAll,
  addSeriesIDtoRedirectionPage,
})(PublishStory);
