import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
/*Headers */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
import { clearQuestions } from '../../Actions/QuestionActions'
// import { publishChannel, emptyChannel } from '../../Actions/RadioChannelAction';
import { publishPdfBook, emptyPdfBook } from '../../Actions/PdfBookAction';
import { publishQuestion, assignCorrrectAnswerToQuestion } from '../../Actions/QuestionActions';
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*css*/
import '../../css/AppCss/Episode/PublishEpisode.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../node_modules/video-react/dist/video-react.css';
/*Other components */
import QuestionPreview from '../Layout/QuestionPreview';
import AudioPlayer from "react-h5-audio-player";
import { ALL_PDF_BOOK, GET_PDF_BOOK_EVAL,VIEW_BOOK_PARENT } from '../../routes';

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class PublishPdfBook extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        sucess: ''
    }
    

    publishPdfBook = (e) => {
        const { activity } = this.props;
        this.props.publishPdfBook(activity.name);
    }

    render() {
        const { pdfBook } = this.props;
        const { questions } = this.props;

        const { bookrror, booksucess, loading } = this.props;
        const { activityerror } = this.props
        const { activitysucess } = this.props

        let buttonName = pdfBook.bookbutton;
        if (pdfBook.bookbutton === "now") {
            buttonName = "publish"
        }
        else if (pdfBook.bookbutton === "schedule") {
            buttonName = "save"
        }

        if (activitysucess === false) {
            alert(activityerror)
            this.props.emptyPdfBook();
            this.props.clearQuestions();
            this.props.emptyEvaluationAll();
        }
        else if (activitysucess === true) {
            if (booksucess === false) {
                alert("error missing data or network " + bookrror)
                this.props.emptyPdfBook();
                this.props.clearQuestions();
                this.props.emptyEvaluationAll();

            }

            else if (booksucess === true) {
                alert("Book added Successfully")
                this.props.emptyPdfBook();
                this.props.clearQuestions();
                this.props.emptyEvaluationAll();

                const { redirectionPage } = this.props;
                //return (<Redirect to={ALL_PDF_BOOK} ></Redirect>)
                
                //return (<Redirect to={VIEW_BOOK_PARENT} ></Redirect>)
                return (<Redirect to={{pathname:VIEW_BOOK_PARENT,state: {toTab:'PDFBOOK'}}}></Redirect>)  
            }
        }

        let questionsList = null;
        if (questions !== undefined) {
            questionsList = (
                <QuestionPreview state='publish'
                    questions={questions}
                />)
        }

        // loading button
        let $buttonSubmitLoader = (
            <Button id="episode_publish"
                onClick={this.publishPdfBook}>
                {buttonName}
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <Button id="episode_publish"
                onClick={this.publishPdfBook}>
                {buttonName}
            </Button>);
            // <button id="episode_publish"
            //     class="ui loading button">Loading</button>);
            // console.log("loading");
        }

        let titleAudio = ''
        console.log('pdfBook =====>', pdfBook)
        if ((pdfBook.titleAudio )
        ) {
            titleAudio = (
                <AudioPlayer
                    src={pdfBook.titleAudio}
                    onPlay={e => console.log("onPlay")}
                />)
        }
        else {
            titleAudio = (
                <td fluid className="episode_label"> No Title Audio</td>
            )
        }

        
        let pdfFile = ''
        if ((pdfBook.pdfFile )
        ) {
            pdfFile = <a href={pdfBook.pdfFile} download style={{wordBreak: "break-all"}}>View Pdf File</a>
        }
        else {
            pdfFile = (
                <td fluid className="episode_label"> No Pdf File</td>
            )
        }
        console.log('pdf', pdfBook)
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">ِ
                                                            Add Pdf Book</span>
                                                    </li>
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">
                                                            Add activity</span>
                                                    </li>
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">
                                                            Add Evaluation</span>
                                                    </li>
                                                    <li className="step active">
                                                        <span className="step-name small-font">
                                                            Puplish Pdf Book</span>
                                                    </li>
                                                </ol>
                                            </div>
                                            <br />
                                            <div className="container">
                                                <label htmlFor="" >{pdfBook.name}</label>
                                                
                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img 
                                                         src={pdfBook.image}
                                                          alt="" 
                                                          className="rounded image_Preview" />
                                                    </div>

                                                    <div className="col">
                                                        <table style={{width: "100%"}}>
                                                            <tr>
                                                                <th className="episode_label"> Book Name </th>
                                                                <td fluid className="episode_label"> {pdfBook.name} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Number Of Questions </th>
                                                                <td fluid className="episode_label">{questions.length} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">{GMTtolocalTime(pdfBook.PublishDate)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {pdfBook.isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Title Audio </th>
                                                                {titleAudio}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> PDF File </th>
                                                                {pdfFile}
                                                            </tr>

                                                        </table>
                                                    </div>


                                                </div>
                                                <br />
                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Questions</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionsList}




                                                </div>

                                                <br />
                                                {$buttonSubmitLoader}
                                                <Link to={GET_PDF_BOOK_EVAL}>
                                                    <Button icon labelPosition='left' id="episode_backButton" >
                                                        back
                                                            <Icon name='left arrow' />
                                                    </Button>
                                                </Link>
                                                <br /> <br /> <br />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

PublishPdfBook.propTypes = {
    publishPdfBook: propTypes.func.isRequired,
    publishActivity: propTypes.func.isRequired,
    publishQuestion: propTypes.func.isRequired,
    assignCorrrectAnswerToQuestion: propTypes.func.isRequired
}
const mapStateToProps = state => ({
    loading: state.pdfBook.loading,
    pdfBook: state.pdfBook.item,
    bookrror: state.pdfBook.error_adding_book,
    booksucess: state.pdfBook.book_added_successfully,

    activity: state.activities.item,
    questions: state.questions.items,
    question: state.questions.item,

    activityerror: state.activities.error_adding_activity,
    activitysucess: state.activities.activity_added_successfully,
    redirectionPage: state.redirectionPage.item

});


export default
    connect(
        mapStateToProps,
        {
            publishPdfBook,
            publishQuestion,
            assignCorrrectAnswerToQuestion,
            emptyPdfBook,
            clearQuestions,
            emptyEvaluationAll,
            addSeriesIDtoRedirectionPage
        }
    )
        (PublishPdfBook)
