import React, { Component } from "react";
import { Dropdown, Input, Button, Icon } from "semantic-ui-react";

import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import "../../css/addStory.css";
import Footer from "../Layout/Footer";
import PublishDate from "../Layout/PublishDate";
import UploadImage from "../S3Uploading/UploadImage";
import UploadVideo from "../S3Uploading/UploadVideo";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import UploadAudio from "../S3Uploading/UploadAudio";
/*Actions */
import { fetchAllSubValues } from "../../Actions/ContentSubValueAction";
import { addStory } from "../../Actions/StoryActions";
import { addVideos } from "../../Actions/StoryVideoAction";
import { fetchAllStoryParent ,getStoryParentData,emptyStoryParentWithStories } from "../../Actions/StoryParentAction";

/* Routes */
import { ADD_STORY_ACTIVITY ,VIEW_BOOK_PARENT} from "../../routes";
import { clearPreSignData, clearAll } from "../../Actions/VideoCipherActions";
import VideoWithMusicUploader from "../FilePondUploading/VideoWithMusicUploader";
import VideoWithoutMusicUploader from "../FilePondUploading/VideoWithoutMusicUploader";
import { STORY_FOLDER_NAME } from "../../portal/src/constants";
import RenderVideoWithMusic from "../VideoPreview/RenderVideoWithMusic";
import RenderVideoWithoutMusic from "../VideoPreview/RenderVideoWithoutMusic";
import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'


const is_free_type = [
  {
    key: 0,
    text: "paid",
    value: "paid",
  },
  {
    key: 1,
    text: "free",
    value: "free",
  },
];

const INITIAL_STATE = {
  redirection: false,
  contentVendorSelectedId:'',
  selected_storyParent_id: "",
  selected_storyParent_name: "",
  video_with_music_folder_id : "",
  video_with_out_music_folder_id : "",
  returned_storyParent_id_viewStoryParentPage: "",
  returned_storyParent_name_viewStoryParentPage: "",

  Name: "",
  Description: "",
  Icon_Image: "",
  Publish_Date: "",
  videoWithMusicUrl: "",
  videoWithoutMusicUrl: "",
  videoWithMusic_Duration: 0,
  videoWithoutMusic_Duration: 0,
  videoKeyVideoWithMusic: "",
  videoKeyVideoWithoutMusic: "",
  radiobutton: "",
  isFree: "",
  voice: "",
  subValueId: "",
  name_in_english: "",
  description_in_english: "",
  featured: false,
  ageFrom:'',
  ageTo:'',
  //////////////// validation
  error: true,
  errorMessage: "",
  VideoWithMusicReady: false,
  VideoWithoutMusicReady: false,
};

class AddStory extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidUpdate(prevProps) {
    const { storyParentSelected } = this.props;
  
    this.props.fetchAllContentVendors();
  
    if (storyParentSelected !== prevProps.storyParentSelected) {
     
      this.handleStoryParentSelectedChange();
    }
  }
  
  handleStoryParentSelectedChange() {


    if (this.props.storyParentSelected && this.props.storyParentSelected !== null && this.props.storyParentSelected !== undefined && this.props.storyParentSelected !== ""){
   
     if (this.props.storyParentSelected.withMusicFolderId  && this.props.storyParentSelected.withMusicFolderId !== null && this.props.storyParentSelected.withMusicFolderId !== undefined){
       this.setState ({
         video_with_music_folder_id :this.props.storyParentSelected.withMusicFolderId ,
       })
     }
   
     if (this.props.storyParentSelected.withoutMusicFolderId  && this.props.storyParentSelected.withoutMusicFolderId !== null && this.props.storyParentSelected.withoutMusicFolderId !== undefined){
       this.setState ({
         video_with_out_music_folder_id :this.props.storyParentSelected.withoutMusicFolderId ,
       })
     }
    }
   
  }

  componentDidMount() {
    this.props.fetchAllSubValues();
    this.props.fetchAllStoryParent();
    this.props.clearAll();

    const { story } = this.props;

  //console.log('ZZZZZZZZZ-3');
  //if(this.props.location.state!=null && this.props.location.state!=undefined )
  	//console.log('loc: '+this.props.location.state.bookParentId);
  

    if (story && story.selected_storyParent_id){
      this.props.getStoryParentData(story.selected_storyParent_id);
      if(story.videoKeyVideoWithMusic && story.videoKeyVideoWithMusic !== null && story.videoKeyVideoWithMusic !== "" && story.videoKeyVideoWithMusic !== undefined ){
        this.setState({ VideoWithMusicReady: true });
      }
      if(story.videoKeyVideoWithoutMusic && story.videoKeyVideoWithoutMusic !== null && story.videoKeyVideoWithoutMusic !== "" && story.videoKeyVideoWithoutMusic !== undefined ){
        this.setState({ VideoWithoutMusicReady: true });
      }
    }
    else {
      this.props.emptyStoryParentWithStories();
    }

    // let videoKeyVideoWithMusic = this.props.vdocipherUploadInfo
    //   .videoKeyVideoWithMusic
    //   ? this.props.vdocipherUploadInfo.videoKeyVideoWithMusic.videoId
    //   : "";
    // let videoKeyVideoWithoutMusic = this.props.vdocipherUploadInfo
    //   .videoKeyVideoWithoutMusic
    //   ? this.props.vdocipherUploadInfo.videoKeyVideoWithoutMusic.videoId
    //   : "";
    this.setState({
      error: false,
      Name: story.Name,
      Description: story.Description,
      Icon_Image: story.Icon_Image,
      Publish_Date: story.Publish_Date,
      videoWithMusicUrl: story.Video_With_Music,
      videoWithoutMusicUrl: story.Video_Without_Music,
      videoWithMusic_Duration: story.videoWithMusic_Duration,
      videoWithoutMusic_Duration: story.videoWithoutMusic_Duration,
      videoKeyVideoWithMusic: story.videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic: story.videoKeyVideoWithoutMusic,
      isFree: story.isFree,
      voice: story.voice,
      subValueId: story.subValueId,
      name_in_english: story.name_in_english,
      description_in_english: story.description_in_english,
      selected_storyParent_id: story.selected_storyParent_id,
      selected_storyParent_name: story.selected_storyParent_name,
//       video_with_music_folder_id :story.video_with_music_folder_id ,
// video_with_out_music_folder_id : story.video_with_out_music_folder_id,
      returned_storyParent_id_viewStoryParentPage:
        story.selected_storyParent_id,
      returned_storyParent_name_viewStoryParentPage:
        story.selected_storyParent_name,
      radiobutton: story.radiobutton,
    });  

    const { parentId, parentName ,withMusicFolderId,withoutMusicFolderId} = this.props.location;

    if (this.props.location.state!=null&&this.props.location.state.bookParentId) {
      this.setState({
        returned_storyParent_name_viewStoryParentPage: parentName,
        returned_storyParent_id_viewStoryParentPage: parentId,
        //selected_storyParent_id: parentId,
        selected_storyParent_name: parentName,
      });
    }

    
    if (withMusicFolderId && withMusicFolderId !== null & withMusicFolderId !== undefined){
      this.setState({video_with_music_folder_id : withMusicFolderId})
    }

    if (withoutMusicFolderId && withoutMusicFolderId !== null & withoutMusicFolderId !== undefined){
      this.setState({video_with_out_music_folder_id : withoutMusicFolderId})
    }
  }

handleAgeFromSelection = (e, { value }) => {
       this.setState({ ageFrom: value })
    }
handleAgeToSelection = (e, { value }) => {
       this.setState({ ageTo: value })
    }
    
handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }
    
  handleUploadInfoWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyVideoWithMusic: this.state.videoKeyVideoWithMusic });
    }
  };
  handleUploadInfoWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithoutMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyVideoWithoutMusic: this.state.videoKeyVideoWithoutMusic  });
    }
  };

  handleChangeVideoWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithMusic: uploadInfo.videoId });
    } 
    else {
      this.setState({ videoKeyVideoWithMusic: this.state.videoKeyVideoWithMusic });
    }
  };
  handleChangeVideoWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithoutMusic: uploadInfo.videoId });
    } 
    else {
      this.setState({ videoKeyVideoWithoutMusic: this.state.videoKeyVideoWithoutMusic });
    }
  };
  // setVideoWithMusicReady = (isReady) => {

  //   if (
  //    isReady
  //   )
  //     this.setState({ VideoWithMusicReady: true });
  // };
  // setVideoWithoutMusicReady = (isReady) => {

  //   if (
  //    isReady
  //   )
  //     this.setState({ VideoWithoutMusicReady: true });
  // };
  getPublishDate(publishDateTime, radiobutton) {
    if (radiobutton) {
      this.setState({ radiobutton: radiobutton });
    }

    if (publishDateTime) {
      this.setState({ Publish_Date: publishDateTime });
    }
  }

  getIconImageUrl(imageUrl) {
    if (imageUrl === "delete") {
      this.setState({
        Icon_Image: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });
    } else if (imageUrl !== undefined && imageUrl !== "") {
      this.setState({ Icon_Image: imageUrl });
      if (
        this.state.Name !== "" &&
        this.state.Name !== " " &&
        this.state.Name !== null
        // && this.state.name_in_english !== ''
        // && this.state.name_in_english !== ' '
        // && this.state.name_in_english !== null
        // && this.state.videoWithMusicUrl != undefined
        // && this.state.videoWithMusicUrl != ''
        // && this.state.videoWithoutMusicUrl != undefined
        // && this.state.videoWithoutMusicUrl != ''
        // && this.state.videoWithMusic_Duration != 0
        // && this.state.videoWithMusic_Duration != undefined
        // && this.state.videoWithoutMusic_Duration != 0
        // && this.state.videoWithoutMusic_Duration != undefined
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    }
  }

  handlePaymentAva = (e, { value }) => {


    this.setState({
      isFree: value,
    });
  };

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
    this.setState({ [e.target.name]: checkSpaces });
    let nameOfState = e.target.name;
    setTimeout(() => {
      if (specialCharacters.test(this.state.Name) || specialCharacters.test(this.state.name_in_english)){
        this.setState({
          error: true,
          errorMessage: "Story name can't contain spaecial characters",
        });
      }
      else if (
        //(this.state.Icon_Image === '' || this.state.Icon_Image === null || this.state.Icon_Image === undefined)
        // ||
        this.state.Name === "" ||
        this.state.Name === null ||
        this.state.Name === " " ||
        (this.state.description_in_english &&
          this.state.description_in_english.length > 255)
        // || (this.state.name_in_english === '' || this.state.name_in_english === null || this.state.name_in_english === ' ')
        // || (this.state.videoWithMusicUrl == undefined || this.state.videoWithMusicUrl == '')
        // || (this.state.videoWithoutMusicUrl == undefined || this.state.videoWithoutMusicUrl == '')
        // || (this.state.videoWithMusic_Duration == 0 || this.state.videoWithMusic_Duration == undefined)
        // || (this.state.videoWithoutMusic_Duration == 0 || this.state.videoWithoutMusic_Duration == undefined)
      ) {
        this.setState({
          error: true,
          errorMessage:
            nameOfState === "description_in_english"
              ? "Description in English is too long (greater than 255 characters)."
              : "Empty Data is not accepted",
        });
      }
      else if (
        //(this.state.Icon_Image !== '' && this.state.Icon_Image !== null
        //&& this.state.Icon_Image !== undefined)
        //&&
        this.state.Name !== "" &&
        this.state.Name !== null &&
        this.state.Name !== " " &&
        this.state.description_in_english &&
        this.state.description_in_english.length <= 255
        // && (this.state.name_in_english !== '' && this.state.name_in_english !== null && this.state.name_in_english !== ' ')
        // && (this.state.videoWithMusicUrl !== undefined && this.state.videoWithMusicUrl !== '')
        // && (this.state.videoWithoutMusicUrl !== undefined && this.state.videoWithoutMusicUrl !== '')
        // && (this.state.videoWithMusic_Duration != 0 && this.state.videoWithMusic_Duration != undefined)
        // && (this.state.videoWithoutMusic_Duration != 0 && this.state.videoWithoutMusic_Duration != undefined)
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
      // else{
      //     this.setState({error: false, errorMessage: "Empty Data is not accepted"});
      // }
    }, 0);
  };
  handleCheckboxChange = () => {
    this.setState({ featured: !this.state.featured })
  };
  getvideoWithMusicUrl(videoUrl, duration) {
    if (videoUrl !== "" && videoUrl !== "delete") {
      this.setState({
        videoWithMusicUrl: videoUrl,
        videoWithMusic_Duration: duration,
      });
      if (
        this.state.Name !== "" &&
        this.state.Name !== " " &&
        this.state.Name !== null
        //  && this.state.Icon_Image != undefined && this.state.Icon_Image != ''
        // && this.state.videoWithoutMusicUrl != undefined && this.state.videoWithoutMusicUrl != ''
        // && this.state.videoWithMusic_Duration != 0 && this.state.videoWithoutMusic_Duration != 0
        // && this.state.videoWithMusic_Duration != undefined
        // && this.state.videoWithoutMusic_Duration != undefined
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    } else {
      this.setState({
        videoWithMusicUrl: "",
        videoWithMusic_Duration: 0,
      });
    }
  }

  getVideoWithoutMusicUrl(videoUrl, duration) {
    if (videoUrl !== "" && videoUrl !== "delete") {
      this.setState({
        videoWithoutMusicUrl: videoUrl,
        videoWithoutMusic_Duration: duration,
      });
      if (
        this.state.Name !== "" &&
        this.state.Name !== " " &&
        this.state.Name !== null
        //&& this.state.Icon_Image != undefined && this.state.Icon_Image != ''
        // && this.state.videoWithMusicUrl != undefined
        // && this.state.videoWithMusicUrl != ''
        // && this.state.videoWithMusic_Duration != 0
        // && this.state.videoWithoutMusic_Duration != 0
        // && this.state.videoWithMusic_Duration != undefined
        // && this.state.videoWithoutMusic_Duration != undefined
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    } else {
      this.setState({
        videoWithoutMusicUrl: "",
        videoWithoutMusic_Duration: 0,
      });
    }
  }

  getAudio(audioUrl) {
    // get  Audio
    if (audioUrl) {
      // check if url =  delete send empty to DB
      if (audioUrl === "delete") {
        this.setState({ voice: "" });
    
      }
      // else send the url to DB
      else {
        this.setState({ voice: audioUrl });
 
      }
    }
  }
/*
  handleStoryParentChange = (e, { value }) => {
    this.setState({ selected_storyParent_id: value[0].id });
    this.setState({ selected_storyParent_name: value[1].name });
    this.setState({ video_with_music_folder_id: value[2].folderIdWithMusic });
    this.setState({ video_with_out_music_folder_id: value[3].folderIdWithoutMusic });
  };
  */

  handleSubValueChange = (e, { value }) => {
    this.setState({ subValueId: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      Name,
      Icon_Image,
      Publish_Date,
      Description,
      videoWithMusicUrl,
      videoWithoutMusicUrl,
      videoWithMusic_Duration,

      videoWithoutMusic_Duration,
      radiobutton,
      isFree,
      voice,
      subValueId,
      selected_storyParent_id,
      selected_storyParent_name,
      name_in_english,
      description_in_english,
      videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic,
      VideoWithMusicReady,
      VideoWithoutMusicReady,
      featured,contentVendorSelectedId,ageFrom,ageTo
    } = this.state;

    if (e.nativeEvent.submitter.id === "nextButton") {
      if (isFree === "" || isFree == undefined) {
        alert("You must choose payment availability");
        this.setState({ validateInput: false });

        e.preventDefault();
      }

let bookParent = this.props.location.state.bookParentId;
      //if (selected_storyParent_id === "" || selected_storyParent_id == undefined || selected_storyParent_name === "" || selected_storyParent_name == undefined ) {
	if(bookParent === "" || bookParent == undefined || bookParent === "" || bookParent == undefined ) {
        alert("Empty Story Parent");
        this.setState({ validateInput: false });
      }

      if (Publish_Date === "" || Publish_Date == undefined) {
        this.setState({ validateInput: false });
      } 
      // else if (
      //   (videoWithMusicUrl === "" || videoWithMusicUrl === undefined) &&
      //   (videoKeyVideoWithMusic === "" || videoKeyVideoWithMusic === undefined)
      // ) {
      //   alert("Empty Video With Music");
      //   this.setState({ validateInput: false });
      // } 
      // else if (
      //   (videoWithoutMusicUrl === "" || videoWithoutMusicUrl === undefined) &&
      //   (videoKeyVideoWithoutMusic === "" ||
      //     videoKeyVideoWithoutMusic === undefined)
      // ) {
      //   alert("Empty Video Without Music");
      //   this.setState({ validateInput: false });
      // } 
      // else if (
      //   videoKeyVideoWithMusic !== "" &&
      //   videoKeyVideoWithMusic !== undefined &&
      //   !VideoWithMusicReady
      // ) {
      //   alert("Please wait until Video with is ready for playback");
      //   this.setState({ validateInput: false });
      // } else if (
      //   videoKeyVideoWithoutMusic !== "" &&
      //   videoKeyVideoWithoutMusic !== undefined &&
      //   !VideoWithoutMusicReady
      // ) {
      //   alert("Please wait until Video without is ready for playback");
      //   this.setState({ validateInput: false });
      // } 
      else {
        this.setState({ validateInput: true });
        this.props.addStory(
          Name,
          Description,
          Icon_Image,
          Publish_Date,
          videoWithMusicUrl,
          videoWithoutMusicUrl,
          videoWithMusic_Duration,

          videoWithoutMusic_Duration,
          radiobutton,
          isFree,
          voice,
          subValueId,
          //selected_storyParent_id,
          bookParent,
          selected_storyParent_name,
          name_in_english,
          description_in_english,
          videoKeyVideoWithMusic,
          videoKeyVideoWithoutMusic,
          featured,contentVendorSelectedId,ageFrom,ageTo
        );
      }
    }
  };

  handleCancel = (e) => {
    this.setState({ redirection: true });
  };
  
  render() {
    const { allSubValues } = this.props;
    const subValuesList = [];
    for (let i = 0; i < allSubValues.length; i++) {
      subValuesList.push({
        key: allSubValues[i].id,
        text: allSubValues[i].contentValue,
        value: allSubValues[i].id,
      });
    }
    
// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------
// --------------------------------
const AgeFromArr = [];

for (let i = 1; i <= 10; i++) 
    {
      AgeFromArr.push({
        key: i,
        text: i,
        value: i,
      });
    }
// --------------------------------
    
    const { storyParent } = this.props;
    
    //console.log('storyParent in addStory :'+JSON.stringify(storyParent,null,2));
    const storyParentList = [];
    for (let i = 0; i < storyParent.length; i++) {
	
	//console.log(' Story Idddddddddddddd :'+storyParent[i].id);
	
      if (!storyParent[i].withMusicFolderId || storyParent[i].withMusicFolderId=== null ||storyParent[i].withMusicFolderId ==="" ||
      !storyParent[i].withoutMusicFolderId || storyParent[i].withoutMusicFolderId=== null ||storyParent[i].withoutMusicFolderId ===""){
        storyParentList.push({
          key: storyParent[i].id,
          text: storyParent[i].name,
          value: [{ id: storyParent[i].id }, { name: storyParent[i].name },{folderIdWithMusic : ""}
          , {folderIdWithoutMusic : ""}],
        });
      }
      else {
        storyParentList.push({
          key: storyParent[i].id,
          text: storyParent[i].name,
          value: [{ id: storyParent[i].id }, { name: storyParent[i].name },{folderIdWithMusic : storyParent[i].withMusicFolderId}
          , {folderIdWithoutMusic : storyParent[i].withoutMusicFolderId}],
        });
      }
    }

//console.log("storyParentList : "+storyParentList.length);
//console.log('storyParentList in addStory :'+JSON.stringify(storyParentList,null,2));

    const {
      Name,
      Icon_Image,
      Publish_Date,
      videoWithMusicUrl,
      videoWithoutMusicUrl,
      videoWithMusic_Duration,

      videoWithoutMusic_Duration,
      radiobutton,
      isFree,
      selected_storyParent_id,
      selected_storyParent_name,
      name_in_english,
      videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic,
    } = this.state;

    const isInvalid =
      Name === "" ||
      Name == undefined ||
      Icon_Image === "" ||
      Icon_Image == undefined ||
      videoWithMusicUrl === "" ||
      videoWithMusicUrl == undefined ||
      videoWithoutMusicUrl === "" ||
      videoWithoutMusicUrl == undefined ||
      videoWithMusic_Duration === 0 ||
      videoWithMusic_Duration == undefined ||
      videoWithoutMusic_Duration === 0 ||
      videoWithoutMusic_Duration == undefined ||
      isFree === "" ||
      isFree == undefined ||
      selected_storyParent_id === "" ||
      selected_storyParent_id == undefined ||
      selected_storyParent_name === "" ||
      selected_storyParent_name == undefined;

  
    // for dropdown Story Parent selection
    let selectedStoryParent = null;
    if (this.state.returned_storyParent_id_viewStoryParentPage) {
      selectedStoryParent = (
        <input
          dir="auto"
          className="form-control"
          fluid
          id="episode_description"
          value={this.state.returned_storyParent_name_viewStoryParentPage}
        />
      );
    } else {
      selectedStoryParent = (
        <Dropdown
        disabled={this.state.videoKeyVideoWithMusic !== "" && this.state.videoKeyVideoWithoutMusic !== ""&&
        this.state.videoKeyVideoWithMusic !== null && this.state.videoKeyVideoWithoutMusic !== null &&
        this.state.videoKeyVideoWithMusic !== undefined && this.state.videoKeyVideoWithoutMusic !== undefined }
          fluid
          selection
          placeholder="Select Story Parent"
          name="songParent_id"
          // id="episode_series_id"
          onChange={this.handleStoryParentChange}
          options={storyParentList}
        />
      );
    }

    if (this.state.redirection === true) {
      const { redirectionPage } = this.props;
      var pageName = redirectionPage.pageName;
      //return <Redirect to={pageName}></Redirect>;
      
      return <Redirect to={VIEW_BOOK_PARENT}></Redirect>;
    }

    if (this.state.validateInput == true) {
      return <Redirect to={ADD_STORY_ACTIVITY}></Redirect>;
    }

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="container">
                        <h3 className="main-title mt-2 font-weight-bold">
                          Add Story
                        </h3>
                        <div className="card mt-3 p-0">
                          <div className="card-body p-4">
                            <form onSubmit={this.handleSubmit.bind(this)}>
                              <div className="main-progress-bar">
                                <ol className="progress-tracker">
                                  <li className="step active">
                                    <span className="step-name small-font">
                                      Add Story
                                    </span>
                                  </li>
                                  <li className="step">
                                    <span className="step-name small-font">
                                      Add activity
                                    </span>
                                  </li>
                                  <li className="step ">
                                    <span className="step-name small-font">
                                      Add Evaluation
                                    </span>
                                  </li>
                                  <li className="step">
                                    <span className="step-name small-font">
                                      Puplish Story
                                    </span>
                                  </li>
                                </ol>
                              </div>
                              <br />
                              <p style={{ color: "red" }}>
                                {this.state.errorMessage}{" "}
                              </p>
                              <div className="row mt-4">
                                {/*  image dropzone*/}
                                <div className="col-4">
                                  {/* <span className="required_input">*</span> */}
                                  <UploadImage
                                    getImageURLCallBack={this.getIconImageUrl.bind(
                                      this
                                    )}
                                    imageUrl={this.state.Icon_Image}
                                    imagepreview={this.state.Icon_Image}
                                    s3DirectryrName="story"
                                  />
                                  <div class="upload-hint x-small-font">
                                    This image size should be 200px * 200px
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="small-label">
                                      <span className="required_input">*</span>
                                      Story Name
                                    </label>
                                    <input
                                      dir="auto"
                                      className="form-control"
                                      type="text"
                                      id="Name"
                                      name="Name"
                                      onChange={this.handleChange}
                                      value={this.state.Name}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label className="small-label">
                                      {/* <span className="required_input">*</span> */}
                                      Story Name In English
                                    </label>
                                    <input
                                      dir="auto"
                                      className="form-control"
                                      type="text"
                                      id="name_in_english"
                                      name="name_in_english"
                                      onChange={this.handleChange}
                                      value={this.state.name_in_english}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label
                                      className="small-label"
                                      for="exampleFormControlTextarea1"
                                    >
                                      Description
                                    </label>
                                    <textarea
                                      dir="auto"
                                      className="form-control"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      name="Description"
                                      onChange={this.handleChange}
                                      value={this.state.Description}
                                    ></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label
                                      className="small-label"
                                      for="exampleFormControlTextarea1"
                                    >
                                      Description In English
                                    </label>
                                    <textarea
                                      dir="auto"
                                      className="form-control"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      name="description_in_english"
                                      onChange={this.handleChange}
                                      value={this.state.description_in_english}
                                    ></textarea>
                                  </div>

                                  <label htmlFor="name" className="label">
                                    Audio
                                  </label>

                                  <UploadAudio
                                    getAudioUrlCallBack={this.getAudio.bind(
                                      this
                                    )}
                                    audioPreview={this.state.voice}
                                    audioUrl={this.state.voice}
                                    s3DirectryrName="story/audio"
                                  />
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={this.state.featured}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  {' Featured'}
                                </label>
                                
                                 <br />
<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                              />
                              <br />
                                                              
                                </div>
                              </div>
                              <br />
                             
                              
                              {/*
                              <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                <span className="required_input">*</span>
                                Select Story Parent
                              </label>
                              <br />
                              {selectedStoryParent}
                              <br />
                              */}
                              <label className="label">Video With Music</label>
                            
                               <div>
                               <VideoWithMusicUploader
                               isNew={true}
                                 id="VideoWithMusic"
                                 onUploadInfo={this.handleUploadInfoWithMusic}
                                 folderName = {this.state.video_with_music_folder_id}
                                 disabled={!this.state.video_with_music_folder_id || this.state.video_with_music_folder_id === "" || this.state.video_with_music_folder_id === null ?true : false}
                               />
                             </div>
                              
                             
                              <br />
                              <label className="label">
                                Video Without Music
                              </label>
                             
                               <div>
                               <VideoWithoutMusicUploader
                                    isNew={true}
                                 id="VideoWithoutMusic"
                                 onUploadInfo={
                                   this.handleUploadInfoWithoutMusic
                                 }
                                 folderName = {this.state.video_with_out_music_folder_id}
                                 disabled={!this.state.video_with_out_music_folder_id || this.state.video_with_out_music_folder_id === "" || this.state.video_with_out_music_folder_id === null ?true : false}
                               />
                             </div>
                              
<div class="row">
	<div class="col-3">
                              
							 <label htmlFor="episode_ageFrom_id" className="label" >                                
                                Select Age From
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age From"
                                id="episode_ageFrom_id"
                                onChange={this.handleAgeFromSelection}
                                options={AgeFromArr}
                              />
                              <br />                              
	</div>
	<div class="col-3">
                              
							 <label htmlFor="episode_ageTo_id" className="label" >                                
                                Select Age To
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age To"
                                id="episode_ageTo_id"
                                onChange={this.handleAgeToSelection}
                                options={AgeFromArr}
                              />
                              <br />                              
	</div>	
</div> 
                              
                            
                              <br />
                              {/* <label className="label">
                      
                                Video with music
                              </label>
                              <UploadVideo
                                getVideoURLCallBack={this.getvideoWithMusicUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.videoWithMusicUrl}
                                videoUrl={this.state.videoWithMusicUrl}
                                s3DirectryrName="story"
                              />
                              <br />
                              <label
                                htmlFor="videoWithMusic_Duration"
                                className="label"
                              >
                              
                                Video with music duration
                              </label>
                              <br />
                              <Input
                                fluid
                                disabled
                                type="number"
                                min="0"
                                step="0.01"
                                name="videoWithMusic_Duration"
                                id="videoWithMusic_Duration"
                                onChange={this.handleChange}
                                value={this.state.videoWithMusic_Duration}
                              />
                              <br /> <br />
                              <hr />
                              <label className="label">
                              
                                Video without music
                              </label>
                              <UploadVideo
                                getVideoURLCallBack={this.getVideoWithoutMusicUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.videoWithoutMusicUrl}
                                videoUrl={this.state.videoWithoutMusicUrl}
                                s3DirectryrName="story"
                              />
                              <br />
                              <label
                                htmlFor="videoWithoutMusic_Duration"
                                className="label"
                              >
                            
                                Video without music duration
                              </label>
                              <br />
                              <Input
                                fluid
                                disabled
                                type="number"
                                min="0"
                                step="0.01"
                                name="videoWithoutMusic_Duration"
                                id="videoWithoutMusic_Duration"
                                onChange={this.handleChange}
                                value={this.state.videoWithoutMusic_Duration}
                              />
                              <br /> */}
                             
                              <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                <span className="required_input">*</span>
                                Select payment availability
                              </label>
                              <br />
                              <Dropdown
                                fluid
                                selection
                                placeholder="Select Payment Availability"
                                id="episode_series_id"
                                onChange={this.handlePaymentAva}
                                options={is_free_type}
                                value={this.state.isFree}
                              />
                              <br />
                              <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                Sub Value
                              </label>
                              <br />
                              <Dropdown
                                fluid
                                search
                                selection
                                placeholder="Select Sub Value"
                                id="episode_series_id"
                                onChange={this.handleSubValueChange}
                                options={subValuesList}
                                value={this.state.subValueId}
                              />
                              <br />
                              <div className="row mt-4">
                                <div className="col-md-10 col-sm-12">
                                  <div className="Puplish-sec mt-2">
                                    {/* DateComponent */}
                                    {this.state.Publish_Date !== " " &&
                                    this.state.Publish_Date !== "" &&
                                    this.state.Publish_Date !== null &&
                                    this.state.Publish_Date !== undefined ? (
                                      <PublishDate
                                        getPublishDateCallBack={this.getPublishDate.bind(
                                          this
                                        )}
                                        date={this.state.Publish_Date}
                                        showNow={!isInvalid}
                                      />
                                    ) : (
                                      <PublishDate
                                        getPublishDateCallBack={this.getPublishDate.bind(
                                          this
                                        )}
                                        value={this.state.Publish_Date}
                                        showNow={!isInvalid}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="groups-btn mt-5">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="mb-0">
                                      <Button
                                        id="episode_cancel"
                                        onClick={this.handleCancel}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="float-right rest-floating">
                                      {/*  <button className="btn btn-lg btn-primary btn-bck btn-block-sm" ><i className="pr-2 fas fa-long-arrow-alt-left"></i>Back</button> */}

                                      {/* <Link to="/addActivityStory"> */}
                                      <Button
                                        id="nextButton"
                                        icon
                                        labelPosition="right"
                                        disabled={this.state.error}
                                      >
                                        Next
                                        <Icon name="right arrow" />
                                      </Button>
                                      {/* </Link> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  story: state.stories.item,
  storyVideos: state.storyVideos.items,
  allSubValues: state.ContentSubValue.items,
  storyParent: state.storyParent.items,
  storyParentSelected : state.storyParent.item,
  redirectionPage: state.redirectionPage.item,
  // vdocipherUploadInfo: state.vdoCipher.entity,
  bookParentId:state.bookParentId,
  ContentVendorsList : state.contentVendor.State_items,
});

export default connect(mapStateToProps, {
  addStory,
  addVideos,
  fetchAllSubValues,
  fetchAllStoryParent,
  getStoryParentData,
  emptyStoryParentWithStories,
  clearPreSignData,
  clearAll,
  fetchAllContentVendors,
})(AddStory);
