import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import propTypes from 'prop-types';
import placeHolder from '../../images/placeholder.png';
import { DateInput } from 'semantic-ui-calendar-react';
import { Dropdown, Accordion, Icon } from 'semantic-ui-react';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux*/
import { withFirebase } from '../../config';
/*Const */
import NAME_CONDITIONS from '../ConstData/NameFilterConditions';
import DESCRIPTION_CONDITIONS from '../ConstData/NameFilterConditions';
import DATE_CONDITIONS from '../ConstData/DateFilterConditions'
import STATUS from '../ConstData/Status'
import SetBookStatus from '../ReadWithJeel/SetBookState';
import DeleteBookParent from '../BookParent/DeleteBookParent'
import DeleteBook from '../ReadWithJeel/DeleteBook';
import { SIGN_IN,EDIT_STORY,EDIT_BOOK_PARENT, BOOK_TO_GET, ADD_BOOK,ADD_STORY,ADD_PDF_BOOK, VIEW_BOOK_PARENT, VIEW_BOOK, BOOK_TO_EDIT,EDIT_PDF_BOOK ,ALL_PDF_BOOK_PAGE_NAME,PDF_BOOK_TO_EDIT,STORY_TO_EDIT,STORY_TO_GET} from '../../routes';

import { connect } from 'react-redux';

import {
    getBookParentData,
    emptyBookParentWithBooks,
    emptyError,
    addBookParentItemWithSentFrom
} from '../../Actions/BookParentAction';

import { emptyAllBooks, addBookItemWithSentFrom } from '../../Actions/ReadWithJeelAction'
import {fetchPdfBooks,addPdfBookItemWithSentFrom } from '../../Actions/PdfBookAction';
import {addStoryItemWithSentFrom} from '../../Actions/StoryActions';

import SetStoryStatus from '../Story/SetStoryStatus';
import SetPdfBookState from '../PdfBook/SetPdfBookState'
import DeleteStory from '../Story/DeleteStory'
import DeletePdfBook from '../PdfBook/DeletePdfBook'

/*Other Compnents */

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}
function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

class ShowBooksListComponent extends Component {
    state = {

        activeIndex: 0,

        filterName: '',
        nameFilterCondition: 'contains',

        dateFilterCondition: 'on',
        publishDate: '',
        secondPublishDate: '',

        isFree: 'none',
        status: 'none',

        descriptionFilterCondition: 'contains',
        description: '',
        featured:false,
        addingDone:false,
    }



addingBookToRedux = (item, parentID, parentName, e) => {
	
	const { type } = this.props;
	
	console.log('Itemmmmmm :'+item.id +' , type: '+type);
	
        let itemContent = item
        item.parentID = parentID
        item.parentName = parentName
        
        if(type=='pdf')
        	this.props.addPdfBookItemWithSentFrom(item, ALL_PDF_BOOK_PAGE_NAME);
        else if(type=='story')
        	this.props.addStoryItemWithSentFrom(item, "ViewBook");
        else if(type=='book')
        	this.props.addBookItemWithSentFrom(itemContent, "ViewBook");
        	
        //this.setState({ addingDone: true, pushRoute: "Book", bookId: itemContent.id })
        //--------
        
        this.setState({ addingDone: true, itemId: item.id})
    }



    render() {
        
        const { bookItems,bookParent,detailsPage,type } = this.props;
        const { redirection, addingDone } = this.state;
        
        //console.log('detailsPage: '+detailsPage);
        //console.log('bookParent'+bookParent);
        //if(type=='pdf')console.log('bookItems : '+JSON.stringify(bookItems,null,2));
        
        //let arr = bookItems
        //if(!arr) arr=[] 

		if (addingDone == true) {
			console.log('addingDone true and type: '+type);
			if(type=="pdf")
            	return (<Redirect to={{pathname:PDF_BOOK_TO_EDIT,state: {bookParentId:bookParent.id}}}></Redirect>) 
            else if(type=='story')
            	return (<Redirect to={{pathname:STORY_TO_EDIT,state: {bookParentId:bookParent.id}}}></Redirect>)
            else if(type=='book')
            	return (<Redirect to={{pathname:BOOK_TO_EDIT,state: {bookParentId:bookParent.id}}}></Redirect>)	           	
        }
        

  return (
    <>
      {bookItems?
      bookItems.map((item, index) => (
        


		<div className="card" id="editEpisodeCard" key={`${index}_${type}`}>
                            <div className="row" id="episode-header">
                                <div className="col">
                                    <img className="img-thumbnail" src={type=='book'?item.image:type=='story'?item.iconImage:type=='pdf'?item.pdfImage:''} alt="Series " />
                                </div>
                                <div className="col-10" >
                                    <div className="title_buttons_container">
                                        <span className="card-title" id="editSeriesCard-title">
                                        	<Link className="link" to={detailsPage + "/" + bookParent.name + "/" + bookParent.id + "/" + item.id}> {type=='pdf'?item.pdfName:item.name}</Link>
                                        </span>

                                        
                                        {type=='pdf'?
                                        	<>
                                        	    <DeletePdfBook id={item.id} BookId={item.id} />
	                                        	<Link className="link" onClick={this.addingBookToRedux.bind(this, item, bookParent.id, bookParent.name)} to={{}}>
	                                            	<button className="seriesCardButtons"  >Edit </button>
	                                        	</Link>
	                                        	<SetPdfBookState id={item.id} status={item.status} bookId={item.id} />
                                        	</>
                                        :type=='story'?
                                            <>
	                                            <DeleteStory id={item.id} />
	                                        	<Link className="link" onClick={this.addingBookToRedux.bind(this, item, bookParent.id, bookParent.name)} to={{}}>
		                                            <button className="seriesCardButtons"  >Edit </button>
		                                         </Link>
	                                        	<SetStoryStatus id={item.id} status={item.status} StoryParentId={bookParent.id} />
                                        	</>
                                        :type=='book'?
                                        	 <>
		                                         <DeleteBook id={item.id} BookParentId={bookParent.id} getBookParentData={this.getBookParentData}/>	
		                                         <Link className="link" onClick={this.addingBookToRedux.bind(this, item, bookParent.id, bookParent.name)} to={{}}>
		                                            <button className="seriesCardButtons"  >Edit </button>
		                                         </Link>                                                                                    
	                                        	 <SetBookStatus id={item.id} status={item.status} BookParentId={bookParent.id} getParentData={this.props.getBookParentData} />
                                        	 </>
                                        :''}

                                    </div>
                                    <span className="font-italic small-font ">
                                        <span className="date">{GMTtolocalTime(item.createdDate)}</span>
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <span><p>{item.description?item.description:'No Desc'}</p></span>
                        </div>


      )):''}
    </>
  );
// ====================================================================================================================        			
        }
    }

ShowBooksListComponent.propTypes = {
    //getBookParentData: propTypes.func.isRequired,
    //addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    addBookItemWithSentFrom: propTypes.func.isRequired,
    addPdfBookItemWithSentFrom: propTypes.func.isRequired,
    addStoryItemWithSentFrom: propTypes.func.isRequired,

}

//export default ShowBooksListComponent
const mapStateToProps = state => ({
    //bookParent: state.bookParent.item,
    //auth: state.firebase.auth,
    //redirectionPage: state.redirectionPage.item,
    //loading: state.bookParent.loading,
    //error: state.bookParent.error_loading_book_parent
});
export default withFirebase(
    connect
        (mapStateToProps,
            {
                //getBookParentData,
                //emptyBookParentWithBooks,
                //emptyError,
                //emptyEvaluationCategory,
                //clearQuestions,
                //emptyEvaluationAll,
                addBookParentItemWithSentFrom,
                addBookItemWithSentFrom,
                addPdfBookItemWithSentFrom,
                addStoryItemWithSentFrom,
                //addSeriesIDtoRedirectionPage,
                //emptyAllBooks

            })
        (ShowBooksListComponent));
        
