import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import { withFirebase } from '../../config'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import {
  SIGN_IN, ALL_SERIES, ALL_SONG_PARENTS, ALL_STORIES_PARENTS, ALL_DISCOVER_SERIES, ALL_GAMES,
  ADMIN_LAND_PAGE, CUSTOMERS, ALL_ISSUES, REPORT, ALL_RADIO, ALL_BOOK, ALL_PDF_BOOK, ALL_BOOK_PARENTS, ALL_GAME_PARENTS
} from '../../routes';

class NavBar extends Component {

  constructor(props) {
    super(props);
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.getIdToken().then((token) => {
        this.props.getUserData(token);
      })
    }
  }

  componentDidMount() {
    const { userPaymentProfile } = this.props;
    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to={SIGN_IN}></Redirect>
    }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if (userPaymentProfile.user_role !== undefined) {
      if (userPaymentProfile.user_role.role !== "Analyst") {
        return (
          <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <ul className="navbar-nav mr-auto">
                <li><NavLink to={ALL_SERIES} className="nav-link"> Series</NavLink></li>
                <li><NavLink to={ALL_SONG_PARENTS} className="nav-link">Songs</NavLink></li>
                {/* <li><NavLink to={ALL_STORIES_PARENTS} className="nav-link">Stories</NavLink></li> */}
                <li><NavLink to={ALL_DISCOVER_SERIES} className="nav-link">Learning & Skills</NavLink></li>
                <li><NavLink to={ALL_GAMES} className="nav-link">Game Data</NavLink></li>
                <li><NavLink to={ALL_GAME_PARENTS} className="nav-link">Games</NavLink></li>
                <li><NavLink to={ALL_RADIO} className="nav-link">Radio</NavLink></li>
                <li><NavLink to={ALL_BOOK_PARENTS} className="nav-link">Read With Jeel</NavLink></li>
                {/*<li><NavLink to={ALL_PDF_BOOK} className="nav-link">PDF Book</NavLink></li>*/}
                {/*<li><NavLink to={ALL_STORIES_PARENTS} className="nav-link">Stories</NavLink></li>*/}

              </ul>

            </nav>
            <br />
          </div>
        )
      }
      else if (userPaymentProfile.user_role.role === "Analyst") {
        return (
          <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <ul className="navbar-nav mr-auto">
                {/* Dashboard */}
                <li><NavLink to={ADMIN_LAND_PAGE} className="nav-link"> Dashboard </NavLink></li>
                {/* Customers */}
                <li><NavLink to={CUSTOMERS} className="nav-link"> Customers </NavLink></li>
                {/* Issues amnd Complaints */}
                <li><NavLink to={ALL_ISSUES} className="nav-link">Support</NavLink></li>
                {/* Reporting */}
                <li><NavLink to={REPORT} className="nav-link">Reports</NavLink></li>
              </ul>
            </nav>
            <br />
          </div>
        )
      }
    } else {
      return (<div></div>);
    }

  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
  (connect
    (mapStateToProps,
      { getUserData }
    )(NavBar)
  );
