// save series, save episode
import React, { Component } from "react";    
import "./css/main-style.css";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { withFirebase } from "./config";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faKey,
  faUserLock,
  faBan,
  faTrash,
  faPlus,
  faInfoCircle,
  faSearch,
  faBell,
  faCog,
  faEye,
  faTrashAlt,
  faCloudUploadAlt,
  faCheckCircle,
  faEdit,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faComments,
  faPaperPlane,
  faChevronLeft,
  faDownload,
  faPlay,
  faLockOpen,
  faLock,
  faMicrophone,
  faPhone,
  faMoneyCheck,
  faTimes,
  faIdCard,
  faUserTag,
  faEyeSlash,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookF,
  faTwitter,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

import {} from "@fortawesome/free-regular-svg-icons";
import {} from "@fortawesome/free-brands-svg-icons";

import * as ROUTES from "./routes";

/*Home*/
import Home from "./components/Home";
import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";
/* UserManagement */
import UserManagementAuth from "./components/UserManagement/UserManagementAuth";
/*Customer*/
import CustomerAuth from "./components/Customer/CustomerAuth";
/*Song && Activity Song*/
import SongAuth from "./components/Song/SongAuth";
/*Series*/
import SeriesAuth from "./components/Series/SeriesAuth";
/*Episode*/
import EpisodeAuth from "./components/Episode/EpisodeAuth";
/*Stories */
import StoryAuth from "./components/Story/StoryAuth";
/*Game*/
import GameAuth from "./components/Game/GameAuth";
/* blogs*/
import BlogAuth from "./components/Blog/BlogAuth";
/* Issues */
import IssueAuth from "./components/Issue/IssueAuth";
/*TermsAndConditions*/
import TermsAndConditionsAuth from "./components/TermsAndConditions/TermsAndConditionsAuth";
/*FAQ */
import FAQAuth from "./components/FAQ/FAQAuth";
/*Evaluation */
import EvaluationAuth from "./components/Evaluation/EvaluationAuth";
/*AddDiscoverUsSeries*/
import DiscoverUsSeriesAuth from "./components/DiscoverUsSeries/DiscoverUsSeriesAuth";
/* general setting */
import GeneralSettingAuth from "./components/GeneralSetting/GeneralSettingAuth";
/* Report */
import ReportAuth from "./components/Reports/ReportAuth";
/* Content Setting */
import ContentSettingAuth from "./components/ContentSetting/ContentSettingAuth";
/*Radio*/
import RadioAuth from "./components/Radio/RadioAuth";
/*Books*/
import BookAuth from "./components/ReadWithJeelView/BookAuth";
/* PDF Book */
import PdfBookAuth from "./components/PdfBookView/PdfBookAuth";
/*Stripe Payments*/
import MyStoreCheckout from "./components/StripePayment/MyStoreCheckout";
import { Elements, StripeProvider } from "react-stripe-elements";

import MobilePaymentLogin from "./components/StripePayment/MobilePaymentLogin";
import PaymentAuth from "./components/StripePayment/PaymentAuth";

import RecommendationAuth from "./components/Recommendation/RecommendationAuth";

import FawryFailure from "./components/FawryPayment/FawryFailure";
import FawrySuccess from "./components/FawryPayment/FawrySuccess";
import FawryResult from "./components/FawryPayment/FawryResult";
import MobilePaymentFeedback from "./components/StripePayment/MobilePaymentFeedback";
import MobilePaymentError from "./components/StripePayment/MobilePaymentError";
import MobilePaymentSwitch from "./components/StripePayment/MobilePaymentSwitch";
/*PaymentPackages */
import PaymentPackages from "./components/PaymentPackages/PaymentPackages";
/*PromoCodes */
import PromoCodeAuth from "./components/PromoCodes/PromoCodeAuth";
import PublicPromoCodeDetails from "./components/PromoCodes/PublicPromoCodeDetails";
/*QRcodes */
import QRcodesGenerator from "./components/QRcodesGenerator/QRcodesGenerator";
import QRCardUpload from "./components/QRcodesGenerator/QRCardUpload";

/*Vendors */
import Vendor from "./components/Vendor/Vendor";
import AddVendor from "./components/Vendor/AddVendor";
import EditVendor from "./components/Vendor/EditVendor";
/* Groups */
import Group from "./components/VendorPanel/Group/Group";
import GroupMembers from "./components/VendorPanel/Group/GroupMembers";
// import AddGroup from "./components/Group/AddGroup";
// import EditGroup from "./components/Group/EditGroup";

/*vendorReport*/
import VendorReport from "./components/VendorReport/VendorReport";

/*Episode*/
import InAppNotificationAuth from "./components/InAppNotification/InAppNotificationAuth";

/* Portal Components */
import Auth from "./portal/components/Authentication/Auth";
import OtherLoginMethods from "./portal/components/Authentication/OtherLoginMethods";
import PhoneCode from "./portal/components/Authentication/PhoneCode";
import LoggedInByPhone from "./portal/components/Authentication/LoggedInByPhone";
import ForgetPassword from "./portal/components/Authentication/ForgetPassword";
import PortalAuth from "./portal/components/PortalAuth";
import FitLogin from "./portal/components/FIt/FitLogin";
import TelecomLogin from "./portal/components/telecommunication/TelecomLogin";
import HyperPay from "./components/HyperPay/HyperPay";
import ResultPage from "./components/HyperPay/ResultPage";
import Result from "./components/HyperPay/Result";

import TpayLogin from "./portal/components/Tpay/TpayLogin";
import PaymentCountries from "./components/PaymentCountries/PaymentCountries";

// Start New Portal
import PortalHome from "./portal/src/home";
import JeelSubscription from "./portal/src/subscribe";
import PaymentResult from "./portal/src/PaymentResult";
import Successful from "./portal/src/Successful";
import CompleteProcess from "./portal/src/subscribe/CompleteProcess";
import UpgradeSubscription from "./portal/src/subscribe/UpgradeSubscription";

import MwBahrainVCode from "./portal/mwBahrain/MwBahrainVCode";
import MwBahrainSuccess from "./portal/mwBahrain/MwBahrainSuccess";

import MwQatarVCode from "./portal/mwQatar/MwQatarVCode";
import MwQatarSuccess from "./portal/mwQatar/MwQatarSuccess";

import MwTestComponent from "./portal/test/MwTestComponent";
import PrivacyComponent from "./portal/components/privacy/Privacy";

import MondiaLoginComponent from "./portal/components/mondia/MondiaLogin";
import MondiaSuccessComponent from "./portal/components/mondia/MondiaSuccess";

/* banners*/
import BannerAuth from "./components/Banner/BannerAuth";
import DynamicSection from "./components/DynamicSection/DynamicSection";
import AddDynamicSection from "./components/DynamicSection/AddDynamicSection";
import EditDynamicSection from "./components/DynamicSection/EditDynamicSection";

import SettingsAuth from "./components/Settings/SettingsAuth";

import ParentLibraryAuth from "./components/ParentLibrary/ParentLibraryAuth";


/*Content Vendors */
import ContentVendor from "./components/ContentVendor/ContentVendor";
import AddContentVendor from "./components/ContentVendor/AddContentVendor";
import EditContentVendor from "./components/ContentVendor/EditContentVendor";

/*Content Vendors */
import ProfitShareNew from "./components/ProfitShare/ProfitShareNew";

/*Partener */
import PartnerHome from "./components/PartnerHome";

import PartenerCards from "./components/partener/cards/cards";
import PartenerVouchers from "./components/partener/cards/vouchers";
import PartenerAssignation from "./components/partener/cards/PartenerAssignation";

import Parents from "./components/VendorPanel/Parents/Parents";
import StudentActivity from "./components/VendorPanel/Kids/StudentActivity";
import VendorDashboard from "./components/VendorPanel/Dashboard/VendorDashboard";
import Kids from "./components/VendorPanel/Kids/Kids";
import DetailedViewsReport from "./components/VendorPanel/Kids/DetailedViewsReport"
import DetailedActivitiesReport from "./components/VendorPanel/Kids/DetailedActivitiesReport"
import ActivityDetailView from "./components/VendorPanel/Kids/ActivityDetailView";
import ViewsDetailView from "./components/VendorPanel/Kids/ViewsDetailView";

import contentVendorViewList from "./components/ContentVendor/contentVendorViewList";


// End New Portal


library.add(
  faKey,
  faUserLock,
  faBan,
  faTrash,
  faPlus,
  faInfoCircle,
  faSearch,
  faBell,
  faCog,
  faEye,
  faTrashAlt,
  faCloudUploadAlt,
  faCheckCircle,
  faEdit,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faComments,
  faPaperPlane,
  faChevronLeft,
  faDownload,
  faPlay,
  faLockOpen,
  faLock,
  faMicrophone,
  faFacebookF,
  faTwitter,
  faGoogle,
  faPhone,
  faMoneyCheck,
  faTimes,
  faIdCard,
  faUserTag,
  faEyeSlash,
  faChevronRight
);

class App extends Component {
  render() {
    return (
      <span>
        {/* Old Stripe account */}
        {/* <StripeProvider apiKey="pk_test_FB9vWunYEUmHaHBjaw04Fm9P00KFqGYEMV" > */}

        {/*<StripeProvider apiKey="pk_live_HQ94p4ndLTGtaJJf5rOaNxXy005Ai1LEcW" >*/}
        <StripeProvider apiKey="pk_test_51FZvbVKwQ0AlKDaJ3kV7FPz5LrKKB5ZZKb36W4zQzRw4QWpU9bWSeRiDLBiEKuBIdJouHQYAxsE0esONuTMKAAQr00L5fDdozZ" >

          <BrowserRouter>
            <div className="App">
              <Route exact path={ROUTES.SIGN_IN} component={Login} />
              <Route exact path={ROUTES.ADMIN_LAND_PAGE} component={Home} />
              <Route exact path={ROUTES.PARTNERHOME} component={PartnerHome} />
              <Route exact path={ROUTES.PARTNERPARENTS} component={Parents} />
              <Route path={ROUTES.STUDENTACTIVITY} component={StudentActivity} />
              <Route exact path={ROUTES.VENDORDASHBOARD} component={VendorDashboard} />
              <Route exact path={ROUTES.PARTNERKIDS} component={Kids} />
              <Route path={ROUTES.DETAILED_VIEWS_REPORT} component={DetailedViewsReport} />
              <Route path={ROUTES.DETAILED_ACTIVITIES_REPORT} component={DetailedActivitiesReport} />
              <Route exact path={ROUTES.ACTIVITY_DETAIL_VIEW} component={ActivityDetailView} />
              <Route exact path={ROUTES.VIEWS_DETAIL_VIEW} component={ViewsDetailView} />


              <Route exact path={ROUTES.HOME} component={Home} />
              <Route path={ROUTES.REGISTER} component={Register} />
              {/* UserManagement */}
              <Route
                path={ROUTES.USER_MANAGEMENT}
                component={UserManagementAuth}
              />
              {/* Customer */}
              <Route path={ROUTES.CUSTOMERS} component={CustomerAuth} />
              <Route path={ROUTES.CUSTOMER} component={CustomerAuth} />
              {/* adding Evaluation Routers */}
              <Route path={ROUTES.EVALUATION} component={EvaluationAuth} />
              {/* adding Song Parent */}
              <Route path={ROUTES.ALL_SONG_PARENTS} component={SongAuth} />
              <Route path={ROUTES.ADD_SONG_PARENT} component={SongAuth} />
              <Route path={ROUTES.VIEW_SONG_PARENT} component={SongAuth} />
              <Route path={ROUTES.EDIT_SONG_PARENT} component={SongAuth} />
              {/* adding Book Parent */}
              <Route path={ROUTES.ALL_BOOK_PARENTS} component={BookAuth} />
              <Route path={ROUTES.ADD_BOOK_PARENT} component={BookAuth} />
              <Route path={ROUTES.VIEW_BOOK_PARENT} component={BookAuth} />
              <Route path={ROUTES.EDIT_BOOK_PARENT} component={BookAuth} />
              {/* adding Game Parent */}
              <Route path={ROUTES.ALL_GAME_PARENTS} component={GameAuth} />
              <Route path={ROUTES.ADD_GAME_PARENT} component={GameAuth} />
              <Route path={ROUTES.VIEW_GAME_PARENT} component={GameAuth} />
              <Route path={ROUTES.EDIT_GAME_PARENT} component={GameAuth} />
              {/* adding Song Routers */}
              <Route path={ROUTES.ALL_SONGS} component={SongAuth} />
              <Route path={ROUTES.ADD_SONG} component={SongAuth} />
              <Route path={ROUTES.EDIT_SONG} component={SongAuth} />
              <Route path={ROUTES.SONG_DETAIL} component={SongAuth} />
              <Route path={ROUTES.PUBLISH_SONG} component={SongAuth} />
              <Route path={ROUTES.SONG_EVALUATION} component={SongAuth} />
              <Route path={ROUTES.EDIT_SONG_ACTIVITY} component={SongAuth} />
              <Route path={ROUTES.EDIT_SONG_EVALUATION} component={SongAuth} />
              <Route path={ROUTES.ADD_SONG_ACTIVITY} component={SongAuth} />
              {/* adding Game Routers */}
              <Route path={ROUTES.ALL_GAMES} component={GameAuth} />
              <Route path={ROUTES.PUZZLE_DETAIL} component={GameAuth} />
              <Route path={ROUTES.DRAG_DROP_DETAIL} component={GameAuth} />
              <Route path={ROUTES.PAINTING_DETAIL} component={GameAuth} />
              <Route path={ROUTES.ADD_DRAG_DROP} component={GameAuth} />
              <Route path={ROUTES.EDIT_DRAG_DROP} component={GameAuth} />
              <Route path={ROUTES.JUMPING_DETAIL} component={GameAuth} />
              <Route path={ROUTES.EDIT_JUMPING} component={GameAuth} />
              {/* adding Game Data Routers */}
              <Route path={ROUTES.ADD_GAME_DATA} component={GameAuth} />
              <Route path={ROUTES.EDIT_GAME_DATA} component={GameAuth} />
              <Route path={ROUTES.GAME_DATA_DETAIL} component={GameAuth} />
              {/* Discover us series */}
              <Route
                path={ROUTES.ALL_DISCOVER_SERIES}
                component={DiscoverUsSeriesAuth}
              />
              <Route
                path={ROUTES.VIEW_DISCOVER_SERIES}
                component={DiscoverUsSeriesAuth}
              />
              <Route
                path={ROUTES.ADD_DISCOVER_SERIES}
                component={DiscoverUsSeriesAuth}
              />
              <Route
                path={ROUTES.UPDATE_DISCOVER_SERIES}
                component={DiscoverUsSeriesAuth}
              />
              {/* adding Series Routers */}
              <Route path={ROUTES.ALL_SERIES} component={SeriesAuth} />
              <Route path={ROUTES.ADD_SERIES} component={SeriesAuth} />
              <Route path={ROUTES.UPDATE_SERIES} component={SeriesAuth} />
              <Route path={ROUTES.VIEW_SERIES} component={SeriesAuth} />
              <Route path={ROUTES.GET_SERIES_LIST} component={SeriesAuth} />
              {/* adding Episode Routers */}
              <Route path={ROUTES.ADD_EPISODE} component={EpisodeAuth} />
              <Route path={ROUTES.PUBLISH_EPISODE} component={EpisodeAuth} />
              <Route path={ROUTES.VIEW_EPISODE} component={EpisodeAuth} />
              <Route
                path={ROUTES.ADD_EPISODE_ACTIVITY}
                component={EpisodeAuth}
              />
              <Route path={ROUTES.EDIT_EPISODE} component={EpisodeAuth} />
              <Route path={ROUTES.EPISODE_EVALUATION} component={EpisodeAuth} />
              <Route
                path={ROUTES.EDIT_EPISODE_ACTIVITY}
                component={EpisodeAuth}
              />
              <Route
                path={ROUTES.EDIT_EPISODE_EVALUATION}
                component={EpisodeAuth}
              />
              {/* adding Story Parent */}
              <Route path={ROUTES.ALL_STORIES_PARENTS} component={StoryAuth} />
              <Route path={ROUTES.ADD_STORY_PARENT} component={StoryAuth} />
              <Route path={ROUTES.VIEW_STORY_PARENT} component={StoryAuth} />
              <Route path={ROUTES.EDIT_STORY_PARENT} component={StoryAuth} />
              {/* adding story Routers */}
              <Route path={ROUTES.ADD_STORY} component={StoryAuth} />
              <Route path={ROUTES.ALL_STORIES} component={StoryAuth} />
              <Route path={ROUTES.ADD_STORY_ACTIVITY} component={StoryAuth} />
              <Route path={ROUTES.PUBLISH_STORY} component={StoryAuth} />
              <Route path={ROUTES.STORY_DETAIL} component={StoryAuth} />
              <Route path={ROUTES.STORY_EVALUATION} component={StoryAuth} />
              <Route path={ROUTES.EDIT_STORY} component={StoryAuth} />
              <Route path={ROUTES.EDIT_STORY_ACTIVITY} component={StoryAuth} />
              <Route path={ROUTES.STORY_TO_EDIT} component={StoryAuth} />
              <Route
                path={ROUTES.EDIT_STORY_EVALUATION}
                component={StoryAuth}
              />
              {/*adding blogs Routers*/}
              <Route path={ROUTES.ALL_BLOGS} component={BlogAuth} />
              <Route path={ROUTES.ADD_BLOG} component={BlogAuth} />
              <Route path={ROUTES.EDIT_BLOG} component={BlogAuth} />
              {/* adding issues Routers */}
              <Route path={ROUTES.ALL_ISSUES} component={IssueAuth} />
              <Route
                path={ROUTES.GET_COMPLAINTS_ISSUES}
                component={IssueAuth}
              />
              <Route
                path={ROUTES.GET_SUGGESTIONS_ISSUES}
                component={IssueAuth}
              />
              <Route path={ROUTES.ISSUE_DETAIL} component={IssueAuth} />
              {/* TermsAndConditions */}
              <Route
                path={ROUTES.TERMS_AND_CONDITIONS}
                component={TermsAndConditionsAuth}
              />
              {/* FAQ */}
              <Route path={ROUTES.ALL_FAQ} component={FAQAuth} />
              <Route path={ROUTES.ADD_FAQ} component={FAQAuth} />
              <Route path={ROUTES.EDIT_FAQ} component={FAQAuth} />
              {/* General Setting */}
              <Route
                path={ROUTES.GENERAL_SETTING}
                component={GeneralSettingAuth}
              />
              {/* Reporting */}
              <Route path={ROUTES.REPORT} component={ReportAuth} />
              {/* content Setting */}
              <Route
                path={ROUTES.CONTENT_SETTING}
                component={ContentSettingAuth}
              />
              <Route
                path={ROUTES.CONTENT_VALUES}
                component={ContentSettingAuth}
              />
              <Route
                path={ROUTES.RECOMMENDATION}
                component={RecommendationAuth}
              />
              {/* stripe Payment  */}
              <Route
                path={ROUTES.MY_STORE_CHECKOUT}
                component={MyStoreCheckout}
              />
              {/* stripe MobilePayment   */}
              <Route
                path={ROUTES.MOBILE_PAYMENT_ERROR}
                component={MobilePaymentError}
              />
              <Route
                path={ROUTES.MOBILE_PAYMENT_FEEDBACK}
                component={MobilePaymentFeedback}
              />
              <Route path={ROUTES.MOBILE_PAYMENT} component={PaymentAuth} />
              <Route
                path={ROUTES.MOBILE_PAYMENT_OFFER}
                component={PaymentAuth}
              />
              <Route
                path={ROUTES.MOBILE_PAYMENT_PROFILE}
                component={PaymentAuth}
              />
              <Route
                path={ROUTES.MOBILE_PAYMENT_SUPPORT}
                component={PaymentAuth}
              />
              <Route
                path={ROUTES.MMOBILE_PAYMENT_HISTORY}
                component={PaymentAuth}
              />
              <Route
                path={ROUTES.MOBILE_PAYMENT_STRIPE_CHECKOUT}
                component={PaymentAuth}
              />
              <Route
                path={ROUTES.MOBILE_PAYMENT_LOGIN}
                component={MobilePaymentLogin}
              />
              <Route
                path={ROUTES.MOBILE_PAYMENT_SWITCH}
                component={MobilePaymentSwitch}
              />
              {/* fawry MobilePayment */}
              <Route
                path={ROUTES.FAWRY_MOBILE_PAYMENT}
                component={PaymentAuth}
              />
              <Route path={ROUTES.FAWRY_FAILURE} component={FawryFailure} />
              <Route path={ROUTES.FAWRY_SUCCESS} component={FawrySuccess} />
              <Route path={ROUTES.FAWRY_RESULT} component={FawryResult} />
              {/* PaymentPackages */}
              <Route
                path={ROUTES.PAYMENT_PACKAGES}
                component={PaymentPackages}
              />
              {/* PaymentPCountries */}
              <Route
                path={ROUTES.PAYMENT_COUNTRIES}
                component={PaymentCountries}
              />
              {/* PromoCodes*/}
              <Route path={ROUTES.PROMO_CODES} component={PromoCodeAuth} />
              <Route path={ROUTES.VIEW_PROMO_CODE} component={PromoCodeAuth} />
              <Route path={ROUTES.ADD_PROMO_CODE} component={PromoCodeAuth} />
              <Route path={ROUTES.EDIT_PROMO_CODE} component={PromoCodeAuth} />
              <Route path={ROUTES.GET_CODE_TO_VIEW_PUBLIC} component={PublicPromoCodeDetails}/>
              {/*QRcodesGenerator */}
              <Route
                path={ROUTES.QR_CODE_GENERATOR}
                component={QRcodesGenerator}
              />
              <Route path={ROUTES.QR_CODE_CARD_UPLOAD} component={QRCardUpload} />
              {/*Vendor */}
              <Route path={ROUTES.VENDORS} component={Vendor} />
              <Route path={ROUTES.ADD_VENDOR} component={AddVendor} />
              <Route path={ROUTES.EDIT_VENDOR} component={EditVendor} />
              {/* Group */}
              <Route path={ROUTES.GROUPS} component={Group} />
              <Route path={ROUTES.VIEW_GROUP_MEMBERS} component={GroupMembers} />
              
              {/* <Route path={ROUTES.ADD_GROUP} component={AddGroup} />
              <Route path={ROUTES.EDIT_GROUP} component={EditGroup} /> */}
              {/*VendorReport */}
              <Route path={ROUTES.VENDOR_REPORT} component={VendorReport} />
              {/* Portal Paths */}
              {/**************** START NEW PORTAL PAGES ****************/}
              <Route
                exact
                path={ROUTES.PORTAL_LAND_PAGE}
                component={PortalHome}
              />
              <Route
                exact
                path={ROUTES.PORTAL_SUBSCRIPTION}
                component={JeelSubscription}
              />
              <Route
                exact
                path={ROUTES.PORTAL_UPGRADE_SUBSCRIPTION}
                component={UpgradeSubscription}
              />
              {/* <Route
                exact
                path={ROUTES.PORTAL_COMPLETE_PROCESS}
                component={CompleteProcess}
              /> */}
              <Route
                exact
                path={ROUTES.PORTAL_PAYMENT_RESULT}
                component={PaymentResult}
              />
              <Route
                exact
                path={ROUTES.PORTAL_SUCCESSFUL}
                component={Successful}
              />
                            {/*adding banners Routers*/}
              <Route path={ROUTES.ALL_BANNERS} component={BannerAuth} />
              <Route path={ROUTES.ADD_BANNER} component={BannerAuth} />
              <Route path={ROUTES.EDIT_BANNER} component={BannerAuth} />


                             {/*adding settings Routers*/}
              <Route path={ROUTES.SETTINGS} component={SettingsAuth} />
              <Route path={ROUTES.ADD_SETTING} component={SettingsAuth} />
              <Route path={ROUTES.GET_SETTING_TO_EDIT} component={SettingsAuth} />

                            {/*adding parent library Routers*/}
              <Route path={ROUTES.ALL_EDUCATIONAL_VIDEOS} component={ParentLibraryAuth} />
              <Route path={ROUTES.ADD_EDUCATIONAL_VIDEO} component={ParentLibraryAuth} />
              <Route path={ROUTES.EDIT_EDUCATIONAL_VIDEO} component={ParentLibraryAuth} />
              <Route path={ROUTES.VIEW_EDUCATIONAL_VIDEO} component={ParentLibraryAuth} />

              {/**************** END NEW PORTAL PAGES ****************/}
              {/* <Route exact path={ROUTES.PORTAL_LAND_PAGE} component={Auth} /> */}
              <Route
                exact
                path={ROUTES.PORTAL_LOGIN_METHODS}
                component={OtherLoginMethods}
              />
              <Route
                exact
                path={ROUTES.PORTAL_LOGGED_BY_PHONE}
                component={LoggedInByPhone}
              />
              <Route
                exact
                path={ROUTES.PORTAL_PHONE_CODE}
                component={PhoneCode}
              />
              <Route
                exact
                path={ROUTES.PORTAL_FORGET_PASSWORD}
                component={ForgetPassword}
              />
              <Route
                exact
                path={ROUTES.PORTAL_ACCOUNT}
                component={PortalAuth}
              />
              <Route
                exact
                path={ROUTES.PORTAL_ACCOUNT_PROFILE}
                component={PortalAuth}
              />
              <Route
                exact
                path={ROUTES.PORTAL_SUBSCRIPTION_HISTORY}
                component={PortalAuth}
              />
              {/* <Route
                exact
                path={ROUTES.PORTAL_SUBSCRIPTION}
                component={PortalAuth}
              /> */}
              <Route
                exact
                path={ROUTES.PORTAL_PAYMENT_METHODS}
                component={PortalAuth}
              />
              <Route
                exact
                path={ROUTES.PORTAL_CREDIT_CARD}
                component={PortalAuth}
              />
              <Route exact path={ROUTES.PORTAL_FAWRY} component={PortalAuth} />
              <Route
                exact
                path={ROUTES.PORTAL_PRE_PAID}
                component={PortalAuth}
              />
               <Route exact path={ROUTES.PORTAL_MADA} component={PortalAuth} />
              <Route
                exact
                path={ROUTES.PORTAL_CHANGE_LOGIN_METHODS}
                component={PortalAuth}
              />
              <Route path={ROUTES.FIT_LOGIN} component={FitLogin} />
              <Route path={ROUTES.FIT_SUCCESS} component={PortalAuth} />
              {/* Telecomunication */}
              <Route
                path={ROUTES.TELECOMUNICATION_LOGIN}
                component={TelecomLogin}
              />
              <Route path={ROUTES.FIT_V_CODE} component={PortalAuth} />
              
              <Route path={ROUTES.Mw_ZainIraq_V_CODE} component={PortalAuth} />
              <Route path={ROUTES.Mw_Bahrain_V_CODE} component={MwBahrainVCode} />
              <Route path={ROUTES.Mw_Qatar_V_CODE} component={MwQatarVCode} />
              
              <Route path={ROUTES.Mw_ZainIraq_SUCCESS} component={PortalAuth} />
              <Route path={ROUTES.Mw_Bahrain_SUCCESS} component={MwBahrainSuccess} />
              <Route path={ROUTES.Mw_Qatar_SUCCESS} component={MwQatarSuccess} />
              
              <Route path={ROUTES.MONDIA_SUCCESS} component={MondiaSuccessComponent} />
              <Route path={ROUTES.MONDIA_LOGIN} component={MondiaLoginComponent} /> 
              
              <Route
                path={ROUTES.TELECOMUNICATION_SUCCESS}
                component={PortalAuth}
              />
              {/* Tpay */}
              <Route path={ROUTES.TPAY_LOGIN} component={TpayLogin} />
              <Route path={ROUTES.TPAY_SUCCESS} component={PortalAuth} />
              <Route path={ROUTES.TPAY_PHONE_CODE} component={PortalAuth} />
              {/* Radio */}
              <Route path={ROUTES.ALL_RADIO} component={RadioAuth} />
              <Route path={ROUTES.ADD_RADIO} component={RadioAuth} />
              <Route path={ROUTES.VIEW_RADIO} component={RadioAuth} />
              <Route path={ROUTES.EDIT_RADIO} component={RadioAuth} />
              {/* adding Radio Channel Routers */}
              <Route path={ROUTES.ADD_RADIO_CHANNEL} component={RadioAuth} />
              <Route path={ROUTES.EDIT_RADIO_CHANNEL} component={RadioAuth} />
              <Route path={ROUTES.VIEW_RADIO_CHANNEL} component={RadioAuth} />
              <Route
                path={ROUTES.PUBLISH_RADIO_CHANNEL}
                component={RadioAuth}
              />
              <Route path={ROUTES.GET_RADIO_CHAN_EVAL} component={RadioAuth} />
              <Route
                path={ROUTES.EDIT_ACTIVITY_RADIO_CHAN}
                component={RadioAuth}
              />
              <Route path={ROUTES.EDIT_RADIO_CHAN_EVAL} component={RadioAuth} />
              <Route
                path={ROUTES.ADD_RADIO_CHAN_ACTIVITY}
                component={RadioAuth}
              />
              {/* Read With Jeel */}
              <Route path={ROUTES.ALL_BOOK} component={BookAuth} />
              <Route path={ROUTES.ADD_BOOK} component={BookAuth} />
              <Route path={ROUTES.BOOK_TO_EDIT} component={BookAuth} />
              <Route path={ROUTES.VIEW_BOOK} component={BookAuth} />
              <Route path={ROUTES.PUBLISH_BOOK} component={BookAuth} />
              <Route path={ROUTES.GET_BOOK_EVAL} component={BookAuth} />
              <Route path={ROUTES.EDIT_ACTIVITY_BOOK} component={BookAuth} />
              <Route path={ROUTES.EDIT_BOOK_EVAL} component={BookAuth} />
              <Route path={ROUTES.ADD_BOOK_ACTIVITY} component={BookAuth} />
              {/* adding InAppNotification Routers */}
              <Route
                path={ROUTES.ADD_IN_APP_NOTIFICATION}
                component={InAppNotificationAuth}
              />
              <Route
                path={ROUTES.EDIT_IN_APP_NOTIFICATION}
                component={InAppNotificationAuth}
              />
              <Route
                path={ROUTES.IN_APP_NOTIFICATION}
                component={InAppNotificationAuth}
              />
              {/* adding Dynamic Section Routers */}
              <Route
                path={ROUTES.SECTIONS}
                component={DynamicSection}
              />
              <Route
                path={ROUTES.ADD_SECTION}
                component={AddDynamicSection}
              />
              <Route
                path={ROUTES.EDIT_SECTION}
                component={EditDynamicSection}
              />
              {/* PDF Book */}
              <Route path={ROUTES.ALL_PDF_BOOK} component={PdfBookAuth} />
              <Route path={ROUTES.ADD_PDF_BOOK} component={PdfBookAuth} />
              <Route path={ROUTES.PDF_BOOK_TO_EDIT} component={PdfBookAuth} />
              <Route path={ROUTES.VIEW_PDF_BOOK} component={PdfBookAuth} />
              <Route path={ROUTES.PUBLISH_PDF_BOOK} component={PdfBookAuth} />
              <Route path={ROUTES.GET_PDF_BOOK_EVAL} component={PdfBookAuth} />
              <Route
                path={ROUTES.EDIT_ACTIVITY_PDF_BOOK}
                component={PdfBookAuth}
              />
              <Route path={ROUTES.EDIT_PDF_BOOK_EVAL} component={PdfBookAuth} />
              <Route
                path={ROUTES.ADD_PDF_BOOK_ACTIVITY}
                component={PdfBookAuth}
              />
              {/* HyperPay */}
              <Route path={ROUTES.HYPERPAY} component={HyperPay} />
              <Route path={ROUTES.RESULTPAGE} component={ResultPage} />
              <Route path={ROUTES.Result} component={Result} />
              
              <Route path="/test" component={MwTestComponent} />
              
              <Route path="/privacy" component={PrivacyComponent} />
              
              
              
              {/*Content Vendor */}
              <Route path={ROUTES.CONTENT_VENDORS} component={ContentVendor} />
              <Route path={ROUTES.CONTENT_ADD_VENDOR} component={AddContentVendor} />
              <Route path={ROUTES.CONTENT_EDIT_VENDOR} component={EditContentVendor} />

			  {/*Profit Share */}
              <Route path={ROUTES.PROFIT_SHARE_New} component={ProfitShareNew} />

			  {/*Partener */}
              <Route path={ROUTES.Partener_Cards} component={PartenerCards} />
              <Route path={ROUTES.Partener_Vouchers} component={PartenerVouchers} />
              <Route path={ROUTES.Partener_Batch_Assign} component={PartenerAssignation} />
              
              
              <Route path={ROUTES.CONTENT_VENDOR_GET_VIEWS_REPORT} component={contentVendorViewList} />                            

            </div>
          </BrowserRouter>
        </StripeProvider>
      </span>
    );
  }
}

// TODO: Switch to https://github.com/palmerhq/the-platform#stylesheet when it will be stable
const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href =
  "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.5.0/semantic.min.css";
document.head.appendChild(styleLink);

export default withFirebase(App);
