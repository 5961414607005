import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { withFirebase } from '../../config';
import placeHolder from '../../images/placeholder.png';
import { DateInput } from 'semantic-ui-calendar-react';
import { Dropdown, Accordion, Icon } from 'semantic-ui-react';
/*Redux*/
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';

/*CSS*/
import "bootstrap/dist/css/bootstrap.min.css";
import '../../css/AppCss/Series/PublicSeriesCss.css';
import '../../css/AppCss/Series/ViewSeries.css';
/*Actions*/
import {
    getBookParentData,
    emptyBookParentWithBooks,
    emptyError,
    addBookParentItemWithSentFrom
} from '../../Actions/BookParentAction';

import { emptyAllBooks, addBookItemWithSentFrom } from '../../Actions/ReadWithJeelAction'

import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

import { emptyEvaluationCategory } from '../../Actions/EvaluationCategoryAction';
import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
import { clearQuestions } from '../../Actions/QuestionActions';


/*Const */
import NAME_CONDITIONS from '../ConstData/NameFilterConditions';
import DESCRIPTION_CONDITIONS from '../ConstData/NameFilterConditions';
import DATE_CONDITIONS from '../ConstData/DateFilterConditions'
import FREE_PAID from '../ConstData/FreeOrPaid'
import STATUS from '../ConstData/Status'

/*Component */
import Loader from '../Layout/Loader';
import DeleteBookParent from './DeleteBookParent'
import SetStateBookParent from './SetStateBookParent'

import DeleteBook from '../ReadWithJeel/DeleteBook';
import SetBookStatus from '../ReadWithJeel/SetBookState';
import { SIGN_IN, EDIT_BOOK_PARENT, BOOK_TO_GET, ADD_BOOK,ADD_STORY,ADD_PDF_BOOK, VIEW_BOOK_PARENT, VIEW_BOOK, BOOK_TO_EDIT,STORY_TO_GET,PDF_BOOK_TO_GET } from '../../routes';

import ShowBooksListComponent from '../Book/ShowBooksList'

import Tabs from '../customTabPane/Tabs';
import Tab from '../customTabPane/Tab';



const INITIAL_STATE = {
    addingDone: false,
    pushRoute: '',
    bookId: null,
    redirectionBook: null,
    /*filter */
    activeIndex: -1,

    filterName: '',
    nameFilterCondition: 'contains',

    dateFilterCondition: 'on',
    publishDate: '',
    secondPublishDate: '',

    isFree: 'none',
    status: 'none',

    descriptionFilterCondition: 'contains',
    description: '',
    featured:false
}


function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}


class ViewBookParent extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
	
	//console.log('In ViewBookParent , props: '+JSON.stringify(this.props,null,2));
	
            this.props.emptyAllBooks();
            this.props.emptyBookParentWithBooks();

            this.props.emptyEvaluationCategory();

            // getting Book Paren tId from redux
            const { redirectionPage } = this.props;
            
            let id = redirectionPage.series_Id;
            //id()
            
            //let id=this.props.bookParent.id;
            //console.log('the id of parent: '+id);
            

            // adding ViewBookParent As parent page
            // to redirect to it after adding book
            this.props.addSeriesIDtoRedirectionPage(VIEW_BOOK_PARENT, id);

            this.props.getBookParentData(id);
            
            //console.log('In ViewBookParent , parentId=: '+id);

        }
    }
    getBookParentData = (id) => {
        this.props.getBookParentData(id);
    }

    componentDidMount() {
        this.props.emptyError();


        this.props.emptyEvaluationCategory();

        this.props.emptyEvaluationAll();
        this.props.clearQuestions();

    }

    redirectToView = (itemId, item, parentID, parentName, e) => {

        this.props.addSeriesIDtoRedirectionPage(VIEW_BOOK, itemId);
        let itemContent = item
        item.parentID = parentID
        item.parentName = parentName
        this.props.addBookItemWithSentFrom(itemContent, "ViewBook");
        this.setState({ redirectionBook: true,
            itemId: itemId })
    }


    addingItemToRedux = (item, e) => {

        this.props.addBookParentItemWithSentFrom(item, "ViewBookParent");
        this.setState({ addingDone: true, pushRoute: "Parent" })
    }

    addingBookToRedux = (item, parentID, parentName, e) => {
        let itemContent = item
        item.parentID = parentID
        item.parentName = parentName
        this.props.addBookItemWithSentFrom(itemContent, "ViewBook");
        this.setState({ addingDone: true, pushRoute: "Book", bookId: itemContent.id })
    }


    handleStatus = (e, { value }) => {
        this.setState({
            status: value
        })
    }

    handlePaymentAva = (e, { value }) => {
        this.setState({
            isFree: value
        })
    }

    handleChange = (e) => {

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
    }

    handleDateChange = (e, { name, value }) => {

        if (this.state.hasOwnProperty(name)) {
            this.setState({
                [name]: value
            })
        }
    }

    handleNameFilterConditions = (e, { value }) => {
        this.setState({
            nameFilterCondition: value,
        })
    }

    handleDescriptionFilterConditions = (e, { value }) => {
        this.setState({
            descriptionFilterCondition: value,
        })
    }

    handleDateFilterConditions = (e, { value }) => {
        if (value == 'on' ||
            value == 'before' ||
            value == 'after') {
            this.setState({ secondPublishDate: '' })
        }

        this.setState({
            dateFilterCondition: value,
        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    render() {

        let secondDate = '', and = '', firstDate = ''

        firstDate = (<div className="col-md-4 col-4">

            <label className="label">Publish Date</label>
            <DateInput
                name="publishDate"
                closeOnMouseLeave
                placeholder="Publish date"
                iconPosition="right"
                dateFormat="MM/DD/YYYY"
                fluid
                value={this.state.publishDate}
                onChange={this.handleDateChange}
            />
        </div>
        )

        if (this.state.dateFilterCondition == 'not between' ||
            this.state.dateFilterCondition == 'between') {


            firstDate = (<div className="col-md-3 col-3">

                <label className="label">Publish Date</label>
                <DateInput
                    name="publishDate"
                    closeOnMouseLeave
                    placeholder="Publish date"
                    iconPosition="right"
                    dateFormat="MM/DD/YYYY"
                    fluid
                    value={this.state.publishDate}
                    onChange={this.handleDateChange}
                />
            </div>)
            and = (<div className="col-md-1 col-1">
                <label className="label mt-4">And</label>
            </div>
            )
            secondDate = (
                <div className="col-md-3 col-3">
                    <label className="label">Publish Date</label>
                    <DateInput
                        name="secondPublishDate"
                        closeOnMouseLeave
                        placeholder="Publish date"
                        iconPosition="right"
                        dateFormat="MM/DD/YYYY"
                        fluid
                        value={this.state.secondPublishDate}
                        onChange={this.handleDateChange}
                    />
                </div>
            )
        }

        const {
            filterName, nameFilterCondition,
            description, descriptionFilterCondition,
            publishDate,
            dateFilterCondition,
            secondPublishDate,
            isFree,
            status,
            featured
        } = this.state;

        const { addingDone, pushRoute, bookId, redirectionBook } = this.state;

        if (redirectionBook == true) {
            return (<Redirect to={VIEW_BOOK} ></Redirect>)
        }


        if (addingDone == true && pushRoute == "Parent") {
            return (<Redirect to={EDIT_BOOK_PARENT} ></Redirect>)
        }

        if (addingDone == true && pushRoute == "Book" && bookId) {
            return (<Redirect to={BOOK_TO_EDIT} ></Redirect>)
        }

        const { bookParent, loading, error } = this.props;
        //console.log(bookParent)
        let parentAndBookList = null
        let booksList = null
        
        let choosenNameFilter = '',
            choosenPublishDate = '',
            choosenIsFreeOrNot = '',
            choosenStatus = '',
            choosenDescription = ''

        let bookParentDescription = ''
        let tempImg = bookParent.image;
        if (!bookParent.image) {
            tempImg = placeHolder;
        }


        if (bookParent.description !== null &&
            bookParent.description !== undefined &&
            bookParent.description !== '') {
            bookParentDescription = bookParent.description
        }
        else {
            bookParentDescription = "No description"
        }

        let bookItems = bookParent.books
        let storyBooksList = [];
		let pdfBooksList = [];
		
		
		if (bookParent.stories) {
			storyBooksList=bookParent.stories
		}
		if (bookParent.pdfBooks) {
			pdfBooksList=bookParent.pdfBooks
		}
		
		//console.log('storyBooksList :'+storyBooksList?storyBooksList.length:'Empty');
		//console.log('pdfBooksList :'+pdfBooksList?pdfBooksList.length:'Empty');

		// ---------------
		// handle Tab Selection :
		// ---------------		
		let selectedTabIndex =0
		//console.log('initial SelectIndex: '+selectedTabIndex);
		if(this.props.location.state && this.props.location.state.toTab)
		{
			let toTab = this.props.location.state.toTab
		 	selectedTabIndex = toTab=='BOOK'?0:toTab=='STORY'?1:toTab=='PDFBOOK'?2:0 ;
		 	//console.log('TOTAB :'+toTab+"  ,selectInd: "+selectedTabIndex);
		}
		//console.log('End SelectIndex: '+selectedTabIndex);

        let booksToFilter = ''
        if(bookParent.books) {
            if (featured){
                booksToFilter = bookParent.books.filter(item => (item.featured === true))
            }
            else {
                booksToFilter = bookParent.books
            }
        }

        if (bookParent.books) {
            const FilteredList = booksToFilter.filter(item => {

                if (nameFilterCondition == 'is') {
                    choosenNameFilter = item.name == filterName
                } else if (nameFilterCondition == 'does not contain') {
                    choosenNameFilter = !item.name.includes(filterName)
                } else if (nameFilterCondition == 'is not') {
                    choosenNameFilter = item.name != filterName
                } else {
                    choosenNameFilter = item.name.includes(filterName)
                }
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== '') {

                    if (descriptionFilterCondition == 'is') {
                        choosenDescription = item.description == description
                    } else if (descriptionFilterCondition == 'does not contain') {
                        choosenDescription = !item.description.includes(description)
                    } else if (descriptionFilterCondition == 'is not') {
                        choosenDescription = item.description != description
                    } else {
                        if (description !== '' && description !== null && description !== undefined) {
                            choosenDescription = item.description.includes(description)

                        } else {
                            choosenDescription = true
                        }

                    }
                }
                if (isFree == 'paid') {
                    choosenIsFreeOrNot = item.isFree == 0
                } else if (isFree == 'free') {
                    choosenIsFreeOrNot = item.isFree == 1
                }

                if (status == 'DEFAULT') {
                    choosenStatus = item.status == 'Default' || item.status == 'DEFAULT'
                } else if (status == 'SUSPENDED') {
                    choosenStatus = item.status == 'SUSPENDED'
                }


                let arrayOfDate = item.publishDate.split(" ")
                let date = arrayOfDate[0];

                if (dateFilterCondition == 'on') {
                    choosenPublishDate = publishDate == date
                }
                else if (dateFilterCondition == 'after') {

                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);

                    choosenPublishDate = filterDate < seriesDate
                }
                else if (dateFilterCondition == 'before') {
                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);

                    choosenPublishDate = filterDate > seriesDate;
                }
                else if (dateFilterCondition == 'between') {

                    if (secondPublishDate !== '' &&
                        secondPublishDate !== null &&
                        secondPublishDate !== undefined) {
                        var seriesDate = new Date(date);
                        var filterDate = new Date(publishDate);
                        var filterSecondDate = new Date(secondPublishDate);

                        choosenPublishDate = filterDate < seriesDate && seriesDate < filterSecondDate
                    }
                }
                else if (dateFilterCondition == 'not between') {
                    if (secondPublishDate !== '' &&
                        secondPublishDate !== null &&
                        secondPublishDate !== undefined) {
                        console.log("////////////////" + item.name + "////////////////////")
                        var seriesDate = new Date(date);
                        var filterDate = new Date(publishDate);
                        var filterSecondDate = new Date(secondPublishDate);

                        console.log("seriesDate")
                        console.log(date)
                        console.log(seriesDate)

                        console.log("filterDate")
                        console.log(publishDate)
                        console.log(filterDate)

                        console.log("filterDate")
                        console.log(secondPublishDate)
                        console.log(filterSecondDate)

                        let notbetween = filterDate < seriesDate && seriesDate < filterSecondDate

                        choosenPublishDate = !notbetween
                    }

                }


                /* Name and Date and Payment and status and description*/
                if (publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

                ) {
                    console.log("Name and Date and Payment and status and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenNameFilter &&
                            choosenIsFreeOrNot && choosenStatus && choosenDescription
                    }
                }

                /* Name and Date and Payment and status*/
                if (publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none'
                ) {
                    console.log("Name and Date and Payment and status")
                    return choosenPublishDate && choosenNameFilter && choosenIsFreeOrNot && choosenStatus
                }

                /* Name and Date and Payment and description*/
                if (publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

                ) {
                    console.log("Name and Date and Payment and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenNameFilter &&
                            choosenIsFreeOrNot && choosenDescription && choosenDescription
                    }
                }

                /* Name and Date and Payment*/
                if (publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none'
                ) {
                    console.log("Name and Date and Payment")
                    return choosenPublishDate && choosenNameFilter && choosenIsFreeOrNot
                }
                ///* Name and Date and status and description
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                    descriptionFilterCondition !== undefined
                ) {
                    console.log("Name and Date and status and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenNameFilter && choosenStatus && choosenDescription
                    }
                }

                ///* Name and Date and status
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    status !== "" && status !== null && status !== undefined && status !== 'none'
                ) {
                    console.log("Name and Date and status")
                    return choosenPublishDate && choosenNameFilter && choosenStatus
                }

                ///* Name and Date and description
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

                ) {
                    console.log("Name and Date and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenNameFilter && choosenDescription
                    }
                }
                ///* Name and Date
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    filterName !== "" && filterName !== null && filterName !== undefined &&
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined
                ) {
                    console.log("Name and Date")
                    return choosenPublishDate && choosenNameFilter
                }

                //date and payment and status and description
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("date and payment and status and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenIsFreeOrNot && choosenStatus && choosenDescription
                    }
                }

                //date and payment and status
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none') {
                    console.log("date and payment and status")
                    return choosenPublishDate && choosenIsFreeOrNot && choosenStatus
                }
                //date and payment and description
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("date and payment and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenIsFreeOrNot && choosenDescription
                    }
                }

                //date and payment
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none') {
                    console.log("date and payment")
                    return choosenPublishDate && choosenIsFreeOrNot
                }

                //date and status and description
                else if (publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("date and status and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenStatus && choosenDescription
                    }
                }
                //date and status
                else if (publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                    status !== "" && status !== null && status !== undefined && status !== 'none') {
                    console.log("date and status")
                    return choosenPublishDate && choosenStatus
                }
                //date and description
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null &&
                    dateFilterCondition !== undefined &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("date and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenPublishDate && choosenDescription
                    }
                }
                //date
                else if (
                    publishDate && publishDate !== null && publishDate !== undefined &&
                    dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined) {
                    console.log("date")
                    return choosenPublishDate
                }
                // name and payment and status and description 
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("name and payment and status and description ")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenNameFilter && choosenIsFreeOrNot && choosenStatus && choosenDescription
                    }
                }

                // name and payment and status
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none') {
                    console.log("name and payment and status")
                    return choosenNameFilter && choosenIsFreeOrNot && choosenStatus
                }

                // name and payment and description
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("name and payment and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenNameFilter && choosenIsFreeOrNot && choosenDescription
                    }
                }
                // name and payment
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none') {
                    console.log("name and payment")

                    return choosenNameFilter && choosenIsFreeOrNot
                }

                //name and status and description
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

                ) {
                    console.log("name and status and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenNameFilter && choosenStatus && choosenDescription
                    }
                }
                //payment and status and description
                else if (
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("payment and status and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenIsFreeOrNot && choosenStatus && description
                    }
                }
                //payment and status
                else if (
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                    status !== "" && status !== null && status !== undefined && status !== 'none') {
                    console.log("payment and status")
                    return choosenIsFreeOrNot && choosenStatus
                }

                //payment and description
                else if (
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&

                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
                ) {
                    console.log("payment and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenIsFreeOrNot && choosenDescription
                    }
                }
                //payment
                else if (
                    isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none') {
                    console.log("payment")
                    return choosenIsFreeOrNot
                }

                // status and description
                else if (
                    status !== "" && status !== null && status !== undefined && status !== 'none' &&
                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                    descriptionFilterCondition !== undefined
                ) {
                    console.log("status and description")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenStatus && choosenDescription
                    }
                }
                //name and status
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                    status !== "" && status !== null && status !== undefined && status !== 'none'
                ) {
                    console.log("name and status")
                    return choosenNameFilter && choosenStatus
                }

                // status
                else if (
                    status !== "" && status !== null && status !== undefined && status !== 'none') {
                    console.log("status")
                    return choosenStatus
                }


                //name and description
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null &&
                    nameFilterCondition !== undefined &&
                    descriptionFilterCondition !== "" &&
                    descriptionFilterCondition !== null &&
                    descriptionFilterCondition !== undefined
                ) {
                    //console.log("///name and description")

                    // base case both are null return all values
                    if ((filterName == '' || filterName == null || filterName == undefined) &&
                        (description == '' || description == null || description == undefined)) {
                        //console.log("both empty")
                        return true;
                    }
                    else if ((filterName !== '' && filterName !== null && filterName !== undefined) &&
                        (description !== '' && description !== null && description !== undefined)) {
                        if (item.description !== null &&
                            item.description !== undefined &&
                            item.description !== ""
                        ) {
                            console.log("both not empty")
                            return choosenNameFilter && choosenDescription
                        }
                    }
                    else if (filterName !== '' && filterName !== null && filterName !== undefined) {
                        console.log("name exist")
                        return choosenNameFilter
                    }
                    else if (description !== '' && description !== null && description !== undefined) {
                        console.log("description exist")
                        if (item.description !== null &&
                            item.description !== undefined &&
                            item.description !== ""
                        ) {

                            return choosenDescription;
                        }
                    }
                }
                //name
                else if (
                    nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined) {
                    console.log("name")
                    return choosenNameFilter
                }
                // description
                else if (
                    description !== "" && description !== null && description !== undefined &&
                    descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                    descriptionFilterCondition !== undefined
                ) {
                    console.log("description")

                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        return choosenDescription
                    }
                }
            })


            if (filterName !== '' || publishDate || isFree
                || status || description !== '') {
                bookItems = FilteredList
            }
         }

// ----------------------------------------------------------------------------------------------
//console.log('storyBooksList: '+storyBooksList);
// ----------------------------------------------------------------------------------------------

        


        if (loading === true) {
            parentAndBookList = (<Loader />);
        }

        else {
            parentAndBookList = (
                <div className="container data-container">

                    {/* start book Parent data */}

                    <div className="card" id="editSeriesCard">                                            
                        <div className="row" >
                            <div className="col" >
                                <img className="img-thumbnail" src={tempImg}
                                    alt="BookParent" />
                            </div>
                            <div className="col-10">
                                <div >
                                    <span className="card-title">
                                        {bookParent.name} 
                                    </span>

                                    <DeleteBookParent id={bookParent.id} />  

                                    <button type="submit"
                                        className="seriesCardButtons"
                                        onClick={this.addingItemToRedux.bind(this, bookParent)}>
                                        Edit
                                    </button>


                                    <SetStateBookParent
                                        status={bookParent.status}
                                        id={bookParent.id}
                                        sentFrom="ViewBookParent" />

                                </div>
                                <span className="font-italic small-font ">
                                    <span className="date">
                                        {GMTtolocalTime(bookParent.createdDate)} 
                                    </span>
                                    <hr />
                                    <span><p>{bookParentDescription} </p></span>
                                </span>

                            </div>
                        </div>
                        
                        <br/>
                        
						<div className="row mt-5">
											{/* Add Book  */}
                                            <Link to={{ pathname: ADD_BOOK,state: {bookParentId:bookParent.id} }} params={{ testvalue: "hello" }}>
                                                <button type="submit"
                                                    className="seriesButtons">
                                                    + Add New Book
                                                </button>
                                            </Link>
                                            
											{/* Add Story  */}
                                            <Link to={{ pathname: ADD_STORY ,state: {bookParentId:bookParent.id}}}>
                                                <button type="submit"
                                                    className="seriesButtons">
                                                    + Add New Story
                                                </button>
                                            </Link>
                                                                                        
                                            {/* Add PDF  */}
                                            <Link to={{ pathname: ADD_PDF_BOOK ,state: {bookParentId:bookParent.id}}}>
                                                <button type="submit"
                                                    className="seriesButtons">
                                                    + Add New PDF
                                                </button>
                                            </Link>

                                                                                        
                        </div>
                                                
                        
                        

                    </div>
                    {/* end bookParent data */}

					<div className="mt-2" style={{height:'50px'}}>  </div>	

			
					<br/>
<Tabs selec={selectedTabIndex}>
      <Tab title="Books" key={"1"}><ShowBooksListComponent bookItems={bookItems} bookParent={bookParent} detailsPage={BOOK_TO_GET} type="book" editFunc={addBookItemWithSentFrom}/></Tab>      
      <Tab title="Story" key={"2"}><ShowBooksListComponent bookItems={storyBooksList} bookParent={bookParent} detailsPage={STORY_TO_GET} type="story" editFunc={addBookItemWithSentFrom} /></Tab>
      <Tab title="PDF" key={"3"}><ShowBooksListComponent bookItems={pdfBooksList} bookParent={bookParent} detailsPage={PDF_BOOK_TO_GET} type="pdf" editFunc={addBookItemWithSentFrom} /></Tab>
</Tabs>
    					
					
<div className="mt-2" style={{height:'50px'}}>   </div>					

                    {/* Start of book style */}
                    {/*booksList*/}
                    {/* End of book style */}

                </div>
            );
        }


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <NavBar />

                                            <br />
                                            {/*<Link to={{ pathname: ADD_BOOK, parentId: bookParent.id, parentName: bookParent.name }}>
                                                <button type="submit" className="seriesButtons" onClick={() => {this.props.emptyAllBooks()}}>
                                                    + Add New Book 
                                                </button>
                                            </Link>*/}
                                            <br />
                                            <Accordion fluid styled>
                                                <Accordion.Title
                                                    active={this.state.activeIndex === 0}
                                                    index={0}
                                                    onClick={this.handleClick}
                                                >
                                                    <Icon name='dropdown' />
                                                    Filter
                                                </Accordion.Title>
                                                <Accordion.Content active={this.state.activeIndex === 0}>


                                                    <div className="row" >

                                                        <div className="col-md-5 col-5">
                                                            <label className="label">Book Name Conditions</label>

                                                            <Dropdown
                                                                className="form-control"
                                                                placeholder='Select Condition'
                                                                selection
                                                                options={NAME_CONDITIONS}
                                                                onChange={this.handleNameFilterConditions}
                                                                value={this.state.nameFilterCondition}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-6">

                                                            <label className="label">Book Name</label>

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                name="filterName"
                                                                placeholder='..'
                                                                id="filterName"
                                                                onChange={this.handleChange}
                                                                value={this.state.filterName} />

                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row" >

                                                        <div className="col-md-5 col-5">
                                                            <label className="label">Book Description Conditions</label>

                                                            <Dropdown
                                                                className="form-control"
                                                                placeholder='Select Condition'
                                                                selection
                                                                options={DESCRIPTION_CONDITIONS}
                                                                onChange={this.handleDescriptionFilterConditions}
                                                                value={this.state.descriptionFilterCondition}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-6">

                                                            <label className="label">Book Description</label>

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                name="description"
                                                                placeholder='..'
                                                                id="description"
                                                                onChange={this.handleChange}
                                                                value={this.state.description} />

                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-md-5 col-5">
                                                            <label className="label">Book Date Conditions</label>

                                                            <Dropdown
                                                                className="form-control"
                                                                placeholder='Select Condition'
                                                                selection
                                                                options={DATE_CONDITIONS}
                                                                onChange={this.handleDateFilterConditions}
                                                                value={this.state.dateFilterCondition}
                                                            />
                                                        </div>
                                                        {firstDate}
                                                        {and}
                                                        {secondDate}


                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-md-5 col-5">
                                                            <label className="label">
                                                                Select payment availability
                                                            </label>

                                                            <Dropdown required fluid selection
                                                                placeholder='Select Payment Availability'
                                                                id="episode_series_id"
                                                                onChange={this.handlePaymentAva}
                                                                options={FREE_PAID}
                                                                value={this.state.isFree}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-4">

                                                            <label className="label">Select Status </label>
                                                            <Dropdown required fluid selection
                                                                placeholder='Select Status'
                                                                id="episode_series_id"
                                                                onChange={this.handleStatus}
                                                                options={STATUS}
                                                                value={this.state.status}
                                                            />
                                                        </div>
                                                    </div>

                                                    <br />
                                                    <div className='row'>
                                                        <div className="col-md-5 col-5">
                                                            <div className='featured-checkbox'>
                                                                <input id="cb" type="checkbox" style={{float: "left", marginTop: "3px", width: "15px", height: "15px" , accentColor:"purple"}} 
                                                                    onChange={(e) => {
                                                                        this.setState({featured: e.target.checked})
                                                                    }} />
                                                                <label htmlFor="cb" style={{marginLeft: "8px" ,marginBottom:"0px"}}>
                                                                    Show Only Featured
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Content>

                                            </Accordion>
                                            
                                            
                                            <br />
                                            {parentAndBookList}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

ViewBookParent.propTypes = {
    getBookParentData: propTypes.func.isRequired,
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    addBookItemWithSentFrom: propTypes.func.isRequired

}
const mapStateToProps = state => ({
    bookParent: state.bookParent.item,
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item,
    loading: state.bookParent.loading,
    error: state.bookParent.error_loading_book_parent
});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                getBookParentData,
                emptyBookParentWithBooks,
                emptyError,
                emptyEvaluationCategory,
                clearQuestions,
                emptyEvaluationAll,
                addBookParentItemWithSentFrom,
                addBookItemWithSentFrom,
                addSeriesIDtoRedirectionPage,
                emptyAllBooks

            })
        (ViewBookParent));

