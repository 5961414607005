import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
/*navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { addBook } from '../../Actions/ReadWithJeelAction'
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
// import { fetchAllRadio } from '../../Actions/RadioActions';
import { addActivity } from '../../Actions/ActivityActions';
/*CSS */
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/Songs.css';
/**Other Component */
import PublishDate from '../Layout/PublishDate';
import UploadImage from '../S3Uploading/UploadImage';
import UploadAudio from '../S3Uploading/UploadAudio';
import { ADD_BOOK_ACTIVITY, ALL_BOOK, ALL_BOOK_PARENTS, VIEW_BOOK_PARENT } from '../../routes';
import DeletePage from './DeletePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadPageImage from '../S3Uploading/UploadPageImage';
import UploadExcelFile from '../S3Uploading/UploadExcelFile';
import { Popup } from 'semantic-ui-react'
import CSVReader from 'react-csv-reader'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {fetchAllBookParent} from '../../Actions/BookParentAction';

import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'


const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const INITIAL_STATE = {
    redirection: false,

    selected_radio_id: "",
    selected_radio_name: "",

    returned_radio_id_viewRadioPage: "",
    returned_radio_name_viewRadioPage: "",


    name: "",
    description: "",
    name_in_english: "",
    description_in_english: "",
    imageUrl: "",

    titleAudio: "",
    bookAudio: "",
    audioMarker: "",

    showfileUploader: true,

    isFree: '',

    auth_user: {},
    publishDateTime: "",
    bookbutton: '',
    voice: '',
    subValueId: '',
    featured: false,
    contentVendorSelectedId:'',
    ////////////////// validation form
    error: true,
    errorMessage: "",

    // Book Pages
    pages: [],
    selected_bookParent_id: '',
    selected_bookParent_name: '',

    returned_bookParent_id_viewBookParentPage: '',
    returned_bookParent_name_viewBookParentPage: '',
	bookParentId:'',
	ageFrom:'',
    ageTo:'',
}
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'plum' : '#FFF',
    paddingBottom: "10px", marginBottom: "10px", borderBottom: "1px solid #22242626",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : '#FFF',
});

class AddBook extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const pages = reorder(
            this.state.pages,
            result.source.index,
            result.destination.index
        );
        pages && pages.length && pages.map((page, index) => {
            page.pageIndex = (index + 1)
        });

        await this.setState({
            pages
        });
    }

    componentDidMount() {
        // this.props.fetchAllRadio();
        this.props.fetchAllBookParent();
        this.props.fetchAllSubValues();
        this.props.fetchAllContentVendors();


        const { readWithJeel } = this.props;
        let showfileUploaderStatus = readWithJeel.audioMarker && readWithJeel.audioMarker != '' && readWithJeel.audioMarker != null ? false : true
        this.setState({
            error: false,
            name: readWithJeel.name,
            imageUrl: readWithJeel.image,
            publishDateTime: readWithJeel.PublishDate,
            titleAudio: readWithJeel.titleAudio,
            bookAudio: readWithJeel.bookAudio,
            audioMarker: readWithJeel.audioMarker,

            showfileUploader: showfileUploaderStatus, // True /  False

            isFree: readWithJeel.isFree,
            // voice: readWithJeel.voice,
            subValueId: readWithJeel.subValueId,
            description: readWithJeel.description,
            name_in_english: readWithJeel.nameInEnglish,

            bookbutton: readWithJeel.bookbutton,

            // Pages
            pages: readWithJeel.pages ? readWithJeel.pages : [],

            selected_bookParent_id: readWithJeel.selected_bookParent_id,
            selected_bookParent_name: readWithJeel.selected_bookParent_name,

            returned_bookParent_id_viewBookParentPage: readWithJeel.selected_bookParent_id,
            returned_bookParent_name_viewBookParentPage: readWithJeel.selected_bookParent_name,
        })

        const { parentName } = this.props.location;
        //console.log('location: '+JSON.stringify(this.props.location,null,2))

		let parentId = this.props.location.state.bookParentId

        if (parentId) {
            this.setState({
                returned_bookParent_name_viewBookParentPage: parentName,
                returned_bookParent_id_viewBookParentPage: parentId,
                selected_bookParent_id: parentId,
                selected_bookParent_name: parentName,
                bookParentId:parentId,
            });
        }
    }

    // getFileUrl(fileURL) {
    //     if (fileURL) {
    //         this.setState({
    //             audioMarker: fileURL,
    //             showfileUploader: false
    //         });
    //         console.log("Audio Marker fileUrl")
    //         console.log(fileURL);
    //     }
    // }

    handleFileUploaderPreview = (e) => {
        const { showfileUploader } = this.state;

        if (showfileUploader === true) {
            this.setState({
                showfileUploader: false
            })
        }
        else {
            this.setState({
                showfileUploader: true
            })
        }
    }

handleAgeFromSelection = (e, { value }) => {
       this.setState({ ageFrom: value })
    }
handleAgeToSelection = (e, { value }) => {
       this.setState({ ageTo: value })
    }
    

    handleDateChange = (e, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;
        setTimeout(() => {
            if (
                (this.state.name === ''
                    || this.state.name === null
                    || this.state.name === ' ')
                    || this.state.description_in_english.length > 255
                    // ||  (this.state.name_in_english === ''
                    // || this.state.name_in_english === null
                    // || this.state.name_in_english === ' ')

            ) {
                this.setState({ error: true, errorMessage: nameOfState === "description_in_english" ? 
                    "Description in English is too long (greater than 255 characters)." : 
                    "Empty Data is not accepted" });
            }
            else if (
                (this.state.name !== ''
                    && this.state.name !== null
                    && this.state.name !== ' ')
                    && this.state.description_in_english.length <= 255
                    // &&  (this.state.name_in_english !== ''
                    // && this.state.name_in_english !== null
                    // && this.state.name_in_english !== ' ')      
            ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }, 0);
    }

    handlePaymentAva = (e, { value }) => {

        console.log(value)

        this.setState({
            isFree: value
        })
    }


    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };


    handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { name, description,
            imageUrl,
            publishDateTime,
            titleAudio,
            bookAudio,
            audioMarker,
            bookbutton, isFree,
            voice, subValueId,
            selected_radio_id,
            selected_radio_name,
            pages,
            selected_bookParent_id,
            selected_bookParent_name,
            name_in_english,
            description_in_english,
            featured,contentVendorSelectedId,ageFrom,ageTo } = this.state;

        if (e.nativeEvent.submitter.id === "nextButton") {

            if (isFree === ''
                || isFree == undefined
                || isFree == null
                || isFree == "") {
                alert("You must choose payment availability")
                this.setState({ validateInput: false })
                e.preventDefault();
            } else if (publishDateTime === ''
                || publishDateTime == undefined) {
                this.setState({ validateInput: false })
            } else {
                if (name === ''
                    || name == undefined
                    // || name_in_english === ''
                    // || name_in_english == undefined
                    ) {
                    alert("Empty Name ")
                    this.setState({ validateInput: false })
                //} else if(selected_bookParent_id == '' || selected_bookParent_id == null) {
                //    alert("Empty Parent")
                //    this.setState({ validateInput: false })
                } else {
	
	let bookParentId = this.props.location.state.bookParentId;
	//console.log('pdf parentId to submit: '+bookParentId);
	
	
	
	if(ageFrom){
		if(ageTo && ageTo< ageFrom){
			alert('Please Enter AgeTo greater than ageFrom')
			return false;	
		}
	}
	
	
	
                    this.setState({ validateInput: true });
                    this.props.addBook(name,
                        description, imageUrl,
                        publishDateTime,
                        titleAudio,
                        bookAudio,
                        audioMarker,
                        bookbutton,
                        pages,
                        isFree,
                        subValueId,
                        selected_bookParent_id,
                        selected_bookParent_name,
                        name_in_english,
                        description_in_english,
                        featured,contentVendorSelectedId,bookParentId,ageFrom,ageTo
                    )
                }
            }
        }
        /// calling save
    }

    handleCancel = (e) => {
        this.setState({ redirection: true })
    }

    getPublishDate(publishDateTime, bookButtonState) {
        if (bookButtonState) {
            this.setState({ bookbutton: bookButtonState })
        }

        if (publishDateTime) {
            this.setState({ publishDateTime: publishDateTime });
        }
    }

    getImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({
                imageUrl: '',
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ imageUrl: imageUrl });
            if (this.state.name !== ''
                && this.state.name !== ' '
                && this.state.name !== null
                // && this.state.name_in_english !== ''
                // && this.state.name_in_english !== ' '
                // && this.state.name_in_english !== null
            ) {
                this.setState({
                    error: false,
                    errorMessage: ""
                });
            }
        }
    }

    getPageImageUrl(imageUrl, index) {
        let { pages } = this.state;
        if (imageUrl === 'delete') {
            pages[index].image = ''
            this.setState({ pages });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            pages[index].image = imageUrl
            this.setState({ pages });
        }
    }


    getAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ voice: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.voice);
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.voice);

            }

        }
    }

    getTitleAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ titleAudio: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.titleAudio);
            }
            // else send the url to DB
            else {
                this.setState({ titleAudio: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.titleAudio);

            }

        }
    }
    getBookAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ bookAudio: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.bookAudio);
            }
            // else send the url to DB
            else {
                this.setState({ bookAudio: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.bookAudio);

            }

        }
    }
    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };
    handlePageDescChange = (e, index) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        let { pages } = this.state;
        if (
            (checkSpaces === ''
                || checkSpaces === null
                || checkSpaces === ' ')
        ) {
            this.setState({
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (
            (checkSpaces !== ''
                && checkSpaces !== null
                && checkSpaces !== ' ')
        ) {
            this.setState({ error: false, errorMessage: "" });
        }
        setTimeout(() => {
            pages[index].text = checkSpaces.trim()
            this.setState({
                pages
            })
        }, 0);
    }
    bookPagesList = (e) => {
        let { pages } = this.state
        let pagesList = pages && pages.length ? pages.map((page, index) => {
            return (
                <Draggable
                    key={page.pageID} // => page.id
                    draggableId={page.pageID} // => page.id
                    index={index}
                >
                    {(provided, snapshot) => (
                        <div>
                            <div ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                    provided.draggableProps.style,
                                    snapshot.isDragging
                                )}
                                key={index} className="page-item">
                                <div className="page-inputs">
                                    <div className='row'>
                                    <div className='col-12'>
                                        <div style={{backgroundColor: "#e0e1e2", fontWeight: "bold", padding: "10px", marginBottom: "5px"}}>
                                        {page.pageIndex ? "Page No. " + page.pageIndex : "Page Without Number"}
                                        </div>
                                    </div>
                                        <div className='col-12 col-sm-4'>
                                            <div className="page-image">
                                                <UploadPageImage
                                                    getImageURLCallBack={this.getPageImageUrl.bind(this)}
                                                    imageUrl={page.image}
                                                    itemIndex={index}
                                                    imagepreview={page.image}
                                                    s3DirectryrName='readWithJeel/bookPages'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-8'>
                                            <div className="page-description h-100">
                                                <textarea dir="auto"
                                                    className="form-control"
                                                    style={{ height: "100%" }}
                                                    // id="exampleFormControlTextarea1"
                                                    // rows="6"
                                                    name="pageDescription"
                                                    onChange={(e) => this.handlePageDescChange(e, index)}
                                                    defaultValue={page.text}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="page-action" style={{ display: 'flex', alignItems: "center", textAlign: "end", marginTop: "10px" }}>

                                    {/* <DeletePage id={index} /> */}
                                    <button style={{ marginInlineStart: "auto" }} type="button" className="ui button" onClick={(e) => this.deleteBookPage(index)}>
                                        <FontAwesomeIcon icon="trash-alt" />
                                    </button>
                                    <div className='dnd_bars_icon' style={{ marginInlineStart: '10px' }}>
                                        <div className='dnd_bar' style={{ marginBottom: '5px', height: '2px', width: '20px', backgroundColor: "purple" }}></div>
                                        <div className='dnd_bar' style={{ marginBottom: '5px', height: '2px', width: '20px', backgroundColor: "purple" }}></div>
                                        <div className='dnd_bar' style={{ marginBottom: '5px', height: '2px', width: '20px', backgroundColor: "purple" }}></div>
                                    </div>

                                </div>
                            </div>
                            {provided.placeholder}
                        </div>
                    )}

                </Draggable>

            )
        }) : <div className="text-center">No Pages</div>
        let dragList = ''
        dragList = pages && pages.length ? (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {
                                pagesList
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        ) : dragList = <div className="text-center">No Pages</div>
        return dragList
    }

    addBookPage = (e) => {
        let page = { image: "", text: "", pageIndex: this.state.pages.length ? this.state.pages.length + 1 : 1, pageID: "pageID_" + (Math.random() * 1000000) }
        let { pages } = this.state
        pages.push(page)
        this.setState({
            pages
        })
    }

    deleteBookPage = (index) => {
        let { pages } = this.state
        pages.splice(index, 1)
        this.setState({
            pages
        })
    }

    onCSVFileLoaded = (data, fileInfo, originalFile) => {
        if (data) {
            this.setState({
                audioMarker: JSON.stringify(data),
                showfileUploader: false
            });
            // alert("The file has been uploaded successfully")
        }
    }
    onCSVFileError = () => {
        alert("Marker Upload Failed")
    }

    /*handleBookParentChange = (e, { value }) => {
        console.log("selected_bookParent_id 4 = " + value[0].id);
        console.log("selected_bookParent_name 5 = " + value[1].name);

        this.setState({ selected_bookParent_id: value[0].id })
        this.setState({ selected_bookParent_name: value[1].name })
    }*/

    render() {
        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
        };

        const { name, description,
            imageUrl,
            publishDateTime,
            titleAudio,
            bookAudio,

            bookbutton, isFree,
            voice, subValueId,
            selected_radio_id,
            selected_radio_name,
            pages, audioMarker, showfileUploader,
            name_in_english,bookParentId } = this.state;

        const isInvalid =
            name === '' || name == undefined ||
            // name_in_english === '' || name_in_english == undefined ||
            imageUrl === '' || imageUrl == undefined ||
            pages === '' || pages == undefined ||
            isFree === '' || isFree == undefined ||
            // selected_radio_id === '' || selected_radio_id == undefined ||
            // selected_radio_name === '' || selected_radio_name == undefined ||
            titleAudio === '' || titleAudio == undefined ||
            bookAudio === '' || bookAudio == undefined;


// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------
const AgeFromArr = [];

for (let i = 1; i <= 10; i++) 
    {
      AgeFromArr.push({
        key: i,
        text: i,
        value: i,
      });
    }
// --------------------------------

        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }

        const{bookParent} = this.props;
        const bookParentList = [];
        for (let i = 0; i < bookParent.length; i++) {
            bookParentList.push({
                key: bookParent[i].id,
                text: bookParent[i].name,
                value: [{ id: bookParent[i].id }, { name: bookParent[i].name }]
            })
        }

         // for dropdown Song Parent selection 
         let selectedBookParent = null;
         /*if (this.state.returned_bookParent_name_viewBookParentPage) {
            selectedBookParent = (<input
                 dir="auto"
                 className="form-control"
                 fluid id="episode_description"
                 value={this.state.returned_bookParent_name_viewBookParentPage} />);
         }
         else {
            selectedBookParent = (
            <Dropdown fluid 
            selection 
            placeholder='Select Book Parent'
            name="bookParent_id" 
            // id="episode_series_id"  
            onChange={this.handleBookParentChange} 
            options={bookParentList} />
             );
         }*/


        let fileLink;
        if (audioMarker !== undefined &&
            audioMarker !== null &&
            audioMarker !== '') {
            fileLink = (
                <div style={{ maxWidth: '100%' }}>
                    <span style={{ wordBreak: "break-all" }}>
                        <button type="button" className="ui blue button" data-toggle="modal" data-target="#merkerDataModal">
                            View Marker Data
                        </button>
                        <div className="modal fade bd-example-modal-lg" id="merkerDataModal" tabindex="-1" role="dialog" aria-labelledby="merkerDataModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="merkerDataModalLabel">Marker Data</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {/* ...Table... */}
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th style={{ borderTop: "none" }} scope="col">Name</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Description</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Start</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Duration</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Type</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Time Format</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    JSON.parse(audioMarker).length ? JSON.parse(audioMarker).map((markerItem, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{markerItem.name ? markerItem.name : ""}</td>
                                                                <td>{markerItem.description ? markerItem.description : ""}</td>
                                                                <td>{markerItem.start ? markerItem.start : ""}</td>
                                                                <td>{markerItem.duration ? markerItem.duration : ""}</td>
                                                                <td>{markerItem.type ? markerItem.type : ""}</td>
                                                                <td>{markerItem.time_format ? markerItem.time_format : ""}</td>
                                                            </tr>
                                                        )
                                                    }) : ''
                                                }
                                            </tbody>
                                        </table>
                                        {/* END */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                    <div className="float-right">
                        <Popup trigger=
                            {<FontAwesomeIcon
                                icon='edit'
                                className="far fa-edit" onClick={this.handleFileUploaderPreview}>
                            </FontAwesomeIcon>
                            }
                            content="Upload File"
                            position="top center" />
                    </div>
                </div>
            );
        }

        let uploadFile = (<CSVReader
            onFileLoaded={this.onCSVFileLoaded}
            onError={this.onCSVFileError}
            parserOptions={papaparseOptions}
        />
        )

        if (showfileUploader == false) {
            uploadFile = (
                <div style={{ maxWidth: '100%' }}>
                    <div className="d-none">
                        <CSVReader
                            onFileLoaded={this.onCSVFileLoaded}
                            onError={this.onCSVFileError}
                            parserOptions={papaparseOptions}
                        />
                    </div>
                    <br />
                    <br />
                </div>
            )

        }

        if (this.state.redirection === true) {
            const { redirectionPage } = this.props;
            var pageName = redirectionPage.pageName
            //return (<Redirect to={ALL_BOOK_PARENTS} ></Redirect>)
            
            return (<Redirect to={VIEW_BOOK_PARENT} ></Redirect>)
        }


        if (this.state.validateInput === true) {
            return (<Redirect to={ADD_BOOK_ACTIVITY}></Redirect>)
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="main-progress-bar">
                                                    <ol className="progress-tracker">
                                                        <li className="step active"><span className="step-name small-font">Add Book</span></li>
                                                        <li className="step"><span className="step-name small-font">Add activity</span></li>
                                                        <li className="step "><span className="step-name small-font">Add Evaluation</span></li>
                                                        <li className="step"><span className="step-name small-font">Puplish Book</span></li>
                                                    </ol>
                                                </div>
                                                <br />

                                                <form onSubmit={this.handleSubmit.bind(this)} >
                                                    <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                                                    <br />

                                                    <div className="row">
                                                        <div className="col-4">
                                                            {/* <span className="required_input">*</span> */}
                                                            <UploadImage
                                                                getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                imageUrl={this.state.imageUrl}
                                                                imagepreview={this.state.imageUrl}
                                                                s3DirectryrName='readWithJeel'
                                                            />

                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="song_name" className="label">
                                                                <span className="required_input">*</span>
                                                                Book Name</label><br />

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                fluid name="name" id="name"
                                                                onChange={this.handleChange}
                                                                value={this.state.name} />
                                                            <br />

                                                            <label htmlFor="name_in_english" className="label">
                                                                {/* <span className="required_input">*</span> */}
                                                                Book Name In English</label><br />

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                fluid name="name_in_english" id="name_in_english"
                                                                onChange={this.handleChange}
                                                                value={this.state.name_in_english} />
                                                            <br />

                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Book Description</label><br />

                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"
                                                                name="description" onChange={this.handleChange}
                                                                value={this.state.description} />
                                                            <br />

                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Book Description In English</label><br />

                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"
                                                                name="description_in_english" onChange={this.handleChange}
                                                                value={this.state.description_in_english} />
                                                            <br />

                                                            <label htmlFor="name" className="label">
                                                                Title Audio
                                                            </label>
                                                            <UploadAudio
                                                                getAudioUrlCallBack={this.getTitleAudio.bind(this)}

                                                                audioPreview={this.state.titleAudio}
                                                                audioUrl={this.state.titleAudio}
                                                                s3DirectryrName="readWithJeel/audio"
                                                            />
                                                            <br />

                                                            <label htmlFor="name" className="label">
                                                                Book Audio
                                                            </label>
                                                            <UploadAudio
                                                                getAudioUrlCallBack={this.getBookAudio.bind(this)}

                                                                audioPreview={this.state.bookAudio}
                                                                audioUrl={this.state.bookAudio}
                                                                s3DirectryrName="readWithJeel/audio"
                                                            />
                                                            <br />
                                                            <label htmlFor="name" className="label">
                                                                Audio Marker
                                                            </label>
                                                            <br />
                                                            {fileLink}
                                                            {uploadFile}


															{/*		
                                                            <br />
                                                            {selectedBookParent}
                                                            */}
                                                            <br />

                                                            <label htmlFor="episode_series_id"
                                                                className="label">
                                                                <span className="required_input">*</span>
                                                                Select payment availability</label><br />

                                                            <Dropdown required fluid selection
                                                                placeholder='Select Payment Availability'
                                                                id="episode_series_id"
                                                                onChange={this.handlePaymentAva}
                                                                options={is_free_type}
                                                                value={this.state.isFree}
                                                            />
                                                            <br />




                                                            <label htmlFor="episode_series_id"
                                                                className="label">
                                                                Sub Value</label><br />

                                                            <Dropdown required fluid search selection
                                                                placeholder='Select Sub Value'
                                                                id="episode_series_id"
                                                                onChange={this.handleSubValueChange}
                                                                options={subValuesList}
                                                                value={this.state.subValueId}
                                                            />
                                                            <br />


                                                            {/* DataComponent */}
                                                            {this.state.publishDateTime !== " " &&
                                                                this.state.publishDateTime !== '' &&
                                                                this.state.publishDateTime !== null &&
                                                                this.state.publishDateTime !== undefined ?
                                                                <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    date={this.state.publishDateTime}
                                                                    showNow={!isInvalid} />

                                                                :
                                                                <PublishDate
                                                                    getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    value={this.state.publishDateTime}
                                                                    showNow={!isInvalid} />}
                                                                <br />
                                                                <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.featured}
                                                                    onChange={this.handleCheckboxChange}
                                                                />
                                                                {' Featured'}
                                                                </label>
                                                            <br />
                                                            
<div class="row">
	<div class="col-3">
                              
							 <label htmlFor="episode_ageFrom_id" className="label" >                                
                                Select Age From
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age From"
                                id="episode_ageFrom_id"
                                onChange={this.handleAgeFromSelection}
                                options={AgeFromArr}
                              />
                              <br />                              
	</div>
	<div class="col-3">
                              
							 <label htmlFor="episode_ageTo_id" className="label" >                                
                                Select Age To
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age To"
                                id="episode_ageTo_id"
                                onChange={this.handleAgeToSelection}
                                options={AgeFromArr}
                              />
                              <br />                              
	</div>	
</div> 
                                                            
<br/>

<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                              />
                              <br />
                                                            
                              
                              
                 

                                                        </div>
                                                        <div className='col-12'>
                                                            <div className="book-pages" style={{ border: "1px solid #22242626", padding: "10px" }}>
                                                                <div className="pages-header d-flex justify-content-between align-items-center"
                                                                    style={{ borderBottom: "1px solid #22242626", marginBottom: "10px", paddingBottom: "10px" }}>
                                                                    <label htmlFor="song_name" className="label" style={{ fontWeight: "bold", textTransform: "uppercase", color: "#6f1a65" }}>
                                                                        Book Pages</label>
                                                                    {
                                                                        this.state.pages && this.state.pages.length ? '' : <button
                                                                            id="addBookPage"
                                                                            type="button"
                                                                            className="seriesButtons"
                                                                            style={{
                                                                                float: "none", margin: 0, marginInlineStart:
                                                                                    "10px"
                                                                            }}
                                                                            onClick={(e) => this.addBookPage(e)}>
                                                                            + Add Page</button>
                                                                    }

                                                                </div>
                                                                <div className='pages-list'>
                                                                    {this.bookPagesList()}
                                                                </div>
                                                                {
                                                                    this.state.pages && this.state.pages.length ? <button
                                                                        id="addBookPage"
                                                                        type="button"
                                                                        className="seriesButtons d-block"
                                                                        style={{
                                                                            float: "none", margin: 0, marginInlineStart:
                                                                                "auto"
                                                                        }}
                                                                        onClick={(e) => this.addBookPage(e)}>
                                                                        + Add Page</button> : ''
                                                                }


                                                            </div>
                                                        </div>


                                                        <br />
                                                        <div className="buttons_container">

                                                            <Button icon labelPosition='right'
                                                                id="nextButton"
                                                                disabled={this.state.error}>
                                                                Next
                                                                <Icon name='right arrow' />
                                                            </Button>

                                                            <Button
                                                                onClick={this.handleCancel}
                                                                id="episode_cancel">Cancel</Button>

                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>

                        </div>



                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

AddBook.propTypes = {
    addBook: propTypes.func.isRequired,
    addActivity: propTypes.func.isRequired,
    fetchAllSubValues: propTypes.func.isRequired,
    fetchAllBookParent: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    readWithJeel: state.readWithJeel.item,
    activities: state.activities.item,
    allSubValues: state.ContentSubValue.items,
    // radio: state.radio.items,
    redirectionPage: state.redirectionPage.item,
    bookParent: state.bookParent.items,
    ContentVendorsList : state.contentVendor.State_items,
});

export default withFirebase(
    connect(
        mapStateToProps,
        {
            addBook,
            addActivity,
            fetchAllSubValues,
            fetchAllBookParent,
            fetchAllContentVendors,
            // fetchAllRadio
        })(AddBook));
