import { FETCH_All_STORY_PARENT, GET_STORY_PARENT_DATA, LOAD_STORY_PARENTES, ERROR_LOADING_STORY_PARENTES,
    ERROR_LOADING_STORY_PARENT, LOAD_STORY_PARENT, SAVE_STORY_PARENT, ERROR_ADDING_STORY_PARENT,
    ERROR_SETTING_STATE_STORY_PARENT ,SETTING_STATE_STORY_PARENT,
    ERROR_DELETING_STORY_PARENT, DELETING_STORY_PARENT,
    ERROR_UPDATE_STORY_PARENT, UPDATE_STORY_PARENT, EMPTY_STORY_PARENT, EMPTY_STORY_PARENT_WITH_DATA,
    ADD_STORY_PARENT_WITH_SENTFROM } 
    from './Types'

    import axios, { post} from 'axios'

    export const  addStoryParentItemWithSentFrom = (item, sentFrom) => dispatch => {

console.log('in story action :'+JSON.stringify(item,null,2));

        const storyParent = {
            item: item,
            sentFrom: sentFrom
        }
        dispatch({
            type: ADD_STORY_PARENT_WITH_SENTFROM,
            payload: storyParent
        })
    }

    
export const fetchAllStoryParent = () => dispatch => {

    dispatch({
        type: LOAD_STORY_PARENTES,
        payload: 'loading'
    })

    //axios.get('/StoryParent/getAllStoryParentList')
    axios.get('/BookParent/getAllBookParent')    
        .then((res) => {
	
	//console.log('Storyyyyyyyyyyyyyyyyyyyyyyyyyyy :'+JSON.stringify(res,null,2));
            dispatch({
                type: FETCH_All_STORY_PARENT,
                payload: res.data.results
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_STORY_PARENTES,
                payload: Error.message
            })
        })
}

export const getStoryParentData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_STORY_PARENT,
                payload: 'loading'
            })
            const url = "/StoryParent/getStoryParentDetails"
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_STORY_PARENT_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_STORY_PARENT,
                    payload: Error.message
                })
            })


        })

    }
}

export const saveStoryParent = (name, description, imgURL, publish_date,
     audio,promo_with_music,promo_without_music,
     name_in_english, description_in_english,
     videoKeyPromoWithMusic, videoKeyPromoWithoutMusic, featured) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_STORY_PARENT,
                payload: 'loading'
            })

            const url = '/StoryParent/addStoryParent';
            const formData = new FormData();
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Status', "SUSPENDED");
            formData.append('Image', imgURL);
            formData.append('Publish_Date', publish_date);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promo_with_music);
            formData.append('Promo_Video_Without_Music',promo_without_music);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English',description_in_english);
            formData.append("videoKeyPromoWithMusic", videoKeyPromoWithMusic);
            formData.append("videoKeyPromoWithoutMusic", videoKeyPromoWithoutMusic);
            formData.append('featured', featured);
            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SAVE_STORY_PARENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_STORY_PARENT,
                    payload: Error.message
                })
            })
        })


    }
}

export const setStateStoryParent = (id , state) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_STORY_PARENT,
                payload: 'loading'
            })

            const url = "/StoryParent/setStoryParentStatus";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Status', state);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SETTING_STATE_STORY_PARENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STATE_STORY_PARENT,
                    payload: Error.response.data.message//Error.message
                })
            })
        })

    }
}

export const deleteStoryParent = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_STORY_PARENT,
                payload: 'loading'
            })

            const url = "/StoryParent/deleteStoryParent";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_STORY_PARENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_STORY_PARENT,
                    payload: Error.message
                })
            })
        })

    }
}

export const updateStoryParent = (id, name, description, status, imageUrl, publishDate, audio,promo_with_music, promo_without_music, name_in_english, description_in_english,
    video_key_promo_with_music, video_key_promo_without_music, featured) => {
   return (dispatch, getState, { getFirebase }) => {

       const firebase = getFirebase();
       firebase.auth().currentUser.getIdToken().then((token) => {
           dispatch({
               type: LOAD_STORY_PARENT,
               payload: 'loading'
           })

           const url = "/StoryParent/updateStoryParent";
           const formData = new FormData();
           formData.append('ID', id);
           formData.append('Name', name);
           formData.append('Description', description);
           formData.append('Image', imageUrl);
           formData.append('Status', "");
           formData.append('Publish_Date', publishDate);
           formData.append('Voice', audio);
           formData.append('Promo_Video_With_Music', promo_with_music);
           formData.append('Promo_Video_Without_Music',promo_without_music);
           formData.append('Name_In_English', name_in_english);
           formData.append('Description_In_English', description_in_english);
           formData.append('Video_Key_Promo_Video_With_Music',video_key_promo_with_music);
           formData.append('Video_Key_Promo_Video_Without_Music',video_key_promo_without_music);
           formData.append('featured', featured);
           const config = {
               headers: {
                   'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                   'X-Authorization-Firebase': token
               }
           }
           post(url, formData, config).then((res) => {
               dispatch(
                   {
                       type: UPDATE_STORY_PARENT,
                       payload: res.data
                   }
               )
           }).catch((Error) => {
               dispatch({
                   type: ERROR_UPDATE_STORY_PARENT,
                   payload: Error.message
               })
           })
       })

   }
}


export const emptyStoryParentWithStories = () => dispatch => {
    dispatch({
        type: EMPTY_STORY_PARENT_WITH_DATA,
        payload: ""
    })
}

export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_STORY_PARENT,
        payload: ""
    })
}
