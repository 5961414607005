import React from 'react';



const Pagination = ({ pageSize, length,handlePagination,currentPage,totalElements}) => {

  //console.log('pagination-tot :'+totalElements+" ,curr: "+currentPage+" ,ciel: "+Math.ceil(totalElements / pageSize));

  const paginationNumbers = [];



for (let i = 1; i <= Math.ceil(totalElements / pageSize); i++) {
    paginationNumbers.push(i);
}



return (			
		    <div className='pagination'>		
		      {paginationNumbers.map((pageNumber) => (		
				<button key={pageNumber} className={currentPage === pageNumber-1 ? 'page-active' : ''}  onClick={()=>{handlePagination(pageNumber-1)}}>{pageNumber}</button>		
		      ))}		
		    </div>
  );

};



export default Pagination;

