import React from 'react';
import { useState } from "react";
import { useEffect } from 'react';

import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import  Pagination from './Pagination';
import {getContentVendorViewItemtData} from './ContentVendorReportActions';

  
const ContentVendorViewItem = ({getContentVendorViewItemtData,vendorId,type,startDate,endDate,State_items,State_loading,State_totalElements,State_error,State_sucessfully}) => {
// ===================================================================== 
// =====================================================================	

const getLoadingStateByContentType =(contentType,State_loading)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return State_loading.State_episode_without_loading;
	else if(contentType=='EPISODE_WITH_MUSIC') return State_loading.State_episode_with_loading;
	else if(contentType=='SONG_WITHOUT_MUSIC') return State_loading.State_song_without_loading;
	else if(contentType=='SONG_WITH_MUSIC') return State_loading.State_song_with_loading;
	else if(contentType=='RADIO_CHANNEL') return State_loading.State_radio_loading;
	else if(contentType=='GAME') return State_loading.State_game_loading;
};
const getSuccessStateByContentType =(contentType,State_sucessfully)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return State_sucessfully.State_episode_without_sucessfully;
	else if(contentType=='EPISODE_WITH_MUSIC') return State_sucessfully.State_episode_with_sucessfully;
	else if(contentType=='SONG_WITHOUT_MUSIC') return State_sucessfully.State_song_without_sucessfully;
	else if(contentType=='SONG_WITH_MUSIC') return State_sucessfully.State_song_with_sucessfully;
	else if(contentType=='RADIO_CHANNEL') return State_sucessfully.State_radio_sucessfully;
	else if(contentType=='GAME') return State_sucessfully.State_game_sucessfully;
};
const getItemDataStateByContentType =(contentType,State_sucessfully)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return State_sucessfully.State_episode_without_items;
	else if(contentType=='EPISODE_WITH_MUSIC') return State_sucessfully.State_episode_with_items;
	else if(contentType=='SONG_WITHOUT_MUSIC') return State_sucessfully.State_song_without_items;
	else if(contentType=='SONG_WITH_MUSIC') return State_sucessfully.State_song_with_items;
	else if(contentType=='RADIO_CHANNEL') return State_sucessfully.State_radio_items;
	else if(contentType=='GAME') return State_sucessfully.State_game_items;
};
const getTotalElementsStateByContentType =(contentType,State_sucessfully)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return State_sucessfully.State_episode_without_totalElements;
	else if(contentType=='EPISODE_WITH_MUSIC') return State_sucessfully.State_episode_with_totalElements;
	else if(contentType=='SONG_WITHOUT_MUSIC') return State_sucessfully.State_song_without_totalElements;
	else if(contentType=='SONG_WITH_MUSIC') return State_sucessfully.State_song_with_totalElements;
	else if(contentType=='RADIO_CHANNEL') return State_sucessfully.State_radio_totalElements;
	else if(contentType=='GAME') return State_sucessfully.State_game_totalElements;
};

const getErrorStateByContentType =(contentType,State_error)=>{
	if(contentType=='EPISODE_WITHOUT_MUSIC') return State_error.State_episode_without_error;
	else if(contentType=='EPISODE_WITH_MUSIC') return State_error.State_episode_with_error;
	else if(contentType=='SONG_WITHOUT_MUSIC') return State_error.State_song_without_error;
	else if(contentType=='SONG_WITH_MUSIC') return State_error.State_song_with_error;
	else if(contentType=='RADIO_CHANNEL') return State_error.State_radio_error;
	else if(contentType=='GAME') return State_error.State_game_error;
};	
// ===================================================================== 
// =====================================================================
  let loadingState = getLoadingStateByContentType(type,State_loading);
  
  let contentVendorItemData = getItemDataStateByContentType(type,State_sucessfully);
  let success = getSuccessStateByContentType(type,State_sucessfully);
  let totalElements = getTotalElementsStateByContentType(type,State_sucessfully);
  
  //console.log('-----------------');
  //console.log('Child type: '+type+" ,totalElements: "+totalElements);
  //console.log('Child totalElements: '+totalElements);
  //console.log('Child contentVendorItemData: '+JSON.stringify(contentVendorItemData,null,2));

		const [currentPage, setCurrentPage] = useState(0); 
		const [pageSize, setPageSize] = useState(5);   
// =====================================================================
// =====================================================================
useEffect(() => {	
	//console.log('Child contType useEffect: '+type+" , start: "+startDate);
	getContentVendorViewItemtData(vendorId,startDate,endDate,type,0,pageSize);														    
}, [currentPage,startDate,endDate]); 
// ===================================================================== 
// =====================================================================
	//Pagination logic :
	  														 
	const handlePagination = (pageNumber) => {	
		//console.log('handlePagination,page :'+pageNumber);
		setCurrentPage(pageNumber);
	    getContentVendorViewItemtData(vendorId,startDate,endDate,type,pageNumber,pageSize);	
	};


// =====================================================================
// =====================================================================  

  return (
    <>
      {contentVendorItemData&&contentVendorItemData.length>0 && contentVendorItemData[0].watchesCount>0?
		<span className="general-setting-form" >
			<fieldset className='fieldset'>
				<legend className="legend">The {type}</legend>
				      <div className="card" >
						      {contentVendorItemData.filter(row=>row.watchesCount>=0)
						      .map((row, index) => (
							
												
						                            <div className="row mb-2" key={index}>
						                                <div className="col ">
						                                    <img className="img-thumbnail" src={row.img} alt="Episode" />
						                                </div>
						                                <div className="col-10">                                    
						                                    <span className="font-italic small-font ">
						                                        <span className="pr-3">Watches</span>
						                                        <span className="text-primary">{row.watchesCount}</span>
						                                        <hr />
						                                    </span>
						                                    <div> 
						                                      <span className="card-title"> {row.contentName} </span>                                        
						                                    </div>
						                                </div>
						                            </div>
												
						
						      ))}
				      </div>
	  		</fieldset>
	  		
	  														<Pagination 
																length={contentVendorItemData.length} 
																pageSize={pageSize} 
																handlePagination={handlePagination} 
																currentPage={currentPage}	
																totalElements={totalElements}														
															/>
      	</span>  
      	
      	    												
      :''}
      
    </>
  );
};

const mapStateToProps = state => ({
    State_items: state.contentVendorReportReducer.State_items,
    State_loading: state.contentVendorReportReducer.State_loading,
    State_totalElements: state.contentVendorReportReducer.State_totalElements,
    State_error: state.contentVendorReportReducer.State_error,
    State_sucessfully: state.contentVendorReportReducer.State_sucessfully,
                                              
});

	
export default connect( mapStateToProps, { getContentVendorViewItemtData } )(withRouter(ContentVendorViewItem));