import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Link } from 'react-router-dom'
import { Button, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import base64 from "base-64";
import utf8 from "utf8";
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import cardCheckLogo from '../../images/card_correct.png';
import cardLogo from '../../images/card.png';


/*Actions */
import { emptyContentVendor,editContentVendor,getContentVendorData } from '../../Actions/ContentVendorActions';
//import {saveContentVendor} from '../../Actions/AuthActions';


/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import country_list from '../ConstData/PaymentAndVendorCountriesList'
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, CONTENT_VENDORS } from '../../routes';
import { Editor } from '@tinymce/tinymce-react';
import UploadImage from "../S3Uploading/UploadImage";

const INITIAL_STATE = {

    id: '',
    name: '',
    email: '',
    password: '',
    phone: '',
    country: '',
    note: '',
    description:'',
    city: '',
    address: '',
    blogHTML: "",
    vendor_imageUrl:"",

    ////////////////// validation form
    error: false,
    errorMessage: "",
};

export class EditContentVendor extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) 
        {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else 
        {
            let id = this.props.match.params.id;
            console.log('id in edit content: '+id);
            this.props.getContentVendorData(id);
            this.setState({ id: id })

        }

    }

    componentWillReceiveProps(nextprop) 
    {
	//console.log('nextprop : '+JSON.stringify(nextprop,null,2));
        let contentVendor = nextprop.contentVendor;


        if (contentVendor.user !== undefined && contentVendor.user) 
        {
            let note = contentVendor.note;
            if (note === '' || note === 'null' || note === null || note === undefined) {
                note = ''
            }
            //let description = contentVendor.description;
            //if (description === '' || description === 'null' || description === null || description === undefined) {
            //    description = ''
            //}

			    //const blocksFromHtml = base64.decode(contentVendor.description);
			    //const blocksFromHtmlUTF8 = utf8.decode(blocksFromHtml);
			    const blocksFromHtmlUTF8 = contentVendor.description
			    this.setState({
			      blogHTML: blocksFromHtmlUTF8
			    });
           
            let city = contentVendor.city
            if (city === '' || city === 'null' || city === null || city === undefined) {
                city = ''
            }

            let address = contentVendor.address;
            if (address === '' || address === 'null' || address === null || address === undefined) {
                address = ''
            }

            this.setState(
                {
                    id: contentVendor.id,
                    name: contentVendor.user.name,
                    email: contentVendor.user.email,
                    password: contentVendor.user.password,
                    phone: contentVendor.user.phone,
                    country: contentVendor.user.country,
                    note: note,
                    //description:description,
                    city: city,
                    address: address,
                    vendor_imageUrl:contentVendor.imageUrl,

                })
        }
    }



    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });

        setTimeout(() => {
            if ((this.state.name === '' || this.state.name === null || this.state.name === undefined)
                || (this.state.phone === '' || this.state.phone === null || this.state.phone === undefined)
                || (this.state.country === '' || this.state.country === null || this.state.country === undefined)
            ) {
                this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
            }
            else if ((this.state.name !== '' && this.state.name !== null && this.state.name !== undefined)
                && (this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined)
                && (this.state.country !== '' && this.state.country !== null && this.state.country !== undefined)

            ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }, 0);
    }

    handleCountryChange = (e, { value }) => {
        this.setState({ country: value });
    };
    
    getImageUrl(imageUrl) {
    if (imageUrl) {
      this.setState({ vendor_imageUrl: imageUrl });

    }
  }

    handleSubmit = (e) => {

        const { id, name, country, phone, note,blogHTML, city, address,vendor_imageUrl } = this.state;

 var res = blogHTML;

    if (blogHTML.includes('<img') === true) {
      res = res.split('<img').join('<img style="display: block; margin-left: auto; margin-right: auto;  text-align: center;  line-height: 96px; height: 22%; color: gray; border: 1px solid rgba(34, 36, 38, .15);border-radius: .28571429rem; transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease; object-fit: scale-down"');
    }
    
    let html = res;
    
    if (blogHTML === '' || blogHTML === undefined || blogHTML === null) {

      alert("Content Vendor description can't be empty");
      this.setState({
        validateInput: false
      })
    }
    
    
        this.props.editContentVendor(id, name, country, phone, note,html, city, address,vendor_imageUrl);
        // this.closeModal();
        e.preventDefault();

    }

  handleEditorChange = (content, editor) => {
    // console.log('Content was updated:', content);
    this.setState({
      blogHTML: content
    });
  }
  
    render() {
        const { success, error, loading } = this.props;


        let buttonSubmitLoader = (
            <Button onClick={this.handleSubmit} id="nextButton" disabled={this.state.error}>
                Save
            </Button>);

        if (loading === true) {
            buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">
                    Loading
                </button>
            )
        }
        if (success === true) {
            this.props.emptyContentVendor();
            alert("Updating Content Vendor Done Successfully");
            return (<Redirect to={CONTENT_VENDORS}></Redirect>)

        }
        else if (success === false) {
            alert(error);
            this.props.emptyContentVendor();
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">

                                            {/* <!--  start status bar --> */}
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    <Link to={CONTENT_VENDORS}>Content Vendors</Link><span> {'>'} </span>

                                                </span>
                                                <span>Edit Content Vendor</span>

                                            </div>
                                            {/* <!--  end status bar --> */}

                                            <br />
                                            <div className="container">
                                                <h3>Edit Content Vendor</h3>
                                                <hr />

                                                <div className="row mx-md-n5">
                                                    <div className="col-xs-6 px-md-5 ">                                                        
                                                    </div>

                                                    <div className="col-xs-6 px-md-5">                                                        
                                                    </div>
                                                </div>

                                                <br />
                                                <div className="">
                                                    <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                                        <p style={{ color: "red" }}>{this.state.errorMessage} </p>

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                                    Name</label>
                                                                    
                                                                 
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid ="true" 
                                                            name="name" id="name" onChange={this.handleChange}
                                                            value={this.state.name} />
                                                        <br />


                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Password</label>
                                                        <input
                                                            type="text"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid ="true" 
                                                            name="password" id="password"
                                                            value={this.state.password}
                                                            disabled />
                                                        <br />

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Email</label>
                                                        <input
                                                            type="email"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid ="true" 
                                                            name="email" id="email"
                                                            value={this.state.email}
                                                            disabled />
                                                        <br />


                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Phone</label>
                                                        <input
                                                            type="number"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid ="true" 
                                                            name="phone" id="email"
                                                            onChange={this.handleChange}
                                                            value={this.state.phone}
                                                            required />
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                                Select Country </label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Country'
                                                            id="episode_series_id"
                                                            onChange={this.handleCountryChange}
                                                            options={country_list}
                                                            value={this.state.country}
                                                        />
                                                        <br />

                                                        <label htmlFor="city" className="label">
                                                            City</label>
                                                        <input
                                                            type="text"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid ="true" 
                                                            name="city" id="city"
                                                            onChange={this.handleChange}
                                                            value={this.state.city}
                                                        />
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="address" className="label">
                                                                Address
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid ="true" 
                                                                rows="3"
                                                                className="form-control"
                                                                name="address"
                                                                id="address"
                                                                onChange={this.handleChange}
                                                                value={this.state.address} />
                                                        </div>
                                                        <br />
                                                        
                                                        <div className="form-group">
                                                            <label htmlFor="note" className="label">
                                                                Note
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid ="true"
                                                                rows="3"
                                                                className="form-control"
                                                                name="note"
                                                                id="note"
                                                                onChange={this.handleChange}
                                                                value={this.state.note} />
                                                        </div>
                                                        
														<div className="form-group">
                                                        	<label htmlFor="note" className="label">
                                                               Img URL
                                                            </label>
                                                            
							                              {/* <span className="required_input">*</span> */}
							                              <UploadImage
							                                getImageURLCallBack={this.getImageUrl.bind(
							                                  this
							                                )}
							                                imageUrl={this.state.vendor_imageUrl}
							                                imagepreview={this.state.vendor_imageUrl}
							                                s3DirectryrName="contentVendor"
							                              />
							                            </div>
							                                                                                    
                                                      {/*  
                                                        <div className="form-group">
                                                            <label htmlFor="description" className="label">
                                                                description
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="description"
                                                                id="description"
                                                                onChange={this.handleChange}
                                                                value={this.state.description} />
                                                        </div>  
                                                        */}                                                      
							<div>
							<Editor
							  apiKey='cxop87zxs4fbrhoromln8hz0gn4m9crk0obq2do43cee4fnw'
                              onInit={(evt, editor) => {

                                const body = editor.dom.select('body')[0];
                                editor.dom.add(
                                  body,
                                  'script',
                                  {
                                    src: "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js",
                                    type: 'text/javascript'
                                  }
                                );

                                editor.dom.add(
                                  body,
                                  'script',
                                  {
                                    src: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js",
                                    type: 'text/javascript'
                                  }
                                );
                              }}
                              initialValue={this.state.blogHTML}
                              onBlur={newValue => {
                                this.setState({ blogHTML: newValue.target.getContent() })
                              }}
                              // onEditorChange={newValue => {
                              //   this.setState({ blogHTML: newValue.target.innerHTML })
                              // }}
                              init={{

                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste"
                                ],
                                toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                                content_css: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css, https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css",
                                valid_children: '+body[style]',
                                valid_elements: '*[*]',
                                inline_styles: true,
                                extended_valid_elements: 'i[class]',
                                width: '100%'

                              }}
                            />
                          </div>
                          

                                                    </form>

                                                </div>


                                                <div className="input-field">

                                                    {buttonSubmitLoader}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}


EditContentVendor.propTypes = {
    editContentVendor: propTypes.func.isRequired,
    getContentVendorData: propTypes.func.isRequired,
}
                               
const mapStateToProps = state => ({
    auth: state.firebase.auth,

    contentVendor: state.contentVendor.State_item,

    loading: state.contentVendor.State_loading,
    success: state.contentVendor.State_Content_Vendor_Update_sucessfully,
    error: state.contentVendor.State_Content_Vendor_Update_Error
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                getContentVendorData,
                editContentVendor,
                emptyContentVendor
            }
        )
        (EditContentVendor)
    );
