import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import { Popup } from 'semantic-ui-react'

/*Components */
import Loader from '../Layout/Loader';
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
import { CONTENT_GET_VENDOR_TO_EDIT,CONTENT_EDIT_VENDOR,CONTENT_VENDOR_GET_VIEWS_REPORT } from '../../routes';

/**Actions */
import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'


class ContentVendorTabel extends Component {

    constructor(props) {
        super(props);

        this.props.fetchAllContentVendors();
    }

    componentDidMount() {
        this.props.fetchAllContentVendors();
    }

    render() {

        const { contentVendors } = this.props;

console.log('contentVendor in render in tbl, Json is: '+JSON.stringify(this.props,null,2));


        const columns = [

            {
                Header: "ID",
                accessor: "id",
                width: 50,
                MinWidth: 50,
                Maxwidth: 130,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (

                        <div style={{ "textAlign": "center" }}>
                            <Link className="link" to={{ pathname: CONTENT_GET_VENDOR_TO_EDIT + "/" + props.original.id }}>
                                {props.original.id}
                            </Link>
                        </div>
                    )
                }

            },
            {
                Header: "Name",
                accessor: "name",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (

                        <div style={{ "textAlign": "center" }}>
                            <Link className="link" to={{ pathname: CONTENT_GET_VENDOR_TO_EDIT + "/" + props.original.id }}>
                                {props.original.user.name}
                            </Link>
                        </div>
                    )
                }

            },
            {
                Header: "Email",
                accessor: "user.email",
                width: 180,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.user.email}
                        </div>
                    )
                }

            },
            {
                Header: "Phone",
                accessor: "user.phone",
                width: 130,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.user.phone}
                        </div>
                    )
                }

            },
            
            {
                Header: "Country",
                accessor: "user.country",
                width: 140,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.user.country}
                        </div>
                    )
                }

            },
            {
                Header: "City",
                accessor: "city",
                width: 140,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.city}
                        </div>
                    )
                }

            },

            {
                Header: "Actions",
                width: 100,
                filterable: false,
                headerClassName: 'table-header',
                resizable: false,
                //foldable: true,
                Cell: props => {
                    return (
                        <div style={{ textAlign: "center" }}>

                            <Link className="link" to={{ pathname: CONTENT_GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                <Popup trigger={
                                    <a className="user-lock" onClick={this.open}>
                                        <i>  <FontAwesomeIcon icon="id-card" />
                                        </i>
                                    </a>}
                                    content='View Content Vendor'
                                    position='top center'
                                />
                            </Link>

							<Link className="link" to={{ pathname: CONTENT_VENDOR_GET_VIEWS_REPORT, state: {vendorId:props.original.id} }}>

                                <Popup trigger={
                                    <a className="user-lock" onClick={this.open}>
                                        <i>  <FontAwesomeIcon icon="id-card" />
                                        </i>
                                    </a>}
                                    content='View Content Vendor Views Report'
                                    position='top center'
                                />
                            </Link>
                        </div>
                    )
                }
            }
        ]


        const { error, loading, success } = this.props;

        let vendorList = null

        if (loading === true) {
            vendorList = (<Loader />)
        }
        else if (success === true && error === '') 
        {

            return (
                <div>
                    <ReactTable

                       className=" table-striped custm-table"
                        columns={columns}
                        data={contentVendors}
                        filterable
                        defaultPageSize={10}
                    //    className="-striped -highlight"
                    //defaultPageSize={5}
                    >

                    </ReactTable>
                </div>)
        }


        return (
            <div>

                <div className="mt-4 responsive-table">
                    {vendorList}
                </div>
            </div>
        )
    }
}


ContentVendorTabel.propTypes = {
    fetchAllContentVendors: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    contentVendors: state.contentVendor.State_items,
    loading: state.contentVendor.State_loading,
    error: state.contentVendor.State_Content_Vendor_Load_Error,
    success: state.contentVendor.State_Content_Vendor_Load_sucessfully,
    userPaymentProfile: state.userPaymentProfile.item

});

export default withRouter
    (connect
        (mapStateToProps,
            { fetchAllContentVendors })
        (ContentVendorTabel)
    );
