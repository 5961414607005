import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
/*Headers */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
import { clearQuestions } from '../../Actions/QuestionActions'
// import { publishChannel, emptyChannel } from '../../Actions/RadioChannelAction';
import { publishBook, emptyBook } from '../../Actions/ReadWithJeelAction';
import { publishQuestion, assignCorrrectAnswerToQuestion } from '../../Actions/QuestionActions';
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*css*/
import '../../css/AppCss/Episode/PublishEpisode.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../node_modules/video-react/dist/video-react.css';
/*Other components */
import QuestionPreview from '../Layout/QuestionPreview';
import AudioPlayer from "react-h5-audio-player";
import { ALL_BOOK, GET_BOOK_EVAL, VIEW_BOOK_PARENT,
    ALL_BOOK_PARENTS } from '../../routes';
import {
    getBookParentData,
} from '../../Actions/BookParentAction';
function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class PublishBook extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        sucess: '',
        parentID: ''
    }
    
    componentDidMount = () => {
	
	//console.log('props in publish : '+JSON.stringify(this.props,null,2));
	
        const { readWithJeel } = this.props;
        this.setState({
            parentID: readWithJeel.selected_bookParent_id
        })
        //console.log('this.state', this.state)
        //console.log('readWithJeel.selected_bookParent_id', readWithJeel.selected_bookParent_id)
    }

    publishBook = (e) => {
        const { readWithJeel } = this.props;
        const { redirectionPage } = this.props;
        this.props.addSeriesIDtoRedirectionPage(redirectionPage.pageName, readWithJeel.bookParent);
        const { activity } = this.props;
        this.props.publishBook(activity.name);
    }

    render() {
        const { readWithJeel } = this.props;
        const { questions } = this.props;

        const { bookrror, booksucess, loading } = this.props;
        const { activityerror } = this.props
        const { activitysucess } = this.props

        let buttonName = readWithJeel.bookbutton;
        if (readWithJeel.bookbutton === "now") {
            buttonName = "publish"
        }
        else if (readWithJeel.bookbutton === "schedule") {
            buttonName = "save"
        }

        if (activitysucess === false) {
            alert(activityerror)
            this.props.emptyBook();
            this.props.clearQuestions();
            this.props.emptyEvaluationAll();
        }
        else if (activitysucess === true) {
            if (booksucess === false) {
                alert("error missing data or network " + bookrror)
                this.props.emptyBook();
                this.props.clearQuestions();
                this.props.emptyEvaluationAll();

            }

            else if (booksucess === true) {
                alert("Book added Successfully")
                this.props.emptyBook();
                this.props.clearQuestions();
                this.props.emptyEvaluationAll();

                const { redirectionPage } = this.props;
                if(this.state.parentID) {
                    this.props.addSeriesIDtoRedirectionPage(VIEW_BOOK_PARENT, this.state.parentID);
                    this.props.getBookParentData(this.state.parentID)
                    return (<Redirect to={VIEW_BOOK_PARENT} ></Redirect>)
                } else {
                   // return (<Redirect to={ALL_BOOK_PARENTS} ></Redirect>)
                   
                   return (<Redirect to={VIEW_BOOK_PARENT} ></Redirect>)
                }
                
                // console.log('readWithJeel', readWithJeel)
                // return (<Redirect to={ALL_BOOK_PARENTS} ></Redirect>)
            }
        }

        let questionsList = null;
        if (questions !== undefined) {
            questionsList = (
                <QuestionPreview state='publish'
                    questions={questions}
                />)
        }

        let pagesList = null;
        if (readWithJeel && readWithJeel.pages !== undefined) {
            pagesList = readWithJeel.pages.length ? readWithJeel.pages.map((page, index) => {
                return(
                <div key={index} className='viewPagesList w-100 mt-2'>
                    <div className='row pb-2' style={{borderBottom: "1px solid #eee"}}>
                        <div className='col col-sm-3'>
                            <div className='pageImage'>
                                {
                                    page.image ? <img style={{maxWidth: "100%"}} src={page.image} /> : 'No Image'
                                }
                                
                            </div>
                        </div>
                        <div className='col col-sm-9'>
                            <div className='pageDescription'>
                                {page.text}
                            </div>
                        </div>
                    </div>
                </div>
                )

            }) : <div className='text-center'>No Pages</div>
            
        }

        // loading button
        let $buttonSubmitLoader = (
            <Button id="episode_publish"
                onClick={this.publishBook}>
                {buttonName}
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <Button id="episode_publish"
                onClick={this.publishBook}>
                {buttonName}
            </Button>);
            // <button id="episode_publish"
            //     class="ui loading button">Loading</button>);
            // console.log("loading");
        }

        let titleAudio = ''
        if ((readWithJeel.titleAudio )
        ) {
            titleAudio = (
                <AudioPlayer
                    src={readWithJeel.titleAudio}
                    onPlay={e => console.log("onPlay")}
                />)
        }
        else {
            titleAudio = (
                <td fluid className="episode_label"> No Title Audio</td>
            )
        }



        let bookAudio = ''
        if ((readWithJeel.bookAudio )
        ) {
            bookAudio = (
                <AudioPlayer
                    src={readWithJeel.bookAudio}
                    onPlay={e => console.log("onPlay")}
                />)
        }
        else {
            bookAudio = (
                <td fluid className="episode_label"> No Book Audio</td>
            )
        }
        
        let audioMarker = ''
        if ((readWithJeel.audioMarker )
        ) {
            audioMarker = <a href={readWithJeel.audioMarker} download style={{wordBreak: "break-all"}}>View Audio Marker</a>
        }
        else {
            audioMarker = (
                <td fluid className="episode_label"> No Audio Marker</td>
            )
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">ِ
                                                            Add Book</span>
                                                    </li>
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">
                                                            Add activity</span>
                                                    </li>
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">
                                                            Add Evaluation</span>
                                                    </li>
                                                    <li className="step active">
                                                        <span className="step-name small-font">
                                                            Puplish Book</span>
                                                    </li>
                                                </ol>
                                            </div>
                                            <br />
                                            <div className="container">
                                                <label htmlFor="" >{readWithJeel.name}</label>
                                                
                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img 
                                                         src={readWithJeel.image}
                                                          alt="" 
                                                          className="rounded image_Preview" />
                                                    </div>

                                                    <div className="col">
                                                        <table style={{width: "100%"}}>
                                                            <tr>
                                                                <th className="episode_label"> Book Name </th>
                                                                <td fluid className="episode_label"> {readWithJeel.name} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Book Name In English </th>
                                                                <td fluid className="episode_label"> {readWithJeel.nameInEnglish} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Selected Book Parent </th>
                                                                <td fluid className="episode_label"> {readWithJeel.selected_bookParent_name} </td>
                                                            </tr>

                                                            {/* <tr>
                                                                <th className="episode_label"> Selected Radio  </th>
                                                                <td fluid className="episode_label"> {radioChannel.selected_radio_name} </td>
                                                            </tr> */}
                                                            <tr>
                                                                <th className="episode_label"> Number Of Pages </th>
                                                                <td fluid className="episode_label">{readWithJeel.pages && readWithJeel.pages.length ? readWithJeel.pages.length : 0} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Number Of Questions </th>
                                                                <td fluid className="episode_label">{questions.length} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">{GMTtolocalTime(readWithJeel.PublishDate)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {readWithJeel.isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Title Audio </th>
                                                                {titleAudio}
                                                            </tr>
                                                            
                                                            <tr>
                                                                <th className="episode_label"> Book Audio </th>
                                                                {bookAudio}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Audio Marker </th>
                                                                {audioMarker}
                                                            </tr>

                                                        </table>
                                                    </div>


                                                </div>
                                                <br />
                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Questions</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionsList}




                                                </div>
                                                <br />
                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Pages</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {pagesList}
                                                </div>
                                                <br />
                                                {$buttonSubmitLoader}
                                                <Link to={GET_BOOK_EVAL}>
                                                    <Button icon labelPosition='left' id="episode_backButton" >
                                                        back
                                                            <Icon name='left arrow' />
                                                    </Button>
                                                </Link>
                                                <br /> <br /> <br />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

PublishBook.propTypes = {
    publishBook: propTypes.func.isRequired,
    publishActivity: propTypes.func.isRequired,
    publishQuestion: propTypes.func.isRequired,
    assignCorrrectAnswerToQuestion: propTypes.func.isRequired,
    getBookParentData: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.readWithJeel.loading,
    readWithJeel: state.readWithJeel.item,
    bookrror: state.readWithJeel.error_adding_book,
    booksucess: state.readWithJeel.book_added_successfully,

    activity: state.activities.item,
    questions: state.questions.items,
    question: state.questions.item,

    activityerror: state.activities.error_adding_activity,
    activitysucess: state.activities.activity_added_successfully,
    redirectionPage: state.redirectionPage.item

});


export default
    connect(
        mapStateToProps,
        {
            publishBook,
            publishQuestion,
            assignCorrrectAnswerToQuestion,
            emptyBook,
            clearQuestions,
            emptyEvaluationAll,
            addSeriesIDtoRedirectionPage,
            getBookParentData
        }
    )
        (PublishBook)
