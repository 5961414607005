import React, { Component } from 'react'
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import '../../../node_modules/video-react/dist/video-react.css';
import Player from 'video-react/lib/components/Player';
import Footer from '../Layout/Footer';
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import {
    getStoryData,
    saveStoryPuzzle,
    saveStoryPaintingGame,
    emptyStory
} from '../../Actions/StoryActions';
import { addDragDropParentIDtoRedirectionPage, addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

import QuestionPreview from '../Layout/QuestionPreview';
import GamePreview from '../Layout/GamePreview';
import PaintingPreview from '../Layout/PaintingPreview';
import { Button } from 'semantic-ui-react';
import AudioPlayer from "react-h5-audio-player";
import UploadImage from '../S3Uploading/UploadImage';
/* Routes */
import {
    SIGN_IN,
    STORY_TO_EDIT,
    GET_STORY_ACTIVITY_TO_EDIT,
    STORY_TO_GET,
    STORY_DETAIL
} from '../../routes';

import EvaluationPreview from '../Evaluation/EvaluationPreview';
import DragDropPreview from '../Layout/DragDropPreview'
import { STORY_FOLDER_NAME } from '../../portal/src/constants.js';
import RenderVideoWithMusic from '../VideoPreview/RenderVideoWithMusic.js';
import RenderVideoWithoutMusic from '../VideoPreview/RenderVideoWithoutMusic.js';



const INITIAL_STATE = {
    storyParentId: '',
    storyParentName: '',

    Icon_Image: "",
    Painting_Image: ""
}


function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class StoryDetails extends Component {


    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.props.emptyStory();
    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            let storyParentName = this.props.match.params.storyParentName;
            let storyParentId = this.props.match.params.storyParentId;
            let storyId = this.props.match.params.storyId;

            this.setState({
                storyParentName: storyParentName,
                storyParentId: storyParentId
            });

            this.props.getStoryData(storyId);

            var url = STORY_TO_GET + "/"
                + storyParentName + "/"
                + storyParentId + "/"
                + storyId;

            this.props.addDragDropParentIDtoRedirectionPage(url, storyParentId);
            //this.props.emptyAllBooks()
            this.props.addSeriesIDtoRedirectionPage(STORY_DETAIL, storyParentId);


        }
    }

    handleChangeVideoWithMusic = (uploadInfo, videoKey) => {
        // console.log("Received uploadInfo in parent:", uploadInfo);
        // if (
        //   uploadInfo &&
        //   uploadInfo !== undefined &&
        //   uploadInfo !== null &&
        //   Object.keys(uploadInfo).length > 0
        // ) {

        // } 
    };
    handleChangeVideoWithoutMusic = (uploadInfo, videoKey) => {
        // console.log("Received uploadInfo in parent:", uploadInfo);
        // if (
        //   uploadInfo &&
        //   uploadInfo !== undefined &&
        //   uploadInfo !== null &&
        //   Object.keys(uploadInfo).length > 0
        // ) {

        // } 
    };
    getIconImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ Icon_Image: imageUrl });

            saveStoryPuzzle(this.props.match.params.storyId, imageUrl)
                .then(res => {
                    alert("Puzzle Game Item Added Successfully");
                    let id = this.props.match.params.storyId;
                    this.props.getStoryData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        setTimeout(() => {
            this.setState({ Icon_Image: "" });
        }, 10);
    }

    getPaintingImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ Painting_Image: imageUrl });

            saveStoryPaintingGame(this.props.match.params.storyId, imageUrl)
                .then(res => {
                    alert("Painting Game Item Added Successfully");
                    let id = this.props.match.params.storyId;
                    this.props.getStoryData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        setTimeout(() => {
            this.setState({ Painting_Image: "" });
        }, 10);
    }






    render() {

        let storyParentName = this.props.match.params.storyParentName;
        let storyParentId = this.props.match.params.storyParentId;
        let storyId = this.props.match.params.storyId;


        const { story } = this.props
        const { activity } = this.props
        const { puzzles } = this.props.story
        const { paintingGames } = this.props.story
        const { dragDrops } = this.props.story

        let questionList = null, editActivityLink = null;
        let evaluationPreview = null;

        if (activity !== undefined) {
            questionList = (<QuestionPreview state='view' questions={activity.questions} />)
            evaluationPreview = (<EvaluationPreview evaluationCategory={activity.evaluationCategory} />);


            if (activity.id !== undefined) {
                editActivityLink =
                    (
						<>
						
                        <Link
                            to={{
                                pathname: GET_STORY_ACTIVITY_TO_EDIT
                                    + "/" + activity.id
                                    + "/" + storyParentName + "/" + storyParentId
                                    + "/" + storyId
                            }}>

                            <Button id="episode_view">Edit</Button>

                        </Link>
                        </>
                        );
            }
        }

        let subvalue = "No Sub Value"
        if (story.contentSubValue !== null
            && story.contentSubValue !== undefined) {

            if (story.contentSubValue.contentValue !== null
                &&
                story.contentSubValue.contentValue !== undefined) {
                subvalue = story.contentSubValue.contentValue;
            }
        }


        let gameList = null;

        if (puzzles !== undefined) {
            gameList = (<GamePreview state='viewStory' id={this.props.match.params.id} games={puzzles} />)
        }


        let gamePaintingList = null;
        if (paintingGames !== undefined) {
            gamePaintingList = (<PaintingPreview state='viewStory' id={this.props.match.params.id} games={paintingGames} />)
        }

        let story_drag_Drop = null;
        if (dragDrops !== undefined) {
            story_drag_Drop = (<DragDropPreview
                games={dragDrops}
                state="viewStory"
                parentId={storyId} />);
        }

        let storyDescription = '';
        if (story.description !== null &&
            story.description !== undefined &&
            story.description !== '') {
            storyDescription = story.description
        }
        else {
            storyDescription = "No description"
        }


        let VideoPreviewWithMusic = null;
        if (story.videoWithMusicId !== undefined
            && story.videoWithMusicId !== null) {
            if (story.videoWithMusicId.url !== undefined
                && story.videoWithMusicId.url !== null) {
                VideoPreviewWithMusic = (<Player playsInline fluid src={story.videoWithMusicId.url} />);
            } else {
                VideoPreviewWithMusic = (<div className="previewText">No Video for Preview</div>);
            }
        }

        let VideoPreviewWithoutMusic = null;
        if (story.videoWithoutMusicId !== undefined
            && story.videoWithoutMusicId !== null) {
            if (story.videoWithoutMusicId.url !== undefined
                && story.videoWithoutMusicId.url !== null) {
                VideoPreviewWithoutMusic = (<Player playsInline fluid src={story.videoWithoutMusicId.url} />);
            } else {
                VideoPreviewWithoutMusic = (<div className="previewText">No Video for Preview</div>);
            }

        }

        let isFree = ''
        if (story.isFree == 0) {
            isFree = 'paid'
        }
        else if (story.isFree == 1) {
            isFree = 'free'
        }

        let audio = ''
        console.log(story.voice)
        if (story.voice !== undefined &&
            story.voice !== null &&
            story.voice !== "undefined"
            && story.voice !== "null"
            && story.voice !== 'null') {
            audio = (<AudioPlayer
                src={story.voice}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            audio = (
                <td fluid className="episode_label"> No Audio</td>
            )
        }

        let featured = false;
        if (story.featured !== null && story.featured !== undefined) {
            featured = story.featured;
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">

                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">

                                                <h5 className="main-title mt-2 font-weight-bold">{story.name}</h5>
                                                <div className="row">
                                                    <img className="rounded image_Preview" src={story.iconImage} alt="" />



                                                    {/* <div class="col-md-8 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label class="font-weight-bold m-1 p-0">Story Name</label>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p class="m-0 p-0">Story Name</p>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label class="font-weight-bold m-1 p-0">question Type</label>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p class="m-0 p-0">Multiple Choice (Text)</p>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label class="font-weight-bold m-1 p-0">Schedule</label>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p class="m-0 p-0">11/2/2019, 02:00 am</p>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label class="font-weight-bold m-1 p-0">Description</label>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p class="m-0 p-0">
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer tooka a galley of type and scrambled it to make a type specimen book.
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div> */}
                                                    <div className="col">
                                                        <table >
                                                            <tr>
                                                                <th className="episode_label"> Story Name </th>
                                                                <td fluid className="episode_label"> {story.name}  </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Story Name In English </th>
                                                                <td fluid className="episode_label"> {story.nameInEnglish}  </td>
                                                            </tr>


                                                            <tr>
                                                                <th className="episode_label"> Selected Story Parent </th>
                                                                <td fluid className="episode_label"> {this.state.storyParentName} </td>
                                                            </tr>


                                                            <tr>
                                                                <th className="episode_label"> Description </th>
                                                                <td fluid className="episode_label"> {storyDescription}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Description In English </th>
                                                                <td fluid className="episode_label"> {story.descriptionInEnglish}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Sub Value </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {subvalue}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">{GMTtolocalTime(story.publishDate)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Audio </th>
                                                                {audio}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Featured </th>
                                                                {featured ? "True" : "False"}
                                                            </tr>
                                                        </table>
                                                    </div>


                                                </div>
                                                <br /> <br />


                                                {story.videoWithMusicId && (
                                                    <div>
                                                        {console.log(story.videoWithMusicId.vdocipherId)}
                                                        <label className="label">Video with music</label>
                                                        <section className="video_section">
                                                            <RenderVideoWithMusic
                                                                videoKey={story.videoWithMusicId.vdocipherId}
                                                                id="VideoWithMusic"
                                                                foldername={STORY_FOLDER_NAME}
                                                                changedid={this.handleChangeVideoWithMusic}
                                                                enableEdit={false}
                                                            ></RenderVideoWithMusic>
                                                        </section>

                                                        <br />
                                                    </div>
                                                )}
                                                {story.videoWithoutMusicId && (
                                                    <div>
                                                        {console.log(story.videoWithoutMusicId.vdocipherId)}
                                                        <label className="label">Video with music</label>
                                                        <section className="video_section">
                                                            <RenderVideoWithoutMusic
                                                                videoKey={story.videoWithoutMusicId.vdocipherId}
                                                                id="VideoWithoutMusic"
                                                                foldername={STORY_FOLDER_NAME}
                                                                changedid={this.handleChangeVideoWithoutMusic}
                                                                enableEdit={false}
                                                            ></RenderVideoWithoutMusic>
                                                        </section>

                                                        <br />
                                                    </div>
                                                )}

                                                {/* {story.videoWithMusicId &&
            story.videoWithMusicId.url !== null && story.videoWithMusicId.url !== ""  && <div>
 <label className="label">Video with music</label>

<section className="video_section" >
    <div>
        {VideoPreviewWithMusic}
    </div>
</section>
<br />
            </div>}

            {story.videoWithoutMusicId &&
            story.videoWithoutMusicId.url !== null && story.videoWithoutMusicId.url !== ""  && <div>
    <label className="label">Video without music</label>

<section className="video_section" >
    <div>
        {VideoPreviewWithoutMusic}
    </div>
</section>
            </div>} */}



                                                <div className="groups-btn mt-5">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="float-right rest-floating">
                                                                {/*<Link className="link" to={STORY_TO_EDIT + "/" + this.state.storyParentName + "/" + this.state.storyParentId + "/" + story.id}>
                                                                    <Button id="episode_view2">Edit222222</Button>
                                                                </Link>*/}
                                                                
                                                                <Link to={{pathname:STORY_TO_EDIT,state: {bookParentId:storyParentId,storyIdFromDetails:storyId}}}> 
                        											<Button id="episode_view">Edit</Button>  
                        										</Link>
                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Question</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionList}


                                                </div>
                                                <br />

                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Evaluation</label>
                                                    <hr id="shorthr" />
                                                    <br />
                                                    {evaluationPreview}

                                                </div>

                                                <br />
                                                <div class="groups-btn mt-5">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="float-right rest-floating">
                                                                {editActivityLink}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="mt-5 mb-4">
                                                    <div className="hr">
                                                        <span className="hr-title font-weight-bold">Puzzle Game</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {gameList}
                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getIconImageUrl.bind(this)}
                                                            imageUrl={this.state.Icon_Image}
                                                            imagepreview={this.state.Icon_Image}
                                                            s3DirectryrName='story/puzzlegame' />
                                                    </div>
                                                </div>



                                                <div className="mt-5 mb-4">
                                                    <div className="hr">
                                                        <span className="hr-title font-weight-bold">Painting Game</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {gamePaintingList}
                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getPaintingImageUrl.bind(this)}
                                                            imageUrl={this.state.Painting_Image}
                                                            imagepreview={this.state.Painting_Image}
                                                            s3DirectryrName='story/paintinggame' />
                                                    </div>
                                                </div>

                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Drag Drop Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {story_drag_Drop}

                                                </div>




                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}
const mapStateToProps = state =>
({
    story: state.stories.item,
    activity: state.stories.item.activityId,
    storyVideos: state.stories.item.videosURL,
    auth: state.firebase.auth
})
export default
    connect(
        mapStateToProps,
        {
            getStoryData,
            emptyStory,
            addDragDropParentIDtoRedirectionPage,
            addSeriesIDtoRedirectionPage,
            
        })
        (StoryDetails);
