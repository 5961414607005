import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import { Popup } from 'semantic-ui-react'

/*Components */
import Loader from '../Layout/Loader';
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
//import { CONTENT_GET_VENDOR_TO_EDIT } from '../../routes';

import { Axios } from 'axios';

/**Actions */

const ProfitRevenuPerCurrencyTabel = ({profitShareList,dataFetched,columns,children,childrenAbove,tblWidth,pageSize=5})=>{ 
        
return ( 

												<div className="container">
                                                    <span className="general-setting-form" >
                                                        <fieldset className='fieldset'>
                                                            <legend className="legend">Revenue Per Currency</legend>
                                                            
                                                            	{childrenAbove?childrenAbove:''}
                                                            
																				<div className="card mt-3 p-0">
																			        <div className="card-body p-4">
									    												<div className="row">       	
									    													<div className="" style={{'width':tblWidth+'px'}}>										    
																						            {/* <!-- start form --> */}
																						            <form className="alluserMng" onSubmit={(e)=> e.preventDefault()}>
																						                <div className="mt-4 responsive-table">
																						                            {/* Admins Table */}                    
																													<ReactTable																	
																										                columns={columns}
																										                data={profitShareList}
																										                defaultPageSize={pageSize}
																										                className="-striped -highlight"
																										                showPagination={false}
																										            >
																										
																										            </ReactTable>				                                
																						                </div>
																						            </form>										        
																		    				</div>
																		    				<div className="col-4"></div>
										   
									    												</div>
																					</div>
																			    </div>  
																			    
																			     {children}
														</fieldset>
                                                    </span>
                                                </div>
                                                
 );			
		
}



export default ProfitRevenuPerCurrencyTabel ;