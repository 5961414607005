import {
	    
GAME_FETCH_ERROR,
RADIO_FETCH_ERROR,
SONG_WITHOUT_FETCH_ERROR,
SONG_WITH_FETCH_ERROR,
EPISODE_WITHOUT_FETCH_ERROR,
EPISODE_WITH_FETCH_ERROR,

EPISODE_WITH_FETCH_SUCCESSFULLY,
EPISODE_WITHOUT_FETCH_SUCCESSFULLY,
SONG_WITH_FETCH_SUCCESSFULLY,
SONG_WITHOUT_FETCH_SUCCESSFULLY,
RADIO_FETCH_SUCCESSFULLY,
GAME_FETCH_SUCCESSFULLY,

EPISODE_WITH_LOAD,
EPISODE_WITHOUT_LOAD,
SONG_WITH_LOAD,
SONG_WITHOUT_LOAD,
RADIO_LOAD,
GAME_LOAD,

EXPORT_CONTENT_VENDOR_REQUEST,
EXPORT_CONTENT_VENDOR_SUCCESS,
EXPORT_CONTENT_VENDOR_FAIL

} from '../Actions/Types'

const initialState = {
	
	//State_items: {State_episode_with_items: [],State_episode_without_items: [],State_song_with_items: [],State_song_without_items: [],State_radio_items: [],State_game_items: [],},    
    State_loading: {State_episode_with_loading: '',State_episode_without_loading: '',State_song_with_loading: '',State_song_without_loading: '',State_radio_loading: '',State_game_loading: '',},
    //State_totalElements: {State_episode_with_totalElements: '0',State_episode_without_totalElements: '0',State_song_with_totalElements: '0',State_song_without_totalElements: '0',State_radio_totalElements: '0',State_game_totalElements: '0',},        
    State_sucessfully: {State_episode_with_sucessfully: '',State_episode_without_sucessfully: '',State_song_with_sucessfully: '',State_song_without_sucessfully: '',State_radio_sucessfully: '',State_game_sucessfully: '',},
    State_error: {State_episode_with_error: '',State_episode_without_error: '',State_song_with_error: '',State_song_without_error: '',State_radio_error: '',State_game_error: '',},
exporting:'',
exportError:'',	
}

export default function (state = initialState, action) {
    switch (action.type) {
        
        case EPISODE_WITH_LOAD:
            return {
                ...state,
                State_loading: {State_episode_with_loading: true},
            }
        case EPISODE_WITHOUT_LOAD:
            return {
                ...state,
                State_loading: {State_episode_without_loading: true},
                //State_episode_without_loading: true
            }
        case SONG_WITH_LOAD:
            return {
                ...state,
                State_loading: {State_song_with_loading: true},
                //State_song_with_loading: true
            }
        case SONG_WITHOUT_LOAD:
            return {
                ...state,
                State_loading: {State_song_without_loading: true},
                //State_song_without_loading: true
            }
        case RADIO_LOAD:
            return {
                ...state,
                State_loading: {State_radio_loading: true},
                //State_radio_loading: true
            }
		case GAME_LOAD:
            return {
                ...state,
                State_loading: {State_game_loading: true},
                //State_game_loading: true
            }
// =========================================================================================================================    
// =========================================================================================================================
// =========================================================================================================================
        case EPISODE_WITH_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_sucessfully: {
				...state.State_sucessfully,
                State_episode_with_items: action.payload,
                State_episode_with_sucessfully: true,
                State_episode_with_error: '',
                State_episode_with_loading: false,
                State_episode_with_totalElements: action.totalElements
                }
            }
        case EPISODE_WITHOUT_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_sucessfully: {
				...state.State_sucessfully,
                State_episode_without_items: action.payload,
                State_episode_without_sucessfully: true,
                State_episode_without_error: '',
                State_episode_without_loading: false,
                State_episode_without_totalElements: action.totalElements
                }
            }
        case SONG_WITH_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_sucessfully: {
				...state.State_sucessfully,	
                State_song_with_items: action.payload,
                State_song_with_sucessfully: true,
                State_song_with_error: '',
                State_song_with_loading: false,
                State_song_with_totalElements: action.totalElements
                }
            }
        case SONG_WITHOUT_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_sucessfully: {
				...state.State_sucessfully,	
                State_song_without_items: action.payload,
                State_song_without_sucessfully: true,
                State_song_without_error: '',
                State_song_without_loading: false,
                State_song_without_totalElements: action.totalElements
                }
            }
        case RADIO_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_sucessfully: {
				...state.State_sucessfully,	
                State_radio_items: action.payload,
                State_radio_sucessfully: true,
                State_radio_error: '',
                State_radio_loading: false,
                State_radio_totalElements: action.totalElements
                }
            }
        case GAME_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_sucessfully: {
				...state.State_sucessfully,	
                State_game_items: action.payload,
                State_game_sucessfully: true,
                State_game_error: '',
                State_game_loading: false,
                State_game_totalElements: action.totalElements
                }
            }            
// =========================================================================================================================    
// =========================================================================================================================
// =========================================================================================================================            
        case EPISODE_WITH_FETCH_ERROR:
            return {
                ...state,
                State_error: {
				...state.State_error,	
                State_episode_with_error: action.payload,
                State_episode_with_sucessfully: false,
                State_episode_with_loading: false,
                }
            }
        case EPISODE_WITHOUT_FETCH_ERROR:
            return {
                ...state,
                State_error: {
				...state.State_error,	
                State_episode_without_error: action.payload,
                State_episode_without_sucessfully: false,
                State_episode_without_loading: false,
                }
            }
        case SONG_WITH_FETCH_ERROR:
            return {
                ...state,
                State_error: {
				...state.State_error,
                State_song_with_error: action.payload,
                State_song_with_loading: false,
                State_episode_with_loading: false,
                }
            }
        case SONG_WITHOUT_FETCH_ERROR:
            return {
                ...state,
                State_error: {
				...state.State_error,	
                State_song_without_error: action.payload,
                State_song_without_sucessfully: false,
                State_song_without_loading: false,
                }
            }
        case RADIO_FETCH_ERROR:
            return {
                ...state,
                State_error: {
				...state.State_error,	
                State_radio_error: action.payload,
                State_radio_sucessfully: false,
                State_radio_loading: false,
                }
            }
        case GAME_FETCH_ERROR:
            return {
                ...state,
                State_error: {
				...state.State_error,	
                State_game_error: action.payload,
                State_game_sucessfully: false,
                State_game_loading: false,
                }
            }                                                            
                   
//---------------------------------------------------------
			case EXPORT_CONTENT_VENDOR_REQUEST:
                return {
                    ...state,
                    exporting: true,
                    exportError: '',
                };
    
            case EXPORT_CONTENT_VENDOR_SUCCESS:
                return {
                    ...state,
                    exporting: false,
                    exportError: '',
                };
    
            case EXPORT_CONTENT_VENDOR_FAIL:
                return {
                    ...state,
                    exporting: false,
                    exportError: action.payload,
                };
                                        
                                    
        default:
            return state

    }
}
