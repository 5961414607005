import React, { useEffect, useState } from "react";

export default ({ children,selec }) => {
  const [selected, setSelected] = useState(selec);
  
  /*
  useEffect(()=> {
     
     children.forEach((ch,index)=>{
					if(index==selec)
					{
						//ch.addClass("selected")
						
						//console.log('classsssssssssssss');
					}
						
					//console.log("innnnndex : "+index+"    ,selec: "+selec);
			});
     }, []);
     */
  
  
  const buttons = children.map((child, index) => (
    <button
      key={{index}}
      className={`tab ${selected == index ? "selected" : "selected2"}`}
      onClick={() => setSelected(index)}
    >
      {child.props.title}
    </button>
  ));

  const content = (
    <div className="container">{children[selected].props.children}</div>
  );

  return [buttons, content];
};