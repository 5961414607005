export const FETCH_QUESTIONS_CATEGORIES = 'FETCH_QUESTIONS_CATEGORIES'
export const EMPTY = 'EMPTY'
/*songs*/
export const PUBLISH_SONG = 'PUBLISH_SONG'
export const GET_SONG_DATA = 'GET_SONG_DATA'
export const ADD_SONG = 'ADD_SONG'
export const FETCH_SONGS = 'FETCH_SONGS'
export const LOAD_SONGS = 'LOAD_SONGS'
export const ERROR_LOADING_SONGS = 'ERROR_LOADING_SONGS'
export const ERROR_LOADING_SONG = 'ERROR_LOADING_SONG'
export const ERROR_ADDING_SONG = 'ERROR_ADDING_SONG'
export const LOAD_SONG = 'LOAD_SONG'
export const EDIT_SONG_DATA = 'EDIT_SONG_DATA'
export const ERROR_EDITING_SONG = 'ERROR_EDITING_SONG'
export const EMPTY_SONG = 'EMPTY_SONG'
export const DELETE_SONG = 'DELETE_SONG'
export const ERROR_DELETING_SONG = 'ERROR_DELETING_SONG'
export const SETTING_SONG_STATUS = 'SETTING_SONG_STATUS'
export const ERROR_SETTING_SONG_STATUS = 'ERROR_SETTING_SONG_STATUS'
export const EMPTY_ALL_SONG = 'EMPTY_ALL_SONG'
/*Song Parent*/
export const FETCH_All_SONG_PARENT = 'FETCH_All_SONG_PARENT'
export const GET_SONG_PARENT_DATA = 'GET_SONG_PARENT_DATA'
export const LOAD_SONG_PARENTES = 'LOAD_SONG_PARENTES'
export const ERROR_LOADING_SONG_PARENTES = 'ERROR_LOADING_SONG_PARENTES'
export const ERROR_LOADING_SONG_PARENT = 'ERROR_LOADING_SONG_PARENT'
export const LOAD_SONG_PARENT = 'LOAD_SONG_PARENT'
export const SAVE_SONG_PARENT = 'SAVE_SONG_PARENT'
export const ERROR_ADDING_SONG_PARENT = 'ERROR_ADDING_SONG_PARENT'
export const ERROR_SETTING_STATE_SONG_PARENT = 'ERROR_SETTING_STATE_SONG_PARENT'
export const SETTING_STATE_SONG_PARENT = 'SETTING_STATE_SONG_PARENT'
export const ERROR_DELETING_SONG_PARENT = 'ERROR_DELETING_SONG_PARENT'
export const DELETING_SONG_PARENT = 'DELETING_SONG_PARENT'
export const ERROR_UPDATE_SONG_PARENT = 'ERROR_UPDATE_SONG_PARENT'
export const UPDATE_SONG_PARENT = 'UPDATE_SONG_PARENT'
export const EMPTY_SONG_PARENT = 'EMPTY_SONG_PARENT'
export const EMPTY_SONG_PARENT_WITH_DATA = 'EMPTY_SONG_PARENT_WITH_DATA'
export const ADD_SONG_PARENT_WITH_SENTFROM = 'ADD_SONG_PARENT_WITH_SENTFROM'
/*Book Parent*/
export const FETCH_All_BOOK_PARENT = 'FETCH_All_BOOK_PARENT'
export const GET_BOOK_PARENT_DATA = 'GET_BOOK_PARENT_DATA'
export const LOAD_BOOK_PARENTES = 'LOAD_BOOK_PARENTES'
export const ERROR_LOADING_BOOK_PARENTES = 'ERROR_LOADING_BOOK_PARENTES'
export const ERROR_LOADING_BOOK_PARENT = 'ERROR_LOADING_BOOK_PARENT'
export const LOAD_BOOK_PARENT = 'LOAD_BOOK_PARENT'
export const SAVE_BOOK_PARENT = 'SAVE_BOOK_PARENT'
export const ERROR_ADDING_BOOK_PARENT = 'ERROR_ADDING_BOOK_PARENT'
export const ERROR_SETTING_STATE_BOOK_PARENT = 'ERROR_SETTING_STATE_BOOK_PARENT'
export const SETTING_STATE_BOOK_PARENT = 'SETTING_STATE_BOOK_PARENT'
export const ERROR_DELETING_BOOK_PARENT = 'ERROR_DELETING_BOOK_PARENT'
export const DELETING_BOOK_PARENT = 'DELETING_BOOK_PARENT'
export const ERROR_UPDATE_BOOK_PARENT = 'ERROR_UPDATE_BOOK_PARENT'
export const UPDATE_BOOK_PARENT = 'UPDATE_BOOK_PARENT'
export const EMPTY_BOOK_PARENT = 'EMPTY_BOOK_PARENT'
export const EMPTY_BOOK_PARENT_WITH_DATA = 'EMPTY_BOOK_PARENT_WITH_DATA'
export const ADD_BOOK_PARENT_WITH_SENTFROM = 'ADD_BOOK_PARENT_WITH_SENTFROM'
/*Game Parent*/
export const FETCH_All_GAME_PARENT = 'FETCH_All_GAME_PARENT'
export const GET_GAME_PARENT_DATA = 'GET_GAME_PARENT_DATA'
export const LOAD_GAME_PARENTES = 'LOAD_GAME_PARENTES'
export const ERROR_LOADING_GAME_PARENTES = 'ERROR_LOADING_GAME_PARENTES'
export const ERROR_LOADING_GAME_PARENT = 'ERROR_LOADING_GAME_PARENT'
export const LOAD_GAME_PARENT = 'LOAD_GAME_PARENT'
export const SAVE_GAME_PARENT = 'SAVE_GAME_PARENT'
export const ERROR_ADDING_GAME_PARENT = 'ERROR_ADDING_GAME_PARENT'
export const ERROR_SETTING_STATE_GAME_PARENT = 'ERROR_SETTING_STATE_GAME_PARENT'
export const SETTING_STATE_GAME_PARENT = 'SETTING_STATE_GAME_PARENT'
export const ERROR_DELETING_GAME_PARENT = 'ERROR_DELETING_GAME_PARENT'
export const DELETING_GAME_PARENT = 'DELETING_GAME_PARENT'
export const ERROR_UPDATE_GAME_PARENT = 'ERROR_UPDATE_GAME_PARENT'
export const UPDATE_GAME_PARENT = 'UPDATE_GAME_PARENT'
export const EMPTY_GAME_PARENT = 'EMPTY_GAME_PARENT'
export const EMPTY_GAME_PARENT_WITH_DATA = 'EMPTY_GAME_PARENT_WITH_DATA'
export const ADD_GAME_PARENT_WITH_SENTFROM = 'ADD_GAME_PARENT_WITH_SENTFROM'
/*activities*/
export const PUBLISH_ACTIVITY = 'PUBLISH_ACTIVITY'
export const ERROR_ADDING_ACTIVITY = 'ERROR_ADDING_ACTIVITY'
export const ADD_ACTIVITY = 'ADD_ACTIVITY'

export const LOAD_ACTIVITY = 'LOAD_ACTIVITY'
export const GET_ACTIVITY_DATA = 'GET_ACTIVITY_DATA'
export const ERROR_LOADING_ACTIVITY = 'ERROR_LOADING_ACTIVITY'
export const EMPTY_ACTIVITY = 'EMPTY_ACTIVITY'
/******************** question ******************/

// loading and empty
export const LOAD_QUESTION = 'LOAD_QUESTION'
export const EMPTY_QUESTION = 'EMPTY_QUESTION'
export const DELETE_QUESTION_FROM_REDUX = 'DELETE_QUESTION_FROM_REDUX'

export const ASSIGN_CORRECT_ANSWER_TO_QUESTION = 'ASSIGN_CORRECT_ANSWER_TO_QUESTION'
export const PUBLISH_QUESTION = 'PUBLISH_QUESTION'
export const ADD_QUESTIONS = 'ADD_QUESTIONS'
export const ADD_QUESTION = 'ADD_QUESTION'
export const GET_ACTIVITY_QUESTIONS = 'GET_ACTIVITY_QUESTIONS'
export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS'

/******************************** * Redirection Page  * ***************************/
export const ADD_EPISODE_REDIRECTION_PAGE = 'ADD_EPISODE_REDIRECTION_PAGE'
export const EMPTY_EPISODE_REDIRECTION_PAGE = 'EMPTY_EPISODE_REDIRECTION_PAGE'

export const EMPTY_DRAG_DROP_PARENT_REDIRECTION_PAGE = 'EMPTY_DRAG_DROP_PARENT_REDIRECTION_PAGE'
export const ADD_DRAG_DROP_PARENT_REDIRECTION_PAGE = 'ADD_DRAG_DROP_PARENT_REDIRECTION_PAGE'

export const EXCHANGE_DATA = 'EXCHANGE_DATA'
export const CLEAR_EXCHANGE_DATA = 'CLEAR_EXCHANGE_DATA'

/******************************** * Episode * ***************************/
export const EMPTY_EPISODE = 'EMPTY_EPISODE'
export const EMPTY_EPISODE_RESPONSE = 'EMPTY_EPISODE_RESPONSE'
export const ADD_EPISODE = 'ADD_EPISODE'
export const LOAD_EPISODE = 'LOAD_EPISODE'
export const PUBLISH_EPISODE = 'PUBLISH_EPISODE'
export const GET_EPISODE_DATA = 'GET_EPISODE_DATA'
export const ERROR_ADDING_EPISODE = 'ERROR_ADDING_EPISODE'
export const ERROR_LOADING_EPISODE = 'ERROR_LOADING_EPISODE'
export const ERROR_LOADING_EPISODES = 'ERROR_LOADING_EPISODES'
export const UPDATE_EPISODE = 'UPDATE_EPISODE'
export const ERROR_UPDATE_EPISODE = 'ERROR_UPDATE_EPISODE'
export const DELETING_EPISODE = 'DELETING_EPISODE'
export const ERROR_DELETING_EPISODE = 'ERROR_DELETING_EPISODE'
export const ERROR_SETTING_STATE_EPISODE = 'ERROR_SETTING_STATE_EPISODE'
export const SETTING_STATE_EPISODE = 'SETTING_STATE_EPISODE'
/************************** Series ***************************/
//fetching data
export const FETCH_All_SERIES = 'FETCH_All_SERIES'
export const GET_SERIES_DATA = 'GET_SERIES_DATA'
//loading
export const LOAD_SERIESES = 'LOAD_SERIESES'
export const LOAD_SERIES = 'LOAD_SERIES'
//error loading
export const ERROR_LOADING_SERIESES = 'ERROR_LOADING_SERIESES'
export const ERROR_LOADING_SERIES = 'ERROR_LOADING_SERIES'
//add
export const ADD_SERIES_WITH_SENTFROM = 'ADD_SERIES_WITH_SENTFROM'
export const SAVE_SERIES = 'SAVE_SERIES'
export const ERROR_ADDING_SERIES = 'ERROR_ADDING_SERIES'
// set staTE
export const SETTING_STATE_SERIES = 'SETTING_STATE_SERIES'
export const ERROR_SETTING_STATE_SERIES = 'ERROR_SETTING_STATE_SERIES'
// delet
export const DELETING_SERIES = 'DELETING_SERIES'
export const ERROR_DELETING_SERIES = 'ERROR_DELETING_SERIES'
// update
export const UPDATE_SERIES = 'UPDATE_SERIES'
export const ERROR_UPDATE_SERIES = 'ERROR_UPDATE_SERIES'
// delete
export const EMPTY_SERIRS = 'EMPTY_SERIRS'
// get series type
export const GET_SERIES_TYPE = 'GET_SERIES_TYPE'
export const CLEAR_SERIES_TYPE = 'CLEAR_SERIES_TYPE'

/********************************* Stories******************************/
export const FETCH_STORIES = 'FETCH_STORIES'
export const LOAD_STORIES = 'LOAD_STORIES'
export const ERROR_LOADING_STORIES = 'ERROR_LOADING_STORIES'
export const ADD_STORY = 'ADD_STORY'
export const ADD_STORY_VIDEOS = 'ADD_STORY_VIDEOS'
export const PUBLISH_STORY = 'PUBLISH_STORY'
export const PUBLISH_VIDEO = 'PUBLISH_VIDEO'
export const ERROR_ADDING_VIDEO = 'ERROR_ADDING_VIDEO'
export const ERROR_ADDING_STORY = 'ERROR_ADDING_STORY'
export const ERROR_LOADING_STORY = 'ERROR_LOADING_STORY'
export const GET_STORY_DATA = 'GET_STORY_DATA'
export const LOAD_STORY = 'LOAD_STORY'
export const STORY_UPDATED = 'STORY_UPDATED'
export const STORY_UPDATE_FAIL = 'STORY_UPDATE_FAIL'
export const EMPTY_STORY = 'EMPTY_STORY'
export const EMPTY_ALL_STORIES = 'EMPTY_ALL_STORIES'
export const SETTING_STORY_STATUS = 'SETTING_STORY_STATUS'
export const ERROR_SETTING_STORY_STATUS = 'ERROR_SETTING_STORY_STATUS'
export const DELETE_STORY = "DELETE_STORY"
export const ERROR_DELETING_STORY = 'ERROR_DELETING_STORY'
export const ERROR_DELETING_STORY_PUZZLE = 'ERROR_DELETING_STORY_PUZZLE'
export const ADD_Story_WITH_SENTFROM = 'ADD_Story_WITH_SENTFROM'


/*Story Parent */
export const FETCH_All_STORY_PARENT = 'FETCH_All_STORY_PARENT'
export const GET_STORY_PARENT_DATA = 'GET_STORY_PARENT_DATA'
export const LOAD_STORY_PARENTES = 'LOAD_STORY_PARENTES'
export const ERROR_LOADING_STORY_PARENTES = 'ERROR_LOADING_STORY_PARENTES'
export const ERROR_LOADING_STORY_PARENT = 'ERROR_LOADING_STORY_PARENT'
export const LOAD_STORY_PARENT = 'LOAD_STORY_PARENT'
export const SAVE_STORY_PARENT = 'SAVE_STORY_PARENT'
export const ERROR_ADDING_STORY_PARENT = 'ERROR_ADDING_STORY_PARENT'
export const ERROR_SETTING_STATE_STORY_PARENT = 'ERROR_SETTING_STATE_STORY_PARENT'
export const SETTING_STATE_STORY_PARENT = 'SETTING_STATE_STORY_PARENT'
export const ERROR_DELETING_STORY_PARENT = 'ERROR_DELETING_STORY_PARENT'
export const DELETING_STORY_PARENT = 'DELETING_STORY_PARENT'
export const ERROR_UPDATE_STORY_PARENT = 'ERROR_UPDATE_STORY_PARENT'
export const UPDATE_STORY_PARENT = 'UPDATE_STORY_PARENT'
export const EMPTY_STORY_PARENT = 'EMPTY_STORY_PARENT'
export const EMPTY_STORY_PARENT_WITH_DATA = 'EMPTY_STORY_PARENT_WITH_DATA'
export const ADD_STORY_PARENT_WITH_SENTFROM = 'ADD_STORY_PARENT_WITH_SENTFROM'
// login firebase and redux
export const ADD_TOKEN = 'ADD_TOKEN'
export const EMPTY_TOKEN = 'EMPTY_TOKEN'
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const FIREBASE_ERROR = 'FIREBASE_ERROR'

/* Registration */
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const REGISTER_LOADING = 'REGISTER_LOADING'
/*UserManagement */
export const FETCH_ALL_ADMINS = 'FETCH_ALL_ADMINS'
export const LOAD_ALL_ADMINS = 'LOAD_ALL_ADMINS'
export const ERROR_ALL_ADMINS = 'ERROR_ALL_ADMINS'
export const ADMIN_RESET_PASSWORD = 'ADMIN_RESET_PASSWORD'
export const ERRROR_ADMIN_RESET_PASSWORD = 'ERRROR_ADMIN_RESET_PASSWORD'
export const EMPTY_ADMIN = 'EMPTY_ADMIN'
export const ERROR_CHANGE_ADMIN_ROLE = 'ERROR_CHANGE_ADMIN_ROLE'
export const CHANGE_ADMIN_ROLE = 'CHANGE_ADMIN_ROLE'
export const ERROR_FETCH_ADMIN = 'ERROR_FETCH_ADMIN'
export const FETCH_ADMIN = 'FETCH_ADMIN'

/*User Role */
export const FETCH_All_ADMINS_ROLE = 'FETCH_All_ASMINS_ROLE'
export const LOAD_ADMINS_ROLE = 'LOAD_ASMINS_ROLE'
export const ERROR_All_ADMINS_ROLE = 'ERROR_All_ASMINS_ROLE'
/********* Customer**********/

export const ACTIVE_CUSTOMER_PACKAGE = 'ACTIVE_CUSTOMER_PACKAGE'
export const ERROR_ACTIVE_CUSTOMER_PACKAGE = 'ERROR_ACTIVE_CUSTOMER_PACKAGE'
export const EMPTY_PACKAGE_ACTIVATION = 'EMPTY_PACKAGE_ACTIVATION'

export const EMPTY_CUSTOMER = 'EMPTY_CUSTOMER'
export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS'
export const LOAD_ALL_CUSTOMERS = 'LOAD_ALL_CUSTOMERS'
export const ERROR_ALL_CUSTOMERS = 'ERROR_ALL_CUSTOMERS'
export const ERROR_ADDING_CUSTOMERS = 'ERROR_ADDING_CUSTOMERS'
export const ADDING_CUSTOMERS = 'ADDING_CUSTOMERS'
export const LOAD_CUSTOMER = 'LOAD_CUSTOMER'
export const ERROR_LOADING_CUSTOMER = 'ERROR_LOADING_CUSTOMER'
export const GET_CUSTOMER_DATA = 'GET_CUSTOMER_DATA'
export const LOAD_SENDING_MAILS = 'LOAD_SENDING_MAILS'
export const ERROR_ADDING_CUSTOMER = 'ERROR_ADDING_CUSTOMER'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'
export const ERROR_EDITING_CUSTOMER = 'ERROR_EDITING_CUSTOMER'
export const EMPTY_PARENT = 'EMPTY'
export const ADD_CHILD = 'ADD_CHILD'
export const REMOVE_CHILD = 'REMOVE_CHILD'
export const CUSTOMER_FIRST_SIGN_UP = 'CUSTOMER_FIRST_SIGN_UP'
export const CUSTOMER_SIGNNED_IN = 'CUSTOMER_SIGNNED_IN'
//Block Customer
export const SETTING_STATE_CUSTOMER = 'SETTING_STATE_CUSTOMER'
export const ERROR_SETTING_STATE_CUSTOMER = 'ERROR_SETTING_STATE_CUSTOMER'
// customer reset password
export const ERRROR_CUSTOMER_RESET_PASSWORD = 'ERRROR_CUSTOMER_RESET_PASSWORD'
export const CUSTOMER_RESET_PASSWORD = 'CUSTOMER_RESET_PASSWORD'
//Mail
export const SEND_EMAIL = 'SEND_EMAIL'
export const ERROR_SEND_EMAIL = 'ERROR_SEND_EMAIL'
//Notification
export const LOAD_SENDING_NOTIFICATION = 'LOAD_SENDING_NOTIFICATION'
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION'
export const ERROR_SENDING_NOTIFICATION = 'ERROR_SENDING_NOTIFICATION'
export const SEND_NOTIFICATION_TO_ALLCUSTOMERS = 'SEND_NOTIFICATION_TO_ALLCUSTOMERS'
export const ERROR_SENDING_NOTIFICATION_TO_ALLCUSTOMERS = 'ERROR_SENDING_NOTIFICATION_TO_ALLCUSTOMERS'
//deleting admin
export const LOAD_ADMIN = 'LOAD_ADMIN'
export const ERROR_DELETING_ADMIN = 'ERROR_DELETING_ADMIN'
export const DELETING_ADMIN = 'DELETING_ADMIN'
// blocking admin
export const ERROR_SETTING_STATE_ADMIN = 'ERROR_SETTING_STATE_ADMIN'
export const SETTING_STATE_ADMIN = 'SETTING_STATE_ADMIN'
// studentActivity
export const LOAD_STUDENT_ACTIVITIES = 'LOAD_STUDENT_ACTIVITIES';
export const GET_STUDENT_ACTIVITIES_SUCCESS = 'GET_STUDENT_ACTIVITIES_SUCCESS';
export const GET_STUDENT_ACTIVITIES_ERROR = 'GET_STUDENT_ACTIVITIES_ERROR';
export const LOAD_RADIOS_REPORT = 'LOAD_RADIOS_REPORT';
export const GET_RADIOS_REPORT_SUCCESS = 'GET_RADIOS_REPORT_SUCCESS';
export const GET_RADIOS_REPORT_ERROR = 'GET_RADIOS_REPORT_ERROR';
export const LOAD_WATCHINGS_REPORT = 'LOAD_WATCHINGS_REPORT';
export const GET_WATCHINGS_REPORT_SUCCESS = 'GET_WATCHINGS_REPORT_SUCCESS';
export const GET_WATCHINGS_REPORT_ERROR = 'GET_WATCHINGS_REPORT_ERROR';
export const LOAD_WEEKLY_USAGE = 'LOAD_WEEKLY_USAGE';
export const GET_WEEKLY_USAGE_SUCCESS = 'GET_WEEKLY_USAGE_SUCCESS';
export const GET_WEEKLY_USAGE_ERROR = 'GET_WEEKLY_USAGE_ERROR';



/* Blogs */
export const FETCH_BLOGS = 'FETCH_BLOGS'
export const LOAD_BLOGS = 'LOAD_BLOGS'
export const ERROR_LOADING_BLOGS = 'ERROR_LOADING_BLOGS'
export const PUBLISH_BLOG = 'PUBLISH_BLOG'
export const ERROR_ADDING_BLOG = 'ERROR_ADDING_BLOG'
export const LOAD_BLOG = 'LOAD_BLOG'
export const GET_BLOG_DATA = 'GET_BLOG_DATA'
export const ERROR_LOADING_BLOG = 'ERROR_LOADING_BLOG'
export const UPDATE_BLOG = 'UPDATE_BLOG'
export const ERROR_UPDATING_BLOG = 'ERROR_UPDATING_BLOG'
export const EMPTY_STORY_VIDEOS = 'EMPTY_STORY_VIDEOS'
export const EMPTY_BLOG = 'EMPTY_BLOG'
export const DELETE_BLOG = "DELETE_BLOG"
export const ERROR_DELETING_BLOG = 'ERROR_DELETING_BLOG'
export const SETTING_BLOG_STATUS = 'SETTING_BLOG_STATUS'
export const ERROR_SETTING_BLOG_STATUS = 'ERROR_SETTING_BLOG_STATUS'

// Issues
export const FETCH_ALL_ISSUES = 'FETCH_ALL_ISSUES'
export const LOAD_ISSUES = 'LOAD_ISSUES'
export const ERROR_LOADING_ISSUES = 'ERROR_LOADING_ISSUES'
export const ERROR_LOADING_ISSUE = 'ERROR_LOADING_ISSUE'
export const GET_ISSUE_DATA = 'GET_ISSUE_DATA'
export const LOAD_ISSUE = 'LOAD_ISSUE'
export const UPDATE_ISSUE = 'UPDATE_ISSUE'
export const ERROR_UPDATING_ISSUE = 'ERROR_UPDATING_ISSUE'
export const ERROR_DELETING_ISSUE = 'ERROR_DELETING_ISSUE'
export const DELETING_ISSUE = 'DELETING_ISSUE'
export const EMPTY_ERROR = 'EMPTY_ERROR'
export const EMPTY_ALL_ISSUES = 'EMPTY_ALL_ISSUES'
// comments
export const SAVE_COMMENT = 'SAVE_COMMENT'
export const ERROR_SAVING_COMMENT = 'ERROR_SAVING_COMMENT'
export const LOAD_ISSUE_COMMENTS = 'LOAD_ISSUE_COMMENTS'
export const GET_ISSUE_COMMENTS = 'GET_ISSUE_COMMENTS'
export const ERROR_LOADING_ISSUE_COMMENTS = 'ERROR_LOADING_ISSUE_COMMENTS'

/* Blog Categories */
export const ERROR_LOADING_BLOG_CATEGORIES = 'ERROR_LOADING_BLOG_CATEGORIES'
export const GET_BLOG_CATEGPORIES = 'GET_BLOG_CATEGPORIES'
export const LOAD_BLOG_CATEGORIES = 'LOAD_BLOG_CATEGORIES'
export const BLOG_CATEGORY_DELETED = 'BLOG_CATEGORY_DELETED'
export const ERROR_DELETING_BLOG_CATEGORY = 'ERROR_DELETING_BLOG_CATEGORY'
export const ERROR_ADDING_BLOG_CATEGORY = 'ERROR_ADDING_BLOG_CATEGORY'
export const SAVE_BLOG_CATEGORY = 'SAVE_BLOG_CATEGORY'
export const UPDATE_BLOG_CATEGORY = 'UPDATE_BLOG_CATEGORY'
export const ERROR_UPDATE_BLOG_CATEGORY = 'ERROR_UPDATE_BLOG_CATEGORY'
export const EMPTY_BLOG_CATEGORY = 'EMPTY_BLOG_CATEGORY'
/** *************************TermsAndConditions* **************************/
export const LOAD_All_TERMSANDCONDITIONS = 'LOAD_All_TERMSANDCONDITIONS'
export const FETCH_All_TERMSANDCONDITIONS = 'FETCH_All_TERMSANDCONDITIONS'
export const ERROR_ALL_TERMSANDCONDITIONS = 'ERROR_ALL_TERMSANDCONDITIONS'
//update
export const ERROR_UPDATE_TERMSANDCONDITIONS = 'ERROR_UPDATE_TERMSANDCONDITIONS'
export const UPDATE_TERMSANDCONDITIONS = 'UPDATE_TERMSANDCONDITIONS'
export const LOAD_TERMSANDCONDITIONS = 'LOAD_TERMSANDCONDITIONS'
//add
export const ERROR_ADDING_TERMSANDCONDITIONS = 'ERROR_ADDING_TERMSANDCONDITIONS'
export const ADD_TERMSANDCONDITIONS = 'ADD_TERMSANDCONDITIONS'
//delete
export const DELETING_TERMSANDCONDITIONS = 'DELETING_TERMSANDCONDITIONS'
export const ERROR_DELETING_TERMSANDCONDITIONS = 'ERROR_DELETING_TERMSANDCONDITIONS'

/********************* * FAQ* *************************/
export const LOAD_All_FAQ = 'LOAD_All_FAQ'
export const FETCH_All_FAQ = 'FETCH_All_FAQ'
export const ERROR_ALL_FAQ = 'ERROR_ALL_FAQ'
//update
export const LOAD_FAQ = 'LOAD_FAQ'
export const ERROR_UPDATE_FAQ = 'ERROR_UPDATE_FAQ'
export const UPDATE_FAQ = 'UPDATE_FAQ'
//add
export const ERROR_ADDING_FAQ = 'ERROR_ADDING_FAQ'
export const ADD_FAQ = 'ADD_FAQ'
//delete
export const DELETING_FAQ = 'DELETING_FAQ'
export const ERROR_DELETING_FAQ = 'ERROR_DELETING_FAQ'
/********************** * Evaluation Category ***************** */
export const ADD_EVALUATION_CATEGORY_ID = 'ADD_EVALUATION_CATEGORY_ID'
export const EMPTY_EVALUATION_CATEGORY_ID = 'EMPTY_EVALUATION_CATEGORY_ID'

/********************* * Evaluation* *************************/
export const EMPTY_EVALUATION = 'EMPTY_EVALUATION'
export const EMPTY_EVALUATIONAll = 'EMPTY_EVALUATIONAll'
export const ADD_EVALUATION = 'ADD_EVALUATION'

/******************** * Discover us series * ***********************************/
export const LOAD_DISCOVER_US_SERIES = 'LOAD_DISCOVER_US_SERIES'
export const FETCH_ALL_DISCOVER_US_SERIES = 'FETCH_ALL_DISCOVER_US_SERIES'
export const ERROR_LOADING_DISCOVER_US_SERIES = 'ERROR_LOADING_DISCOVER_US_SERIES'
export const EMPTY_DISCOVER_US_SERIES = 'EMPTY_DISCOVER_US_SERIES'
export const SAVE_DISCOVER_US_SERIES = 'SAVE_DISCOVER_US_SERIES'
export const ERROR_SAVING_DISCOVER_US_SERIES = 'ERROR_SAVING_DISCOVER_US_SERIES'
export const DELETE_DISCOVER_US_SERIES = 'DELETE_DISCOVER_US_SERIES'
export const ERROR_DELETING_DISCOVER_US_SERIES = 'ERROR_DELETING_DISCOVER_US_SERIES'
export const UPDATE_DISCOVE_US_SERIES = 'UPDATE_DISCOVE_US_SERIES'
export const ERROR_UPDATING_DISCOVER_US_SERIES = 'ERROR_UPDATING_DISCOVER_US_SERIES'
export const GET_DISCOVER_US_SERIES_DATA = 'GET_DISCOVER_US_SERIES_DATA'
export const SETTING_DISCOVER_US_SERIES_STATUS = 'SETTING_DISCOVER_US_SERIES_STATUS'
export const ERROR_SETTING_DISCOVER_US_SERIES_STATUS = 'ERROR_SETTING_DISCOVER_US_SERIES_STATUS'
export const EMPTY_DISCOVER_US_SERIES_ITEM = 'EMPTY_DISCOVER_US_SERIES_ITEM'
export const ADD_DISCOVER_WITH_SENTFROM = 'ADD_DISCOVER_WITH_SENTFROM'
// generalSetting
export const LOAD_GENERAL_SETTING = 'LOAD_GENERAL_SETTING'
export const GET_GENERAL_SETTING_DATA = 'GET_GENERAL_SETTING_DATA'
export const ERROR_LOADING_GENERAL_SETTING = 'ERROR_LOADING_GENERAL_SETTING'
export const GENERAL_SETTING_UPDATE = 'GENERAL_SETTING_UPDATE'
export const ERROR_UPDATING_GENERAL_SETTING = 'ERROR_UPDATING_GENERAL_SETTING'
export const EMPTY_GENERAL_SETTING = 'EMPTY_GENERAL_SETTING'

//details reporting
export const LOAD_KIDS_REPORT = 'LOAD_KIDS_REPORT'
export const GET_KIDS_REPORT = "GET_KIDS_REPORT"
export const ERROR_LOADING_KIDS_REPORT = 'ERROR_LOADING_KIDS_REPORT'
export const EMPTY_REPORT = 'EMPTY_REPORT'

//all reporting
export const LOAD_KID_USAGE_REPORT = 'LOAD_KID_USAGE_REPORT'
export const GET_KID_USAGE_REPORT = 'GET_KID_USAGE_REPORT'
export const ERROR_LOADING_KID_USAGE_REPORT = 'ERROR_LOADING_KID_USAGE_REPORT'

//country filter
export const LOAD_USAGE_COUNTRY_FILTER = 'LOAD_USAGE_COUNTRY_FILTER'
export const GET_USAGE_COUNTRY_FILTER = 'GET_USAGE_COUNTRY_FILTER'
export const ERROR_LOADING_COUNTRY_FILTER = 'ERROR_LOADING_COUNTRY_FILTER'

//gender filter
export const LOAD_USAGE_GENDER_FILTER = 'LOAD_USAGE_GENDER_FILTER'
export const GET_USAGE_GENDER_FILTER = 'GET_USAGE_GENDER_FILTER'
export const ERROR_LOADING_GENDER_FILTER = 'ERROR_LOADING_GENDER_FILTER'

//age filter

export const LOAD_USAGE_AGE_FILTER = 'LOAD_USAGE_AGE_FILTER'
export const GET_USAGE_AGE_FILTER = 'GET_USAGE_AGE_FILTER'
export const ERROR_LOADING_AGE_FILTER = 'ERROR_LOADING_AGE_FILTER'

//custome Report
//country
export const LOAD_CUSTOME_COUNTRY = 'LOAD_CUSTOME_COUNTRY'
export const GET_CUSTOME_COUNTRY = 'GET_CUSTOME_COUNTRY'
export const ERROR_LOADING_CUSTOME_COUNTRY = 'ERROR_LOADING_CUSTOME_COUNTRY'
export const EMPTY_CUSTOME_REPORT = 'EMPTY_CUSTOME_REPORT'

//gender
export const LOAD_CUSTOME_GENDER = 'LOAD_CUSTOME_GENDER'
export const GET_CUSTOME_GENDER = 'GET_CUSTOME_GENDER'
export const ERROR_LOADING_CUSTOME_GENDER = 'ERROR_LOADING_CUSTOME_GENDER'

//age range
export const LOAD_CUSTOME_AGE_RANGE = 'LOAD_CUSTOME_AGE_RANGE'
export const GET_CUSTOME_AGE_RANGE = 'GET_CUSTOME_AGE_RANGE'
export const ERROR_LOADING_CUSTOME_AGE_RANGE = 'ERROR_LOADING_CUSTOME_AGE_RANGE'

//question category
export const QUESTION_CATEGORY_DELETED = 'QUESTION_CATEGORY_DELETED'
export const ERROR_DELETING_QUESTION_CATEGORY = 'ERROR_DELETING_QUESTION_CATEGORY'
export const UPDATE_QUESTION_CATEGORY = 'UPDATE_QUESTION_CATEGORY'
export const ERROR_UPDATE_QUESTION_CATEGORY = 'ERROR_UPDATE_QUESTION_CATEGORY'
export const SAVE_Question_CATEGORY = 'SAVE_Question_CATEGORY'
export const ERROR_ADDING_Question_CATEGORY = 'ERROR_ADDING_Question_CATEGORY'
export const EMPTY_QUESTION_CATEGORY = 'EMPTY_QUESTION_CATEGORY'

//StripePayment
export const LOAD_PAYMENT = 'LOAD_PAYMENT'
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const EMPTY_PAYMENT = 'EMPTY_PAYMENT'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const RENEW_PAYMENT = 'RENEW_PAYMENT'
export const CANCEL_RENEW_PAYMENT = 'CANCEL_RENEW_PAYMENT'

// stripe payment history
export const ERROR_GET_USER_PAYMENT_HISTORY = 'ERROR_GET_USER_PAYMENT_HISTORY'
export const GET_USER_PAYMENT_HISTORY = 'GET_USER_PAYMENT_HISTORY'
export const LOAD_GET_USER_PAYMENT_HISTORY = 'LOAD_GET_USER_PAYMENT_HISTORY'

// payment user
export const ERROR_GET_USER_PAYMENT_DATA = 'ERROR_GET_USER_PAYMENT_DATA'
export const GET_USER_PAYMENT_DATA = 'GET_USER_PAYMENT_DATA'
export const LOAD_GET_USER_PAYMENT_DATA = 'LOAD_GET_USER_PAYMENT_DATA'
export const EMPTY_MOBILE_USER_PROFILE = 'EMPTY_MOBILE_USER_PROFILE'
export const SUCCESS_CANCEL_USER_PAYMENT_SUPSCRIPTION = 'SUCCESS_CANCEL_USER_PAYMENT_SUPSCRIPTION'
export const SUCCESS_CANCEL_TPAY_SUPSCRIPTION = 'SUCCESS_CANCEL_TPAY_SUPSCRIPTION'
export const ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION = 'ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION'
export const EMPTY_MOBILE_USER_PROFILE_WITH_ITEM = 'EMPTY_MOBILE_USER_PROFILE_WITH_ITEM'
// payment package
export const FETCH_All_PACKAGE_PAYMENT = 'FETCH_All_PACKAGE_PAYMENT'
export const ERROR_FETCH_All_PACKAGE_PAYMENT = 'ERROR_FETCH_All_PACKAGE_PAYMENT'
export const LOAD_PACKAGE_PAYMENT = 'LOAD_PACKAGE_PAYMENT'
export const ADD_PAYMENT_DATA = 'ADD_PAYMENT_DATA'
export const DELETE_PAYMENT_DATA = 'DELETE_PAYMENT_DATA'
export const ADD_AUTO_RENEWEL = 'ADD_AUTO_RENEWEL'
export const UPDATE_All_PACKAGE_PAYMENT = 'UPDATE_All_PACKAGE_PAYMENT'
export const ERROR_UPDATE_All_PACKAGE_PAYMENT = 'ERROR_UPDATE_All_PACKAGE_PAYMENT'
export const EMPTY_PACKAGE_PAYMENT = 'EMPTY_PACKAGE_PAYMENT'
/*Payment Package price*/
export const ERROR_GET_PAYMENT_PRICE_BY_COUNTRY = 'ERROR_GET_PAYMENT_PRICE_BY_COUNTRY'
export const GET_PAYMENT_PRICE_BY_COUNTRY = 'GET_PAYMENT_PRICE_BY_COUNTRY'
export const LOAD_PAYMENT_PRICE_BY_COUNTRY = 'LOAD_PAYMENT_PRICE_BY_COUNTRY'
export const EMPTY_PAYMENT_PRICE_BY_COUNTRY = 'EMPTY_PAYMENT_PRICE_BY_COUNTRY'

/*Epecial countries price*/
export const FETCH_All_SPECIAL_COUNTRIES_PRICE = 'FETCH_All_SPECIAL_COUNTRIES_PRICE'
export const ERROR_FETCH_All_SPECIAL_COUNTRIES_PRICE = 'ERROR_FETCH_All_SPECIAL_COUNTRIES_PRICE'

export const LOAD_SPECIAL_COUNTRIES_PRICE = 'LOAD_SPECIAL_COUNTRIES_PRICE'

export const ADD_SPECIAL_COUNTRIES_PRICE = 'ADD_SPECIAL_COUNTRIES_PRICE'
export const ERROR_ADD_SPECIAL_COUNTRIES_PRICE = 'ERROR_ADD_SPECIAL_COUNTRIES_PRICE'

export const DELETE_SPECIAL_COUNTRIES_PRICE = 'DELETE_SPECIAL_COUNTRIES_PRICE'
export const ERROR_DELETE_SPECIAL_COUNTRIES_PRICE = 'ERROR_DELETE_SPECIAL_COUNTRIES_PRICE'

export const UPDATE_SPECIAL_COUNTRIES_PRICE = 'UPDATE_SPECIAL_COUNTRIES_PRICE'
export const ERROR_UPDATE_SPECIAL_COUNTRIES_PRICE = 'ERROR_UPDATE_SPECIAL_COUNTRIES_PRICE'

export const EMPTY_SPECIAL_COUNTRIES_PRICE = 'EMPTY_SPECIAL_COUNTRIES_PRICE'

/*Fawry Payment*/
export const ERROR_GET_FAWRY_PAYMENT_PACKAGE = 'ERROR_GET_FAWRY_PAYMENT_PACKAGE'
export const GET_FAWRY_PAYMENT_PACKAGE = 'GET_FAWRY_PAYMENT_PACKAGE'
export const LOAD_GET_FAWRY_PAYMENT_PACKAGE = 'LOAD_GET_FAWRY_PAYMENT_PACKAGE'
export const EMPTY_FAWRY_PAYMENT_PACKAGE = 'EMPTY_FAWRY_PAYMENT_PACKAGE'
/*Payment Countries*/
export const ERROR_GET_PAYMENT_PACKAGE = 'ERROR_GET_PAYMENT_PACKAGE'
export const GET_PAYMENT_PACKAGE = 'GET_PAYMENT_PACKAGE'
export const LOAD_GET_PAYMENT_PACKAGE = 'LOAD_GET_PAYMENT_PACKAGE'
export const CLEAR_GET_PAYMENT_PACKAGE = 'CLEAR_GET_PAYMENT_PACKAGE'
export const EMPTY_PAYMENT_PACKAGE = 'EMPTY_PAYMENT_PACKAGE'
export const UPDATE_PAYMENT_PACKAGE = 'UPDATE_PAYMENT_PACKAGE'
export const ERROR_UPDATE_PAYMENT_PACKAGE = 'ERROR_UPDATE_PAYMENT_PACKAGE'
/* Content Main values*/
export const LOAD_All_MAIN_VALUE = 'LOAD_All_MAIN_VALUE'
export const FETCH_All_MAIN_VALUE = 'FETCH_All_MAIN_VALUE'
export const ERROR_ALL_MAIN_VALUE = 'ERROR_ALL_MAIN_VALUE'
export const LOAD_MAIN_VALUE = 'LOAD_MAIN_VALUE'
export const ERROR_UPDATE_MAIN_VALUE = 'ERROR_UPDATE_MAIN_VALUE'
export const UPDATE_MAIN_VALUE = 'UPDATE_MAIN_VALUE'
export const ERROR_ADDING_MAIN_VALUE = 'ERROR_ADDING_MAIN_VALUE'
export const ADD_MAIN_VALUE = 'ADD_MAIN_VALUE'
export const DELETING_MAIN_VALUE = 'DELETING_MAIN_VALUE'
export const ERROR_DELETING_MAIN_VALUE = 'ERROR_DELETING_MAIN_VALUE'
export const EMPTY_MAIN_VALUE = 'EMPTY_MAIN_VALUE'
export const GET_MAIN_VALUE = 'GET_MAIN_VALUE'
export const ERROR_LOADING_MAIN_VALUE = 'ERROR_LOADING_MAIN_VALUE'

/*Content Sub values */

export const LOAD_All_SUB_VALUE = 'LOAD_All_SUB_VALUE'
export const FETCH_All_SUB_VALUE = 'FETCH_All_SUB_VALUE'
export const ERROR_ALL_SUB_VALUE = 'ERROR_ALL_SUB_VALUE'

export const SUB_VALUE_DELETED = 'SUB_VALUE_DELETED'
export const ERROR_DELETING_SUB_VALUE = 'ERROR_DELETING_SUB_VALUE'
export const SAVE_SUB_VALUE = 'SAVE_SUB_VALUE'
export const ERROR_ADDING_SUB_VALUE = 'ERROR_ADDING_SUB_VALUE'
export const UPDATE_SUB_VALUE = 'UPDATE_SUB_VALUE'
export const ERROR_UPDATE_SUB_VALUE = 'ERROR_UPDATE_SUB_VALUE'
export const EMPTY_SUB_VALUE = 'EMPTY_SUB_VALUE'

/*Duration Of Use*/
export const LOAD_All_DURATION_OF_USE = 'LOAD_All_DURATION_OF_USE'
export const FETCH_All_DURATION_OF_USE = 'FETCH_All_DURATION_OF_USE'
export const ERROR_ALL_DURATION_OF_USE = 'ERROR_ALL_DURATION_OF_USE'
export const LOAD_DURATION_OF_USE = 'LOAD_DURATION_OF_USE'
export const ERROR_ADDING_DURATION_OF_USE = 'ERROR_ADDING_DURATION_OF_USE'
export const ADD_DURATION_OF_USE = 'ADD_DURATION_OF_USE'
export const DELETING_DURATION_OF_USE = 'DELETING_DURATION_OF_USE'
export const ERROR_DELETING_DURATION_OF_USE = 'ERROR_DELETING_DURATION_OF_USE'
export const EMPTY_DURATION = 'EMPTY_DURATION'

/*Time Of Use */
export const LOAD_All_TIME_OF_USE = 'LOAD_All_TIME_OF_USE'
export const FETCH_All_TIME_OF_USE = 'FETCH_All_TIME_OF_USE'
export const ERROR_ALL_TIME_OF_USE = 'ERROR_ALL_TIME_OF_USE'
export const LOAD_TIME_OF_USE = 'LOAD_TIME_OF_USE'
export const ERROR_ADDING_TIME_OF_USE = 'ERROR_ADDING_TIME_OF_USE'
export const ADD_TIME_OF_USE = 'ADD_TIME_OF_USE'
export const DELETING_TIME_OF_USE = 'DELETING_TIME_OF_USE'
export const ERROR_DELETING_TIME_OF_USE = 'ERROR_DELETING_TIME_OF_USE'
export const EMPTY_TIME = 'EMPTY_TIME'

/* Secure Random Number */
export const EMPTY_RANDOM_NUMBER = 'EMPTY_RANDOM_NUMBER'
export const LOAD_RANDOM_NUMBER = 'LOAD_RANDOM_NUMBER'
export const GET_RANDOM_NUMBER = 'GET_RANDOM_NUMBER'
export const ERROR_GET_RANDOM_NUMBER = 'ERROR_GET_RANDOM_NUMBER'
export const EMPTY_RANDOM_NUMBER_ITEMS = 'EMPTY_RANDOM_NUMBER_ITEMS'
export const GET_RANDOM_NUMBER_LINK_ID = 'GET_RANDOM_NUMBER_LINK_ID'
export const GET_RANDOM_NUMBER_NEW = 'GET_RANDOM_NUMBER_NEW'
export const FETCH_ALL_QrTemplates = 'FETCH_ALL_QrTemplates'
export const ERROR_LOADING_ALL_QrTemplates = 'ERROR_LOADING_ALL_QrTemplates'

/* Group */
export const FETCH_ALL_GROUP = 'FETCH_ALL_GROUP';
export const LOAD_GROUP = 'LOAD_GROUP';
export const LOAD_ASSIGN_GROUP = 'LOAD_ASSIGN_GROUP';
export const LOAD_GROUP_PAGE_OR_FILTER = 'LOAD_GROUP_PAGE_OR_FILTER';
export const ERROR_LOADING_ALL_GROUP = 'ERROR_LOADING_ALL_GROUP';
export const ADD_GROUP_ERROR = 'ADD_GROUP_ERROR';
export const ADD_GROUP_SUCCESSFULLY = 'ADD_GROUP_SUCCESSFULLY';
export const EMPTY_GROUP = 'EMPTY_GROUP';
export const UPDATE_GROUP_SUCCESSFULLY = 'UPDATE_GROUP_SUCCESSFULLY';
export const DELETE_GROUP_SUCCESSFULLY = 'DELETE_GROUP_SUCCESSFULLY';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';
export const GET_GROUP_DATA_SUCCESSFULLY = 'GET_GROUP_DATA_SUCCESSFULLY';
export const GET_GROUP_DATA_ERROR = 'GET_GROUP_DATA_ERROR';
export const LOAD_MEMBERS = 'LOAD_MEMBERS';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'FETCH_MEMBERS_ERROR';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_ERROR = 'FETCH_DASHBOARD_ERROR';
export const ASSIGN_STUDENT_TO_GROUP_SUCCESS = 'ASSIGN_STUDENT_TO_GROUP_SUCCESS';
export const ASSIGN_STUDENT_TO_GROUP_ERROR = 'ASSIGN_STUDENT_TO_GROUP_ERROR';

/*Vendor*/
export const FETCH_ALL_VENDOR = 'FETCH_ALL_VENDOR'
export const LOAD_VENDOR = 'LOAD_VENDOR'
export const ERROR_LOADING_ALL_VENDOR = 'ERROR_LOADING_ALL_VENDOR'
export const ADD_VENDOR_ERROR = 'ADD_VENDOR_ERROR'
export const ADD_VENDOR_SUCCESSFULLY = 'ADD_VENDOR_SUCCESSFULLY'
export const EMPTY_VENDOR = 'EMPTY_VENDOR'
export const UPDATE_VENDOR_SUCCESSFULLY = 'UPDATE_VENDOR_SUCCESSFULLY'
export const UPDATE_VENDOR_ERROR = 'UPDATE_VENDOR_ERROR'
export const GET_VENDOR_DATA_SUCCESSFULLY = 'GET_VENDOR_DATA_SUCCESSFULLY'
export const GET_VENDOR_DATA_ERROR = 'GET_VENDOR_DATA_ERROR'

export const CARD_FILE_UPLOADED = 'CARD_FILE_UPLOADED'
export const LOAD_VENDOR_DASHBOARD = 'LOAD_VENDOR_DASHBOARD';
export const FETCH_VENDOR_DASHBOARD_SUCCESS = 'FETCH_VENDOR_DASHBOARD_SUCCESS';
export const FETCH_VENDOR_DASHBOARD_ERROR = 'FETCH_VENDOR_DASHBOARD_ERROR';

export const FETCH_CHILDREN_BY_VENDOR = 'FETCH_CHILDREN_BY_VENDOR';
export const ERROR_FETCHING_CHILDREN_BY_VENDOR = 'ERROR_FETCHING_CHILDREN_BY_VENDOR';


/*Vendor Report */
export const LOAD_VENDOR_REPORT = 'LOAD_VENDOR_REPORT'
export const FETCH_ALL_VENDOR_REPORT = 'FETCH_ALL_VENDOR_REPORT'
export const FETCH_ALL_VENDOR_REPORT_ERROR = 'FETCH_ALL_VENDOR_REPORT_ERROR'
export const EMPTY_VENDOR_REPORT = 'EMPTY_VENDOR_REPORT'

/*Promo Codes */
export const LOAD_ALL_CODES = 'LOAD_ALL_CODES'
export const FETCH_ALL_CODES = 'FETCH_ALL_CODES'
export const ERROR_ALL_CODES = 'ERROR_ALL_CODES'
export const LOAD_CODE = 'LOAD_CODE'
export const ERROR_DELETING_CODE = 'ERROR_DELETING_CODE'
export const DELETING_CODE = 'DELETING_CODE'
export const EMPTY_CODE = 'EMPTY_CODE'
export const ADD_CODE = 'ADD_CODE'
export const ERROR_ADDING_CODE = 'ERROR_ADDING_CODE'
export const FETCH_CODE_DATA = 'FETCH_CODE_DATA'
export const ERROR_LOADING_CODE = 'ERROR_LOADING_CODE'
export const EDIT_CODE = 'EDIT_CODE'
export const ERROR_EDITING_CODE = 'ERROR_EDITING_CODE'
export const ADD_CODE_TO_REDUX = 'ADD_CODE_TO_REDUX'
export const EMPTY_CODE_FROM_REDUX = 'EMPTY_CODE_FROM_REDUX'
export const FETCH_SAVED_CODE = 'FETCH_SAVED_CODE'
export const ERROR_FETCH_SAVED_CODE = 'ERROR_FETCH_SAVED_CODE'
export const LOAD_SAVED_CODE = 'LOAD_SAVED_CODE'
export const SAVE_CODE = 'SAVE_CODE'
export const ERROR_SAVING_CODE = 'ERROR_SAVING_CODE'
export const LOAD_SAVING_CODE = 'LOAD_SAVING_CODE'
export const CLEAR_PROMO_CODE_ALL = 'CLEAR_PROMO_CODE_ALL'

/*Discount Types */
export const LOAD_ALL_DISCOUNTS = 'LOAD_ALL_DISCOUNTS'
export const FETCH_ALL_DISCOUNTS = 'FETCH_ALL_DISCOUNTS'
export const ERROR_ALL_DISCOUNTS = 'ERROR_ALL_DISCOUNTS'

/*Coupon Types */
export const FETCH_ALL_COUPONS = 'FETCH_ALL_COUPONS'
export const LOAD_ALL_COUPONS = 'LOAD_ALL_COUPONS'
export const ERROR_ALL_COUPONS = 'ERROR_ALL_COUPONS'

/* Payment Methods */
export const FETCH_ALL_METHODS = 'FETCH_ALL_METHODS'
export const LOAD_ALL_METHODS = 'LOAD_ALL_METHODS'
export const ERROR_ALL_METHODS = 'ERROR_ALL_METHODS'


// subscription profile user in web portal
export const ERROR_GET_USER_CODE_DATA = 'ERROR_GET_USER_CODE_DATA'
export const GET_USER_CODE_DATA = 'GET_USER_CODE_DATA'
export const LOAD_GET_USER_CODE_DATA = 'LOAD_GET_USER_CODE_DATA'
export const EMPTY_USER_PROFILE = 'EMPTY_USER_PROFILE'
export const EMPTY_USER_PROFILE_WITH_ITEM = 'EMPTY_USER_PROFILE_WITH_ITEM'
export const SUCCESS_CANCEL_USER_RENEWAL_SUPSCRIPTION = 'SUCCESS_CANCEL_USER_RENEWAL_SUPSCRIPTION'
export const ERROR_CANCEL_USER_RENEWAL_SUPSCRIPTION = 'ERROR_CANCEL_USER_RENEWAL_SUPSCRIPTION'

// success messages in portal
export const ADD_SUCCESS_MESSAGE = 'ADD_SUCCESS_MESSAGE'
export const REMOVE_SUCCESS_MESSAGE = 'REMOVE_SUCCESS_MESSAGE'

// Currency rate in portal
export const LOAD_CURRENCY_RATE = 'LOAD_CURRENCY_RATE'
export const GET_CURRENCY_RATE = 'GET_CURRENCY_RATE'
export const ERROR_GETTING_CURRENCY_RATE = 'ERROR_GETTING_CURRENCY_RATE'
export const EMPTY_CURRENCY_RATE = 'EMPTY_CURRENCY_RATE'


// fit
export const FIT_USER_LOGIN_SUCCESSFULLY = 'FIT_USER_LOGIN_SUCCESSFULLY'
export const FIT_USER_LOGIN_ERROR = 'FIT_USER_LOGIN_ERROR'
export const LOADING_FIT_USER_LOGIN = 'LOADING_FIT_USER_LOGIN'

export const FIT_USER_REGISTER_SUCCESSFULLY = 'FIT_USER_REGISTER_SUCCESSFULLY'
export const FIT_USER_REGISTER_ERROR = 'FIT_USER_REGISTER_ERROR'
export const LOADING_FIT_USER_REGISTER = 'LOADING_FIT_USER_REGISTER'

export const LOAD_FIT_VERIFYING_CODE = 'LOAD_FIT_VERIFYING_CODE'
export const FIT_VERIFYING_CODE_SUCCESS = 'FIT_VERIFYING_CODE_SUCCESS'
export const FIT_VERIFYING_CODE_ERROR = 'FIT_VERIFYING_CODE_ERROR'

export const LOAD_FIT_RESEND_CODE = 'LOAD_FIT_RESEND_CODE'
export const FIT_RESEND_CODE_SUCCESS = 'FIT_RESEND_CODE_SUCCESS'
export const FIT_RESEND_CODE_ERROR = 'FIT_RESEND_CODE_ERROR'

export const EMPTY_FIT = 'EMPTY_FIT'


// Tpay
export const TPAY_USER_LOGIN_SUCCESSFULLY = 'TPAY_USER_LOGIN_SUCCESSFULLY'
export const TPAY_USER_LOGIN_ERROR = 'TPAY_USER_LOGIN_ERROR'
export const LOADING_TPAY_USER_LOGIN = 'LOADING_TPAY_USER_LOGIN'

export const TPAY_USER_REGISTER_SUCCESSFULLY = 'TPAY_USER_REGISTER_SUCCESSFULLY'
export const TPAY_USER_REGISTER_ERROR = 'TPAY_USER_REGISTER_ERROR'
export const LOADING_TPAY_USER_REGISTER = 'LOADING_TPAY_USER_REGISTER'

export const LOAD_TPAY_VERIFYING_CODE = 'LOAD_TPAY_VERIFYING_CODE'
export const TPAY_VERIFYING_CODE_SUCCESS = 'TPAY_VERIFYING_CODE_SUCCESS'
export const TPAY_VERIFYING_CODE_ERROR = 'TPAY_VERIFYING_CODE_ERROR'

export const LOAD_TPAY_RESEND_CODE = 'LOAD_TPAY_RESEND_CODE'
export const TPAY_RESEND_CODE_SUCCESS = 'TPAY_RESEND_CODE_SUCCESS'
export const TPAY_RESEND_CODE_ERROR = 'TPAY_RESEND_CODE_ERROR'

export const LOAD_TPAY_DIGEST_CODE = 'LOAD_TPAY_DIGEST_CODE'
export const TPAY_DIGEST_CODE_SUCCESS = 'TPAY_DIGEST_CODE_SUCCESS'
export const TPAY_DIGEST_CODE_ERROR = 'TPAY_DIGEST_CODE_ERROR'

export const EMPTY_TPAY = 'EMPTY_TPAY'
// Fawry pages redirection between old payment-portal and the new web-portal
export const ADD_FAWRY_REDIRECTION_PAGE = 'ADD_FAWRY_REDIRECTION_PAGE'
export const REMOVE_FAWRY_REDIRECTION_PAGE = 'REMOVE_FAWRY_REDIRECTION_PAGE'

// PRE PAID PAYMENT
export const LOAD_PRE_PAID_PAYMENT = 'LOAD_PRE_PAID_PAYMENT'
export const ADD_PRE_PAID_PAYMENT = 'ADD_PRE_PAID_PAYMENT'
export const EMPTY_PRE_PAID_PAYMENT = 'EMPTY_PRE_PAID_PAYMENT'
export const ERROR_ADDING_PRE_PAID_PAYMENT = 'ERROR_ADDING_PRE_PAID_PAYMENT'

/*Radio*/
export const FETCH_All_RADIO = 'FETCH_All_RADIO'
export const GET_RADIO_DATA = 'GET_RADIO_DATA'
export const LOAD_ALL_RADIO = 'LOAD_ALL_RADIO'
export const ERROR_LOADING_RADIOES = 'ERROR_LOADING_RADIOES'
export const ERROR_LOADING_RADIO = 'ERROR_LOADING_RADIO'
export const LOAD_RADIO = 'LOAD_RADIO'
export const SAVE_RADIO = 'SAVE_RADIO'
export const ERROR_ADDING_RADIO = 'ERROR_ADDING_RADIO'
export const ERROR_SETTING_STATE_RADIO = 'ERROR_SETTING_STATE_RADIO'
export const SETTING_STATE_RADIO = 'SETTING_STATE_RADIO'
export const ERROR_DELETING_RADIO = 'ERROR_DELETING_RADIO'
export const DELETING_RADIO = 'DELETING_RADIO'
export const ERROR_UPDATE_RADIO = 'ERROR_UPDATE_RADIO'
export const UPDATE_RADIO = 'UPDATE_RADIO'
export const EMPTY_RADIO = 'EMPTY_RADIO'
export const EMPTY_RADIO_WITH_DATA = 'EMPTY_RADIO_WITH_DATA'
export const ADD_RADIO_WITH_SENTFROM = 'ADD_RADIO_WITH_SENTFROM'

/*Radio Channel*/
export const FETCH_ALL_CHANNELS = 'FETCH_ALL_CHANNELS'
export const ADD_CHANNEL = 'ADD_CHANNEL'
export const PUBLISH_CHANNEL = 'PUBLISH_CHANNEL'
export const GET_CHANNEL_DATA = 'GET_CHANNEL_DATA'
export const LOAD_ALL_CHANNELS = 'LOAD_ALL_CHANNELS'
export const ERROR_LOADING_CHANNEL = 'ERROR_LOADING_CHANNEL'
export const ERROR_LOADING_ALL_CHANNELS = 'ERROR_LOADING_ALL_CHANNELS'
export const ERROR_ADDING_CHANNEL = 'ERROR_ADDING_CHANNEL'
export const LOAD_CHANNEL = 'LOAD_CHANNEL'
export const EDIT_CHANNEL_DATA = 'EDIT_CHANNEL_DATA'
export const ERROR_EDITING_CHANNEL = 'ERROR_EDITING_CHANNEL'
export const EMPTY_CHANNEL = 'EMPTY_CHANNEL'
export const ERROR_DELETING_CHANNEL = 'ERROR_DELETING_CHANNEL'
export const DELETE_CHANNEL = 'DELETE_CHANNEL'
export const SETTING_CHANNEL_STATUS = 'SETTING_CHANNEL_STATUS'
export const ERROR_SETTING_CHANNEL_STATUS = 'ERROR_SETTING_CHANNEL_STATUS'
export const EMPTY_ALL_CHANNELS = 'EMPTY_ALL_CHANNELS'

/*In App Notification*/
export const FETCH_ALL_IN_APP_NOTIFICATION = 'FETCH_ALL_IN_APP_NOTIFICATION'
export const LOAD_IN_APP_NOTIFICATION = 'LOAD_IN_APP_NOTIFICATION'
export const LOAD_ALL_IN_APP_NOTIFICATION = 'LOAD_ALL_IN_APP_NOTIFICATION'
export const ERROR_LOADING_ALL_IN_APP_NOTIFICATION = 'ERROR_LOADING_ALL_IN_APP_NOTIFICATION'
export const ADD_IN_APP_NOTIFICATION_SUCCESSFULLY = 'ADD_IN_APP_NOTIFICATION_SUCCESSFULLY'
export const ADD_IN_APP_NOTIFICATION_ERROR = 'ADD_IN_APP_NOTIFICATION_ERROR'
export const UPDATE_IN_APP_NOTIFICATION_SUCCESSFULLY = 'UPDATE_IN_APP_NOTIFICATION_SUCCESSFULLY'
export const UPDATE_IN_APP_NOTIFICATION_ERROR = 'UPDATE_IN_APP_NOTIFICATION_ERROR'
export const GET_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY = 'GET_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY'
export const GET_IN_APP_NOTIFICATION_DATA_ERROR = 'GET_IN_APP_NOTIFICATION_DATA_ERROR'
export const DELETE_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY = 'DELETE_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY'
export const DELETE_IN_APP_NOTIFICATION_DATA_ERROR = 'DELETE_IN_APP_NOTIFICATION_DATA_ERROR'
export const EMPTY_IN_APP_NOTIFICATION = 'EMPTY_IN_APP_NOTIFICATION'
export const EMPTY_IN_APP_NOTIFICATION_RESPONSE = 'EMPTY_IN_APP_NOTIFICATION_RESPONSE'

/*Game Data */
export const LOAD_All_GAME_DATA = 'LOAD_All_GAME_DATA'
export const FETCH_All_GAME_DATA = 'FETCH_All_GAME_DATA'
export const ERROR_ALL_GAME_DATA = 'ERROR_ALL_GAME_DATA'
export const LOAD_GAME_DATA = 'LOAD_GAME_DATA'
export const ERROR_UPDATE_GAME_DATA = 'ERROR_UPDATE_GAME_DATA'
export const UPDATE_GAME_DATA = 'UPDATE_GAME_DATA'
export const ERROR_ADDING_GAME_DATA = 'ERROR_ADDING_GAME_DATA'
export const ADD_GAME_DATA = 'ADD_GAME_DATA'
export const DELETING_GAME_DATA = 'DELETING_GAME_DATA'
export const ERROR_DELETING_GAME_DATA = 'ERROR_DELETING_GAME_DATA'
export const EMPTY_GAME_DATA = 'EMPTY_GAME_DATA'
export const GET_GAME_DATA = 'GET_GAME_DATA'
export const ERROR_LOADING_GAME_DATA = 'ERROR_LOADING_GAME_DATA'


/*Book*/
export const FETCH_ALL_BOOKS = 'FETCH_ALL_BOOKS'
export const ADD_BOOK = 'ADD_BOOK'
export const PUBLISH_BOOK = 'PUBLISH_BOOK'
export const GET_BOOK_DATA = 'GET_BOOK_DATA'
export const LOAD_ALL_BOOKS = 'LOAD_ALL_BOOKS'
export const ERROR_LOADING_BOOK = 'ERROR_LOADING_BOOK'
export const ERROR_LOADING_ALL_BOOKS = 'ERROR_LOADING_ALL_BOOKS'
export const ERROR_ADDING_BOOK = 'ERROR_ADDING_BOOK'
export const LOAD_BOOK = 'LOAD_BOOK'
export const EDIT_BOOK_DATA = 'EDIT_BOOK_DATA'
export const ERROR_EDITING_BOOK = 'ERROR_EDITING_BOOK'
export const EMPTY_BOOK = 'EMPTY_BOOK'
export const ERROR_DELETING_BOOK = 'ERROR_DELETING_BOOK'
export const DELETE_BOOK = 'DELETE_BOOK'
export const SETTING_BOOK_STATUS = 'SETTING_BOOK_STATUS'
export const ERROR_SETTING_BOOK_STATUS = 'ERROR_SETTING_BOOK_STATUS'
export const EMPTY_ALL_BOOKS = 'EMPTY_ALL_BOOKS'

export const ADD_BOOK_PAGE = 'ADD_BOOK_PAGE'
export const UPDATE_BOOK_PAGE = 'UPDATE_BOOK_PAGE'
export const DELETE_BOOK_PAGE = 'DELETE_BOOK_PAGE'
export const ERROR_DELETING_BOOK_PAGE = 'ERROR_DELETING_BOOK_PAGE'
export const ERROR_ADDING_BOOK_PAGE = 'ERROR_ADDING_BOOK_PAGE'
export const ERROR_UPDATE_BOOK_PAGE = 'ERROR_UPDATE_BOOK_PAGE'
export const LOAD_BOOK_PAGE = 'LOAD_BOOK_PAGE'


export const ADD_BOOK_WITH_SENTFROM = 'ADD_BOOK_WITH_SENTFROM'

// PDF Book
export const FETCH_ALL_PDF_BOOKS = 'FETCH_ALL_PDF_BOOKS'
export const ADD_PDF_BOOK = 'ADD_PDF_BOOK'
export const PUBLISH_PDF_BOOK = 'PUBLISH_PDF_BOOK'
export const GET_PDF_BOOK_DATA = 'GET_PDF_BOOK_DATA'
export const LOAD_ALL_PDF_BOOKS = 'LOAD_ALL_PDF_BOOKS'
export const ERROR_LOADING_PDF_BOOK = 'ERROR_LOADING_PDF_BOOK'
export const ERROR_LOADING_ALL_PDF_BOOKS = 'ERROR_LOADING_ALL_PDF_BOOKS'
export const ERROR_ADDING_PDF_BOOK = 'ERROR_ADDING_PDF_BOOK'
export const LOAD_PDF_BOOK = 'LOAD_PDF_BOOK'
export const EDIT_PDF_BOOK_DATA = 'EDIT_PDF_BOOK_DATA'
export const ERROR_EDITING_PDF_BOOK = 'ERROR_EDITING_PDF_BOOK'
export const EMPTY_PDF_BOOK = 'EMPTY_PDF_BOOK'
export const ERROR_DELETING_PDF_BOOK = 'ERROR_DELETING_PDF_BOOK'
export const DELETE_PDF_BOOK = 'DELETE_PDF_BOOK'
export const SETTING_PDF_BOOK_STATUS = 'SETTING_PDF_BOOK_STATUS'
export const ERROR_SETTING_PDF_BOOK_STATUS = 'ERROR_SETTING_PDF_BOOK_STATUS'
export const EMPTY_ALL_PDF_BOOKS = 'EMPTY_ALL_PDF_BOOKS'
export const ADD_PDF_BOOK_WITH_SENTFROM = 'ADD_PDF_BOOK_WITH_SENTFROM'
export const GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE = 'GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE'
export const LOAD_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE = 'LOAD_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE'
export const ERORR_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE = 'ERORR_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE'

//payment data
export const SAVE_PAYMENT_METHODS = 'SAVE_PAYMENT_METHODS'
export const SAVE_PRICE_SUBSCRIBE_CARD = 'SAVE_PRICE_SUBSCRIBE_CARD'
export const SAVE_DISCOUNT_SUBSCRIBE_CARD = 'SAVE_DISCOUNT_SUBSCRIBE_CARD'
export const SAVE_DISCRIPTION_SUBSCRIBE_CARD = 'SAVE_DISCRIPTION_SUBSCRIBE_CARD'
export const SAVE_EXPIRATIONDATE_SUBSCRIBE_CARD = 'SAVE_EXPIRATIONDATE_SUBSCRIBE_CARD'
export const SAVE_SLUG_SUBSCRIBE_CARD = 'SAVE_SLUG_SUBSCRIBE_CARD'
export const CLEAR_PAYMENT_DATA = "CLEAR_PAYMENT_DATA"
export const CHANGE_PAYMENT_METHOD_TYPE = "CHANGE_PAYMENT_METHOD_TYPE"

// Country
export const SET_COUNTRY = "SET_COUNTRY"
export const EMPTY_COUNTRY = "EMPTY_COUNTRY"

//HyperPay
export const LOAD_PREPARE_CHECKOUT  = "LOAD_PREPARE_CHECKOUT"
export const FETCH_PREPARE_CHECKOUT = "FETCH_PREPARE_CHECKOUT"
export const ERROE_PREPARE_CHECKOUT = "ERROE_PREPARE_CHECKOUT"
export const GET_PAYMENT_STATUS  = "GET_PAYMENT_STATUS"
export const LOAD_PAYMENT_STATUS  = "LOAD_PAYMENT_STATUS"
export const ERROR_PAYMENT_STATUS  = "ERROR_PAYMENT_STATUS"
export const EMPTY_ID = "EMPTY_ID"
export const EMPTY_STATUS = "EMPTY_STATUS"

//Subscriptions Report
export const GET_SUBSCRIPTIONS_REPORT = "GET_SUBSCRIPTIONS_REPORT"
export const LOAD_SUBSCRIPTIONS_REPORT = "LOAD_SUBSCRIPTIONS_REPORT"
export const ERROR_LOADING_SUBSCRIPTIONS_REPORT = "ERROR_LOADING_SUBSCRIPTIONS_REPORT"
export const EMPTY_SUBSCRIPTIONS_REPORT = "EMPTY_SUBSCRIPTIONS_REPORT"

//Subscriptions Report
export const GET_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "GET_SUBSCRIPTIONS_REPORT_BY_COUNTRY"
export const LOAD_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "LOAD_SUBSCRIPTIONS_REPORT_BY_COUNTRY"
export const ERROR_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "ERROR_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY"
export const EMPTY_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "EMPTY_SUBSCRIPTIONS_REPORT_BY_COUNTRY"

//Subscription Report
export const GET_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "GET_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY"
export const LOAD_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "LOAD_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY"
export const ERROR_COUNT_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "ERROR_COUNT_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY"
export const EMPTY_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY = "EMPTY_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY"

export const EXPORT_SUBSCRIPTIONS_REPORT_REQUEST = 'EXPORT_SUBSCRIPTIONS_REPORT_REQUEST';
export const EXPORT_SUBSCRIPTIONS_REPORT_SUCCESS = 'EXPORT_SUBSCRIPTIONS_REPORT_SUCCESS';
export const EXPORT_SUBSCRIPTIONS_REPORT_FAIL = 'EXPORT_SUBSCRIPTIONS_REPORT_FAIL';

//Fawry Pay
export const LOAD_FAWRY_PAY = "LOAD_FAWRY_PAY"
export const FETCH_FAWRY_PAY = "FETCH_FAWRY_PAY"
export const ERROR_FAWRY_PAY = "ERROR_FAWRY_PAY"
export const EMPTY_FAWRY_PAY = "EMPTY_FAWRY_PAY"


// =============================================================
// MW Zain Iraq  ===============================================
// =============================================================
/*
export const MW_USER_LOGIN_SUCCESSFULLY = 'MW_USER_LOGIN_SUCCESSFULLY'
export const MW_USER_LOGIN_ERROR = 'MW_USER_LOGIN_ERROR'
export const LOADING_MW_USER_LOGIN = 'LOADING_MW_USER_LOGIN'

export const MW_USER_REGISTER_SUCCESSFULLY = 'MW_USER_REGISTER_SUCCESSFULLY'
export const MW_USER_REGISTER_ERROR = 'MW_USER_REGISTER_ERROR'
export const LOADING_MW_USER_REGISTER = 'LOADING_MW_USER_REGISTER'

export const LOAD_MW_VERIFYING_CODE = 'LOAD_MW_VERIFYING_CODE'
export const MW_VERIFYING_CODE_SUCCESS = 'MW_VERIFYING_CODE_SUCCESS'
export const MW_VERIFYING_CODE_ERROR = 'MW_VERIFYING_CODE_ERROR'

export const LOAD_MW_RESEND_CODE = 'LOAD_MW_RESEND_CODE'
export const MW_RESEND_CODE_SUCCESS = 'MW_RESEND_CODE_SUCCESS'
export const MW_RESEND_CODE_ERROR = 'MW_RESEND_CODE_ERROR'

export const EMPTY_MW = 'EMPTY_MW'
*/

export const MW_ZainIraq_USER_LOGIN_SUCCESSFULLY = 'MW_USER_LOGIN_SUCCESSFULLY'
export const MW_ZainIraq_USER_LOGIN_ERROR = 'MW_USER_LOGIN_ERROR'
export const MW_ZainIraq_LOADING_USER_LOGIN = 'LOADING_MW_USER_LOGIN'

export const MW_USER_REGISTER_SUCCESSFULLY = 'MW_USER_REGISTER_SUCCESSFULLY'
export const MW_USER_REGISTER_ERROR = 'MW_USER_REGISTER_ERROR'
export const MW_ZainIraq_LOADING_USER_REGISTER = 'LOADING_MW_USER_REGISTER'

export const MW_ZainIraq_LOAD_VERIFYING_CODE = 'LOAD_MW_VERIFYING_CODE'
export const MW_ZainIraq_VERIFYING_CODE_SUCCESS = 'MW_VERIFYING_CODE_SUCCESS'
export const MW_ZainIraq_VERIFYING_CODE_ERROR = 'MW_VERIFYING_CODE_ERROR'

export const MW_ZainIraq_LOAD_RESEND_CODE = 'LOAD_MW_RESEND_CODE'
export const MW_ZainIraq_RESEND_CODE_SUCCESS = 'MW_RESEND_CODE_SUCCESS'
export const MW_ZainIraq_RESEND_CODE_ERROR = 'MW_RESEND_CODE_ERROR'

export const MW_ZainIraq_EMPTY = 'EMPTY_MW'
// =============================================================
// MW Bahrain  =================================================
// =============================================================
export const MW_Bahrain_LOADING_USER_LOGIN = 'LOADING_MW_USER_LOGIN'
export const MW_Bahrain_USER_LOGIN_SUCCESSFULLY = 'MW_USER_LOGIN_SUCCESSFULLY'
export const MW_Bahrain_USER_LOGIN_ERROR = 'MW_USER_LOGIN_ERROR'

export const MW_Bahrain_LOADING_USER_REGISTER = 'LOADING_MW_USER_REGISTER'
export const MW_Bahrain_USER_REGISTER_SUCCESSFULLY = 'MW_USER_REGISTER_SUCCESSFULLY'
export const MW_Bahrain_USER_REGISTER_ERROR = 'MW_USER_REGISTER_ERROR'

export const MW_Bahrain_LOAD_VERIFYING_CODE = 'LOAD_MW_VERIFYING_CODE'
export const MW_Bahrain_VERIFYING_CODE_SUCCESS = 'MW_VERIFYING_CODE_SUCCESS'
export const MW_Bahrain_VERIFYING_CODE_ERROR = 'MW_VERIFYING_CODE_ERROR'

export const MW_Bahrain_LOAD_RESEND_CODE = 'LOAD_MW_RESEND_CODE'
export const MW_Bahrain_RESEND_CODE_SUCCESS = 'MW_RESEND_CODE_SUCCESS'
export const MW_Bahrain_RESEND_CODE_ERROR = 'MW_RESEND_CODE_ERROR'

export const MW_Bahrain_EMPTY = 'EMPTY_MW'

// =============================================================
// MW Qatar  ===================================================
// =============================================================
export const MW_Qatar_LOADING_USER_LOGIN = 'LOADING_MW_USER_LOGIN'
export const MW_Qatar_USER_LOGIN_SUCCESSFULLY = 'MW_USER_LOGIN_SUCCESSFULLY'
export const MW_Qatar_USER_LOGIN_ERROR = 'MW_USER_LOGIN_ERROR'

export const MW_Qatar_LOADING_USER_REGISTER = 'LOADING_MW_USER_REGISTER'
export const MW_Qatar_USER_REGISTER_SUCCESSFULLY = 'MW_USER_REGISTER_SUCCESSFULLY'
export const MW_Qatar_USER_REGISTER_ERROR = 'MW_USER_REGISTER_ERROR'

export const MW_Qatar_LOAD_VERIFYING_CODE = 'LOAD_MW_VERIFYING_CODE'
export const MW_Qatar_VERIFYING_CODE_SUCCESS = 'MW_VERIFYING_CODE_SUCCESS'
export const MW_Qatar_VERIFYING_CODE_ERROR = 'MW_VERIFYING_CODE_ERROR'

export const MW_Qatar_LOAD_RESEND_CODE = 'LOAD_MW_RESEND_CODE'
export const MW_Qatar_RESEND_CODE_SUCCESS = 'MW_RESEND_CODE_SUCCESS'
export const MW_Qatar_RESEND_CODE_ERROR = 'MW_RESEND_CODE_ERROR'

export const MW_Qatar_EMPTY = 'EMPTY_MW'

//Video Cipher
export const LOAD_PRE_SIGN_URL = "LOAD_PRE_SIGN_URL";
export const FETCH_PRE_SIGN_URL = "FETCH_PRE_SIGN_URL";
export const ERROR_PRE_SIGN_URL = "ERROR_PRE_SIGN_URL";
export const GET_VIDEO_STATUS = "GET_VIDEO_STATUS";
export const CLEAR_PRE_SIGN_URL_DATA = "CLEAR_PRE_SIGN_URL_DATA";
export const CLEAR_ALL = "CLEAR_All";
export const GENERATE_OTP = "GENERATE_OTP";
export const RESET_VDOCIPHER_DATA = "RESET_VDOCIPHER_DATA";

/* Banners */
export const FETCH_BANNERS = 'FETCH_BANNERS'
export const LOAD_BANNERS = 'LOAD_BANNERS'
export const ERROR_LOADING_BANNERS = 'ERROR_LOADING_BANNERS'
export const PUBLISH_BANNER = 'PUBLISH_BANNER'
export const ERROR_ADDING_BANNER = 'ERROR_ADDING_BANNER'
export const LOAD_BANNER = 'LOAD_BANNER'
export const GET_BANNER_DATA = 'GET_BANNER_DATA'
export const ERROR_LOADING_BANNER = 'ERROR_LOADING_BANNER'
export const UPDATE_BANNER = 'UPDATE_BANNER'
export const ERROR_UPDATING_BANNER = 'ERROR_UPDATING_BANNER'
export const EMPTY_BANNER = 'EMPTY_BANNER'
export const EMPTY_BANNER_ITEMS = 'EMPTY_BANNER_ITEMS'
export const DELETE_BANNER = "DELETE_BANNER"
export const ERROR_DELETING_BANNER = 'ERROR_DELETING_BANNER'
export const SETTING_BANNER_STATUS = 'SETTING_BANNER_STATUS'
export const ERROR_SETTING_BANNER_STATUS = 'ERROR_SETTING_BANNER_STATUS'
/* Dynamic Section */
export const FETCH_ALL_SECTION = 'FETCH_ALL_SECTION'
export const LOAD_SECTION = 'LOAD_SECTION'
export const ERROR_LOADING_ALL_SECTION = 'ERROR_LOADING_ALL_SECTION'
export const ADD_SECTION_ERROR = 'ADD_SECTION_ERROR'
export const ADD_SECTION_SUCCESSFULLY = 'ADD_SECTION_SUCCESSFULLY'
export const EMPTY_SECTION = 'EMPTY_SECTION'
export const UPDATE_SECTION_SUCCESSFULLY = 'UPDATE_SECTION_SUCCESSFULLY'
export const UPDATE_SECTION_ERROR = 'UPDATE_SECTION_ERROR'
export const GET_SECTION_DATA_SUCCESSFULLY = 'GET_SECTION_DATA_SUCCESSFULLY'
export const GET_SECTION_DATA_ERROR = 'GET_SECTION_DATA_ERROR'
export const DELETE_SECTION_SUCCESSFULLY = "DELETE_SECTION_SUCCESSFULLY"
export const DELETE_SECTION_ERROR = "DELETE_SECTION_ERROR" 

/* Settings */
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const ERROR_LOADING_SETTINGS = 'ERROR_LOADING_SETTINGS';
export const ADD_SETTING = 'ADD_SETTING';
export const ERROR_ADDING_SETTING = 'ERROR_ADDING_SETTING';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const ERROR_UPDATING_SETTING = 'ERROR_UPDATING_SETTING';
export const DELETE_SETTING = 'DELETE_SETTING';
export const ERROR_DELETING_SETTING = 'ERROR_DELETING_SETTING';
export const GET_SETTING_DATA = 'GET_SETTING_DATA'
export const ERROR_LOADING_SETTING = 'ERROR_LOADING_SETTING'
export const EMPTY_SETTING = 'EMPTY_SETTING'


/* Parent Educational Videos */
export const FETCH_EDUCATIONAL_VIDEOS = 'FETCH_EDUCATIONAL_VIDEOS'
export const LOAD_EDUCATIONAL_VIDEOS = 'LOAD_EDUCATIONAL_VIDEOS'
export const ERROR_LOADING_EDUCATIONAL_VIDEOS = 'ERROR_LOADING_EDUCATIONAL_VIDEOS'
export const PUBLISH_EDUCATIONAL_VIDEO = 'PUBLISH_EDUCATIONAL_VIDEO'
export const ERROR_ADDING_EDUCATIONAL_VIDEO = 'ERROR_ADDING_EDUCATIONAL_VIDEO'
export const LOAD_EDUCATIONAL_VIDEO = 'LOAD_EDUCATIONAL_VIDEO'
export const GET_EDUCATIONAL_VIDEO_DATA = 'GET_EDUCATIONAL_VIDEO_DATA'
export const ERROR_LOADING_EDUCATIONAL_VIDEO = 'ERROR_LOADING_EDUCATIONAL_VIDEO'
export const UPDATE_EDUCATIONAL_VIDEO = 'UPDATE_EDUCATIONAL_VIDEO'
export const ERROR_UPDATING_EDUCATIONAL_VIDEO = 'ERROR_UPDATING_EDUCATIONAL_VIDEO'
export const EMPTY_EDUCATIONAL_VIDEO = 'EMPTY_EDUCATIONAL_VIDEO'
export const EMPTY_EDUCATIONAL_VIDEO_ITEMS = 'EMPTY_EDUCATIONAL_VIDEO_ITEMS'
export const DELETE_EDUCATIONAL_VIDEO = "DELETE_EDUCATIONAL_VIDEO"
export const ERROR_DELETING_EDUCATIONAL_VIDEO = 'ERROR_DELETING_EDUCATIONAL_VIDEO'
export const SETTING_EDUCATIONAL_VIDEO_STATUS = 'SETTING_EDUCATIONAL_VIDEO_STATUS'
export const ERROR_SETTING_EDUCATIONAL_VIDEO_STATUS = 'ERROR_SETTING_EDUCATIONAL_VIDEO_STATUS'


// =============================================================
// Content Vendor  =============================================
// =============================================================
export const CONTENT_VENDOR_FETCH_ALL = 'CONTENT_VENDOR_FETCH_ALL'
export const CONTENT_VENDOR_LOAD_START = 'CONTENT_VENDOR_LOAD_START'
export const CONTENT_VENDOR_LOAD_ERROR = 'CONTENT_VENDOR_LOAD_ERROR'
export const CONTENT_VENDOR_ADD_ERROR = 'CONTENT_VENDOR_ADD_ERROR'
export const CONTENT_VENDOR_ADD_SUCCESSFULLY = 'CONTENT_VENDOR_ADD_SUCCESSFULLY'
export const CONTENT_VENDOR_UPDATE_SUCCESSFULLY = 'CONTENT_VENDOR_UPDATE_SUCCESSFULLY'
export const CONTENT_VENDOR_UPDATE_ERROR = 'CONTENT_VENDOR_UPDATE_ERROR'
export const CONTENT_VENDOR_GET_DATA_SUCCESSFULLY = 'CONTENT_VENDOR_GET_DATA_SUCCESSFULLY'
export const CONTENT_VENDOR_GET_DATA_ERROR = 'CONTENT_VENDOR_GET_DATA_ERROR'
export const CONTENT_VENDOR_EMPTY = 'CONTENT_VENDOR_EMPTY'

// =============================================================
// Profit Share  ===============================================
// =============================================================
export const PROFIT_SHARE_CURRENCY_PROFITS_LOAD = 'PROFIT_SHARE_CURRENCY_PROFITS_LOAD'
export const PROFIT_SHARE_CURRENCY_PROFITS_FETCH_SUCCESSFULLY = 'PROFIT_SHARE_CURRENCY_PROFITS_FETCH_SUCCESSFULLY'
export const PROFIT_SHARE_CURRENCY_PROFITS_FETCH_ERROR = 'PROFIT_SHARE_CURRENCY_PROFITS_FETCH_ERROR'

export const PROFIT_SHARE_ContentVendor_PROFITS_LOAD = 'PROFIT_SHARE_PARTENER_PROFITS_LOAD'
export const PROFIT_SHARE_ContentVendor_PROFITS_FETCH_SUCCESSFULLY = 'PROFIT_SHARE_PARTENER_PROFITS_FETCH_SUCCESSFULLY'
export const PROFIT_SHARE_ContentVendor_FETCH_ERROR = 'PROFIT_SHARE_PARTENER_PROFITS_FETCH_ERROR'

// =============================================================
// Partener Batch  =============================================
// =============================================================
export const PARTENER_BATCH_LOAD = 'PARTENER_BATCH_LOAD'
export const PARTENER_BATCH_FETCH_SUCCESSFULLY = 'PARTENER_BATCH_FETCH_SUCCESSFULLY'
export const PARTENER_BATCH_FETCH_ERROR = 'PARTENER_BATCH_FETCH_ERROR'

export const PARTENER_BATCH_Voucher_LOAD = 'PARTENER_BATCH_Voucher_LOAD'
export const PARTENER_BATCH_Voucher_FETCH_SUCCESSFULLY = 'PARTENER_BATCH_Voucher_FETCH_SUCCESSFULLY'
export const PARTENER_BATCH_Voucher_FETCH_ERROR = 'PARTENER_BATCH_Voucher_FETCH_ERROR'

export const PARTENER_Assign_LOAD = 'PARTENER_Assign_LOAD'
export const PARTENER_Assign_SUCCESSFULLY = 'PARTENER_Assign_SUCCESSFULLY'
export const PARTENER_Assign_ERROR = 'PARTENER_Assign_ERROR'
export const PARTENER_Assign_Empty = 'PARTENER_Assign_Empty'


// Notification Actions
export const SEND_NOTIFICATION_LOADING = 'SEND_NOTIFICATION_LOADING';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';
// =============================================================
// ContentVendorReport  ========================================
// =============================================================
export const GAME_FETCH_ERROR = 'GAME_FETCH_ERROR';
export const RADIO_FETCH_ERROR = 'RADIO_FETCH_ERROR';
export const SONG_WITHOUT_FETCH_ERROR = 'SONG_WITHOUT_FETCH_ERROR';
export const SONG_WITH_FETCH_ERROR = 'SONG_WITH_FETCH_ERROR';
export const EPISODE_WITHOUT_FETCH_ERROR = 'EPISODE_WITHOUT_FETCH_ERROR';
export const EPISODE_WITH_FETCH_ERROR = 'EPISODE_WITH_FETCH_ERROR';

export const EPISODE_WITH_FETCH_SUCCESSFULLY = 'EPISODE_WITH_FETCH_SUCCESSFULLY';
export const EPISODE_WITHOUT_FETCH_SUCCESSFULLY = 'EPISODE_WITHOUT_FETCH_SUCCESSFULLY';
export const SONG_WITH_FETCH_SUCCESSFULLY = 'SONG_WITH_FETCH_SUCCESSFULLY';
export const SONG_WITHOUT_FETCH_SUCCESSFULLY = 'SONG_WITHOUT_FETCH_SUCCESSFULLY';
export const RADIO_FETCH_SUCCESSFULLY = 'RADIO_FETCH_SUCCESSFULLY';
export const GAME_FETCH_SUCCESSFULLY = 'GAME_FETCH_SUCCESSFULLY';

export const EPISODE_WITH_LOAD = 'EPISODE_WITH_LOAD';
export const EPISODE_WITHOUT_LOAD = 'EPISODE_WITHOUT_LOAD';
export const SONG_WITH_LOAD = 'SONG_WITH_LOAD';
export const SONG_WITHOUT_LOAD = 'SONG_WITHOUT_LOAD';
export const RADIO_LOAD = 'RADIO_LOAD';
export const GAME_LOAD = 'GAME_LOAD';

export const EXPORT_CONTENT_VENDOR_REQUEST='EXPORT_CONTENT_VENDOR_REQUEST';
export const EXPORT_CONTENT_VENDOR_SUCCESS='EXPORT_CONTENT_VENDOR_SUCCESS';
export const EXPORT_CONTENT_VENDOR_FAIL='EXPORT_CONTENT_VENDOR_FAIL';
