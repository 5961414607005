import store from '../Store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { post } from 'axios'

export function savePdfBook(activityId) {
    return new Promise(function (resolve, reject) {
        let pdfBook = store.getState().pdfBook.item;
        
        let isFree = '', subValueId = '';
        if (pdfBook.isFree === 'paid') {
            isFree = 0
        }
        else if (pdfBook.isFree === 'free') {
            isFree = 1
        }

        if (pdfBook.subValueId === '' ||
            pdfBook.subValueId === undefined ||
            pdfBook.subValueId === null) {
            subValueId = 0
        } else {
            subValueId = pdfBook.subValueId
        }

        let description = pdfBook.description;
        if (pdfBook.description == null ||
            pdfBook.description == undefined ||
            pdfBook.description == '') {
            description = ''
        }

        let titleAudio = pdfBook.titleAudio;
        if (titleAudio == null ||
            titleAudio == undefined ||
            titleAudio == '') {

            titleAudio = ''
        }

        let pdfFile = pdfBook.pdfFile;
        if (pdfFile == null ||
            pdfFile == undefined ||
            pdfFile == '') {

            pdfFile = ''
        }


        const url = "/PdfBook/addPdfBook";
        const formData = new FormData();
        formData.append('Name', pdfBook.name);
        formData.append('Description', description);

        formData.append('Image', pdfBook.image);
        formData.append('Status', 'SUSPENDED');
        formData.append('Publish_Date', pdfBook.PublishDate);
        formData.append('Activity_ID', activityId);
        formData.append('Is_free', isFree);
        formData.append('Voice', titleAudio);
        formData.append('Pdf_Url', pdfFile);
        formData.append('Content_Sub_Value_Id', subValueId);
        formData.append('featured', pdfBook.featured);
        formData.append('book_Parent_Id', pdfBook.bookParent);
        formData.append('contentVendorSelectedId', pdfBook.contentVendorSelectedId);
        formData.append('ageFrom', pdfBook.ageFrom);
        formData.append('ageTo', pdfBook.ageTo);
        

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            resolve(res);
            return (res);
        }).catch((Error) => {
            reject(Error)
            return Error.message
        })
    })
}